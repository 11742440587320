export const SET_DELIVERY_CART_ORDER_TYPE = 'SET_DELIVERY_CART_ORDER_TYPE'
export const SET_DELIVERY_CART_ITEMS = 'SET_DELIVERY_CART_ITEMS'
export const ADD_DELIVERY_CART_ITEM = 'ADD_DELIVERY_CART_ITEM'
export const ADD_DELIVERY_CART_SUBITEM = 'ADD_DELIVERY_CART_SUBITEM'
export const REMOVE_DELIVERY_CART_ITEM = 'REMOVE_DELIVERY_CART_ITEM'
export const REMOVE_DELIVERY_CART_SUBITEM = 'REMOVE_DELIVERY_CART_SUBITEM'
export const UPDATE_DELIVERY_CART_ITEM = 'UPDATE_DELIVERY_CART_ITEM'
export const SET_DELIVERY_CART_ITEM_FOCUS_INDEX = 'SET_DELIVERY_CART_ITEM_FOCUS_INDEX'
export const SET_DELIVERY_CART_PREORDER_DATE = 'SET_DELIVERY_CART_PREORDER_DATE'
export const SET_DELIVERY_CART_ORDER = 'SET_DELIVERY_CART_ORDER'
export const SET_DELIVERY_CART_MAX_DISTANCE = 'SET_DELIVERY_CART_MAX_DISTANCE'
export const SET_DELIVERY_CART_MINIMUM_PRICE = 'SET_DELIVERY_CART_MINIMUM_PRICE'
export const SET_DELIVERY_CART_FREE_FROM = 'SET_DELIVERY_CART_FREE_FROM'
export const SET_DELIVERY_CART_CURRENT_DELIVERY_COST = 'SET_DELIVERY_CART_CURRENT_DELIVERY_COST'
export const SET_DELIVERY_CART_DEFAULT_DELIVERY_COST = 'SET_DELIVERY_CART_DEFAULT_DELIVERY_COST'
export const REMOVE_DELIVERY_CART_REMARK = 'REMOVE_DELIVERY_CART_REMARK'
export const ADD_DELIVERY_CART_REMARK_TO_ITEM = 'ADD_DELIVERY_CART_REMARK_TO_ITEM'
export const SET_DELIVERY_CART_MODAL_CHANGE_CALCULATOR_IS_NEEDED = 'SET_DELIVERY_CART_MODAL_CHANGE_CALCULATOR_IS_NEEDED'
export const SET_SHOW_DELIVERY_CONFIG_ERROR = 'SET_SHOW_DELIVERY_CONFIG_ERROR'
export const REMOVE_DELIVERY_CART_COUPON = 'REMOVE_DELIVERY_CART_COUPON'
export const SET_DELIVERY_CART_GIFT_COUPONS = 'SET_DELIVERY_CART_GIFT_COUPONS'
export const SET_DELIVERY_CART_PERIOD_CONFIG = 'SET_DELIVERY_CART_PERIOD_CONFIG'
