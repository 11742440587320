import { SET_AUTOCOMPLETE_GEO, SET_CUSTOMER, SET_CUSTOMER_INCOMING_CALL, SET_CUSTOMER_DELIVERY_COST, SET_CUSTOMER_EVENT, SET_CUSTOMER_GEO_DATA, SET_CUSTOMER_MODAL, SET_CUSTOMERS_BY_NAME, SET_CUSTOMERS_BY_NUMBER, SET_CUSTOMERS_BY_PHONE, SET_CUSTOMER_ADDRESS_IS_NOT_FULL, SET_CUSTOMER_GEO_DATA_LOADING, SET_CUSTOMER_CALLS, SET_CUSTOMERS_BY_EMAIL, SET_CUSTOMERS_BY_COMPANY_NAME } from '../../actionTypes/customer'

const initialState = {
	customer: null,
	deliveryCost: 0,
	geoData: null,
	autocompleteGeo: false,
	customersByPhone: null,
	customersByNumber: null,
	customersByName: null,
	customersByEmail: null,
	customersByCompanyName: null,
	customerAddressIsNotFull: false,
	geoDataLoading: false,
	calls: [],
	incomingCall: null,
	modal: {
		showOrder: false,
		showOrderData: false,
		showCancelOrder: false,
		showPaymentMethod: false,
		showPrintCanceledOrder: false,
		showReceiptPayerData: false,
	},
	event: {
		eventForm: true,
		eventTable: true,
		eventOrder: false,
		eventCancelOrder: false,
	},
}

export function customerReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CUSTOMER:
			return {
				...state,
				customer: action.value,
				deliveryCost: action.value ? action.value?.deliveryCost : 0,
				geoData: action.value?.geoData ?? null,
			}
		case SET_CUSTOMER_DELIVERY_COST:
			return {
				...state,
				deliveryCost: action.value ?? 0,
			}
		case SET_CUSTOMER_GEO_DATA:
			return {
				...state,
				geoData: action.value,
			}
		case SET_AUTOCOMPLETE_GEO:
			return {
				...state,
				autocompleteGeo: action.value,
			}
		case SET_CUSTOMERS_BY_PHONE:
			return {
				...state,
				customersByPhone: action.value,
			}
		case SET_CUSTOMERS_BY_NUMBER:
			return {
				...state,
				customersByNumber: action.value,
			}
		case SET_CUSTOMERS_BY_NAME:
			return {
				...state,
				customersByName: action.value,
			}
		case SET_CUSTOMERS_BY_EMAIL:
			return {
				...state,
				customersByEmail: action.value,
			}
		case SET_CUSTOMERS_BY_COMPANY_NAME:
			return {
				...state,
				customersByCompanyName: action.value,
			}
		case SET_CUSTOMER_CALLS:
			return {
				...state,
				calls: action.value,
			}
		case SET_CUSTOMER_INCOMING_CALL:
			return {
				...state,
				incomingCall: action.value,
			}
		case SET_CUSTOMER_MODAL:
			return {
				...state,
				modal: { ...state.modal, ...action.value },
			}
		case SET_CUSTOMER_EVENT:
			return {
				...state,
				event: {
					...state.event,
					eventForm: action.value.eventForm ?? state.event.eventForm,
					eventTable: action.value.eventTable ?? state.event.eventTable,
					eventOrder: action.value.eventOrder ?? state.event.eventOrder,
					eventCancelOrder: action.value.eventCancelOrder ?? state.event.eventCancelOrder,
				},
			}
		case SET_CUSTOMER_ADDRESS_IS_NOT_FULL:
			return {
				...state,
				customerAddressIsNotFull: action.value,
			}
		case SET_CUSTOMER_GEO_DATA_LOADING:
			return {
				...state,
				geoDataLoading: action.value,
			}
		default:
			return state
	}
}
