import React from 'react'
import CartCoupon from './CartCoupon'
import CartMenu from './CartMenu'
import CartProduct from './CartProduct'

const CartItem = React.memo(({ item, index }) => {
	return (
		item?.type === 'MENU' ?
			<CartMenu item={item} index={index} /> :
			(['MARKETING_COUPON', 'STAMP_VOUCHER', 'GIFT_COUPON'].includes(item?.type)) ?
				<CartCoupon coupon={item} /> :
				<CartProduct item={item} index={index} />
	)
})

CartItem.displayName = 'CartItem'
export default CartItem
