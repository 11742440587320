import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const DepositPaymentModalWrap = styled.div`
	font-size: 1em;
	display: grid;
	padding-bottom: 1em;
	gap: 1.88em;
`
DepositPaymentModalWrap.displayName = 'DepositPaymentModalWrap'

export const ErrorText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	font-size: 1.25em;
	line-height: 1.15em;
	text-align: center;
	color: ${colorTheme.input.error.text};
	margin-top: -0.9em;

	@media screen and (max-width: ${theme.point820}) {
		margin-top: 0;
	}
`
ErrorText.displayName = 'ErrorText'
