import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { KeyboardMain } from './NumbersKeyboard/index.styled'
import { colorTheme } from '../../../style/theme/colorTheme'

export const KeyboardsWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.88em;
	margin-top: 1.88em;

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.25em;
		grid-template-columns: 1fr;
		${KeyboardMain} {
			-webkit-filter: none;
		}
	}
`
KeyboardsWrapper.displayName = 'KeyboardsWrapper'

export const InputsWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.88em;
	margin-bottom: 0.88em;
	align-items: end;

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		gap: 1.25em;
	}
`
InputsWrapper.displayName = 'InputsWrapper'


export const MoneyInputField = styled.input`
	font-size: 1.75em;
	width: 100%;
	background-color: ${colorTheme.cartModal.changeTipsCalculator.input.money.background};
	color: ${colorTheme.cartModal.changeTipsCalculator.input.money.text};
	text-align: left;
	align-items: center;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	&.focused {
		color: ${colorTheme.cartModal.changeTipsCalculator.input.money.focused.text};
	}
`
MoneyInputField.displayName = 'MoneyInputField'

export const MoneyInputBorderContainer = styled.div`
	border: 1px solid ${colorTheme.cartModal.changeTipsCalculator.input.money.border};
	border-radius: 0.75em;

	&.error {
		display: grid;
		grid-template-columns: 40% 60%;
		border-color: transparent;
	}

	&.focused {
		border-color: transparent;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.error {
			grid-template-columns: 35% 65%;
		}
	}
`
MoneyInputBorderContainer.displayName = 'MoneyInputBorderContainer'

export const MoneyInputContainer = styled.div`
	font-size: 1rem;
	background-color: ${colorTheme.cartModal.changeTipsCalculator.input.money.background};
	border-radius: 0.75em;
	padding: 0.75em 1.25em;
	box-sizing: border-box;
	border: 2px solid transparent;
	position: relative;
	height: 4.07em;

	display: flex;
	align-items: center;

	&.focused {
		border: 2px solid ${colorTheme.cartModal.changeTipsCalculator.input.money.focused.border};
		box-shadow: 0px 6px 6px 0px ${colorTheme.cartModal.changeTipsCalculator.input.money.focused.shadow};
	}

	&.error {
		border: 2px dashed ${colorTheme.cartModal.changeTipsCalculator.input.money.error.border};
		border-radius: 0.75em 0 0 0.75em;
		border-right: none;
	}
	
	&.focused.error {
		border: 2px solid ${colorTheme.cartModal.changeTipsCalculator.input.money.error.border};
		border-radius: 0.75em 0 0 0.75em;
		border-right: none;
	}

	@media screen and (max-width: ${theme.point720}) {
		box-shadow: none;
		height: 3.32em;
	}
`
MoneyInputContainer.displayName = 'MoneyInputContainer'

export const MoneyLabelText = styled.label`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	color: ${colorTheme.cartModal.changeTipsCalculator.input.money.labelText};
	text-align: left;
`
MoneyLabelText.displayName = 'MoneyLabelText'

export const MoneyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: .31em;
	width: 100%;
	max-width: 25.37em;

	@media screen and (max-width: ${theme.point820}) {
		max-width: 27.67em;
	}

	@media screen and (max-width: ${theme.point720}) {
		max-width: 100%;
	}
`
MoneyWrapper.displayName = 'MoneyWrapper'

export const ChangeInputWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: .31em;
	width: 100%;
`

ChangeInputWrap.displayName = 'ChangeInputWrap'

export const ChangeInputContainer = styled.div`
	width: 100%;
	max-width: 25.37em;

	&.error {
		display: grid;
		grid-template-columns: 40% 60%;
	}

	@media screen and (max-width: ${theme.point820}) {
		max-width: 27.67em;
	}

	@media screen and (max-width: ${theme.point720}) {
		max-width: 100%;

		&.error {
			grid-template-columns: 35% 65%;
		}
	}
`

ChangeInputContainer.displayName = 'ChangeInputContainer'

export const ChangeInput = styled.div`
	font-size: 1rem;
	height: 4.19em;
	font-family: ${theme.fontRobotoBold};
	background-color: ${colorTheme.cartModal.changeTipsCalculator.input.change.background};
	border: 1px dashed ${colorTheme.cartModal.changeTipsCalculator.input.change.border};
	border-radius: 0.75em;
	padding: 0.75em 1.25em;
	box-shadow: none;
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;

	&.error {
		border-radius: 0.75em 0 0 0.75em;
		border-color: ${colorTheme.cartModal.changeTipsCalculator.input.change.error.border};
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 3.44em;
	}
`

ChangeInput.displayName = 'ChangeInput'

export const ChangeInputText = styled.span`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	color: ${colorTheme.cartModal.changeTipsCalculator.input.change.text};
	text-align: left;
`
ChangeInputText.displayName = 'ChangeInputText'

export const ChangeInputSum = styled.span`
	font-size: 1.75em;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.cartModal.changeTipsCalculator.input.change.text};
`
ChangeInputSum.displayName = 'ChangeInputSum'

export const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 1.88em;

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
		gap: 1.25em;
		margin-top: 0;
	}
`
ButtonsWrapper.displayName = 'ButtonsWrapper'

export const ChangeCalculatorWrapper = styled.div`
`
ChangeCalculatorWrapper.displayName = 'ChangeCalculatorWrapper'

export const ChangeCalculatorContent = styled.div`
	@media screen and (max-width: ${theme.point720}) {
		padding: 0;
		margin-bottom: 2.5em;
		margin-top: 1.87em;
	}
`
ChangeCalculatorContent.displayName = 'ChangeCalculatorContent'

export const InputErrorWrap = styled.div`
	display: flex;
	padding: 0 0.63em;
	border: 1px solid ${colorTheme.cartModal.changeTipsCalculator.input.money.error.border};
	align-items: center;
	border-radius: 0 0.75em 0.75em 0;
	transform: translateX(-1px);

	&.focused {
		border: 2px solid ${colorTheme.cartModal.changeTipsCalculator.input.money.error.border};
		box-shadow: 0px 6px 6px 0px ${colorTheme.cartModal.changeTipsCalculator.input.money.focused.shadow};
	}

	> div {
		display: flex;
		gap: 0.37em;
		justify-content: start;
	}

	svg {
		width: 1.12em;
		height: 1.07em;
		flex-shrink: 0;
	}
`
InputErrorWrap.displayName = 'InputErrorWrap'

export const InputErrorText = styled.div`
	color: ${colorTheme.cartModal.changeTipsCalculator.input.change.error.text};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1em;
	font-weight: 500;
	text-align: left;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.87em;
	}
`
InputErrorText.displayName = 'InputErrorText'

