import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const DeliveryCostWrap = styled.label`
	display: grid;
	grid-template-columns: 1fr min-content;
	align-items: center;
	gap: 0.4em;
	width: 100%;
	height: 100%;
	cursor: pointer;
	padding: 0.63em 0 0.31em;
`
DeliveryCostWrap.displayName = 'DeliveryCostWrap'

export const DeliveryCostTitleWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;
`
DeliveryCostTitleWrap.displayName = 'DeliveryCostTitleWrap'

export const DeliveryCostInputWrap = styled.div`
	display: flex;
	gap: 0.19em;
	align-items: center;
`
DeliveryCostInputWrap.displayName = 'DeliveryCostInputWrap'

export const DeliveryCostInput = styled.div`
	width: 5.88em;
	height: 2.13em;
	border-radius: 0.37em;
	border: 1px solid ${colorTheme.cart.deliveryCost.border};
	padding: 0.1em 0.31em;
	outline: 0;
	position: relative;
	align-self: normal;
	background-color: ${colorTheme.cart.deliveryCost.background};

	&.active {
		border-color: ${colorTheme.cart.deliveryCost.activeBorder};
	}

	&.long-currency {
		width: 6.4em;
	}

	&.error {
		border-color: ${colorTheme.cart.deliveryCost.error};
	}

	> input {
		width: 100%;
		padding: 0;
		border: none;
		margin: 0;
		background: transparent;
		height: 100%;
		font-size: 1.12em;
		font-family: ${theme.fontRobotoMedium};
		float: left;
		box-sizing: border-box;
		color: ${colorTheme.cart.deliveryCost.text};
		text-align: right;
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&:after {
		display: block;
		content: '${props => props.currencySymbol && props.currencySymbol}';
		font-size: 1em;
		font-family: ${theme.fontRobotoMedium};
		position: absolute;
		top: 0.5em;
		right: 0.88em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.5em 0.56em;
		width: ${props => props.width ? '5em' : '3.6em'};
	}
`
DeliveryCostInput.displayName = 'DeliveryCostInput'

export const DeliveryCostPeriodMessage = styled.div`
	grid-column: 1 / 3;
	color: ${colorTheme.cart.deliveryCost.periodText};
	align-self: start;
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.94em;
	font-weight: 500;
	line-height: 1.03em;
`
DeliveryCostPeriodMessage.displayName = 'DeliveryCostPeriodMessage'
