import subscriptionInstance from './instance'

const fetchInvoices = (page, size, companyIndex, status = null) => {
	return subscriptionInstance.get(`/${companyIndex}/invoices`
		+ '?page=' + page
		+ '&size=' + size
		+ (status !== null ? '&status=' + status : ''),
	)
}

export default fetchInvoices
