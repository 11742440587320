import React from 'react'
import MainIcon from '../../../../img/svg/main'
import { NotificationModalInfoText, NotificationModalInfoWrapper } from './index.styled'

const NotificationModalInfoMessage = React.memo(({ text }) => {
		return (
			<NotificationModalInfoWrapper>
				<MainIcon icon="error" />
				<NotificationModalInfoText>{text}</NotificationModalInfoText>
			</NotificationModalInfoWrapper>
		)
	}
)

NotificationModalInfoMessage.displayName = 'NotificationModalInfoMessage'
export default NotificationModalInfoMessage
