import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { selectCompanyIndex } from '../../../redux/selector/system'
import { DigitalReceiptTitle, DigitalReceiptWrapper, QrCodeWrap } from './index.styled'
import { QRCodeSVG } from 'qrcode.react'
import { useDispatch, useSelector } from 'react-redux'
import { receiptUIUrl } from '../../../urls'
import { setReceiptIsLoading } from '../../../redux/action/receipt'
import { getReceiptThunk } from '../../../redux/thunk/receipt'
import HourglassLoader from '../loaders/HourglassLoader'

const DigitalReceipt = React.memo(({ orderPublicId }) => {
	const { t } = useTranslation()
	const companyIndex = useSelector(selectCompanyIndex)
	const receiptIsLoading = useSelector(state => state.receipt?.receiptIsLoading)
	const link = `${receiptUIUrl}/receipt?companyIndex=${companyIndex}&orderPublicId=${orderPublicId}`
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setReceiptIsLoading(true))
		const interval = setInterval(() => {
			if (orderPublicId && receiptIsLoading) {
				dispatch(getReceiptThunk(companyIndex, orderPublicId))
			}
		}, 5000)
		const timeout = setTimeout(() => {
			clearInterval(interval)
		}, 30000)
		return () => {
			clearInterval(interval)
			clearTimeout(timeout)
		}
	}, [orderPublicId, receiptIsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<DigitalReceiptWrapper>
			<DigitalReceiptTitle>{t('digitalReceipt.title')}</DigitalReceiptTitle>
			<QrCodeWrap>
				{receiptIsLoading ?
					<HourglassLoader className="dark mob-small" />
					: <QRCodeSVG id="qrcode-svg" value={link} level="M" />}
			</QrCodeWrap>
		</DigitalReceiptWrapper>
	)
})

DigitalReceipt.displayName = 'DigitalReceipt'
export default DigitalReceipt
