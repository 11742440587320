import { convert2GiftCouponItem } from '../../../../Elements/cart/GiftVoucher/helper'

export const getPreparedItems = (selectedOrder, extraDiscountItem, marketingCouponItem) => {
	const { itemId, name, value } = extraDiscountItem
	const extraDiscount = itemId ?
		{ itemId, name, type: 'EXTRA_DISCOUNT', quantity: 1, price: value > 0 ? -value : 0 } :
		{ name, type: 'EXTRA_DISCOUNT', quantity: 1, price: value > 0 ? -value : 0 }
	const isGiftCouponItem = selectedOrder?.items?.filter((el) => el?.type === 'GIFT_COUPON')?.[0]
	const items = selectedOrder?.items?.map(item => item.type === 'GIFT_COUPON' ? convert2GiftCouponItem(isGiftCouponItem) : item)
	return marketingCouponItem ? [...items.filter(it => it.type !== 'EXTRA_DISCOUNT'), extraDiscount, marketingCouponItem] : [...items.filter(it => it.type !== 'EXTRA_DISCOUNT'), extraDiscount]
}
