import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'
import { ButtonIconWrap } from '../index.styled'

export const ButtonWrapper = styled.button`
	font-size: 1rem;
	background-color: ${props => props.color};
	border: 1px solid ${props => props.color};
	padding: 0.4em 0.88em;
	border-radius: 0.75em;
	display: flex;
	justify-content: center;
	gap: 0.63em;
	align-items: center;
	color: ${colorTheme.button.color};
	min-height: 3.44em;
	cursor: pointer;
	box-sizing: border-box;
	transition: all 0.3s ease;

	&.x-small {
		min-height: 1.69em;
		padding: 0.38em;
		border-radius: 0.5em;
	}

	&.small {
		min-height: 2.69em;
		padding: 0.5em;
	}

	&.medium {
		min-height: 3.06em;
		padding: 0.63em;
	}

	&.big {
		min-height: 4.19em;
		padding: 0.88em 2.44em;
	}

	&.x-big {
		min-height: 4.69em;
		padding: 0.88em 2.44em;
	}

	&.switchChild {
		display: flex;
	}

	&.switchChild {
		${ButtonIconWrap} {
			order: 1;
		}
	}

	&.fullWidth {
		width: 100%;
	}

	&.no-print {
		@media print {
			visibility: hidden;
		}
	}

	&.success {
		background-color: ${colorTheme.button.type.success};
		border: 1px solid ${colorTheme.button.type.success};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.successPress};
		}
	}

	&.primary {
		background-color: ${colorTheme.button.type.primary};
		border: 1px solid ${colorTheme.button.type.primary};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.primaryPress};
		}
	}

	&.warning {
		background-color: ${colorTheme.button.type.warning};
		border: 1px solid ${colorTheme.button.type.warning};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.dangerPress};
		}
	}

	&.danger {
		background-color: ${colorTheme.button.type.danger};
		border: 1px solid ${colorTheme.button.type.danger};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.dangerPress};
		}
	}

	&.secondary {
		background-color: ${colorTheme.button.type.secondary};
		border: 1px solid ${colorTheme.button.type.secondary};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.secondaryPress};
		}
	}

	&.light {
		background-color: ${colorTheme.button.type.light};
		border: 1px solid ${colorTheme.button.type.light};
	}

	&.secondary_light {
		background-color: ${colorTheme.button.type.secondary_light};
		border: 1px solid ${colorTheme.button.type.secondary_light};
	}

	&.secondary_gray_light {
		background-color: ${colorTheme.button.type.secondary_gray_light};
		border: 1px solid ${colorTheme.button.type.secondary_gray_light};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.secondaryPress};
		}
	}

	&.secondary_gray_lighter {
		background-color: ${colorTheme.button.type.secondary_gray_lighter};
		border: 1px solid ${colorTheme.button.type.secondary_gray_lighter};
		&:active {
			box-shadow: 0px -2px 0px ${colorTheme.button.type.secondaryPress};
		}
	}

	&.outline {
		background-color: ${colorTheme.button.type.outline};
		&:hover {
			box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
		}
		&:active {
			box-shadow: none;
		}
	}

	&.outline_gray {
		background-color: ${colorTheme.button.type.light};
		border: 1px solid ${colorTheme.button.type.light_gray};
	}

	&.disabledHover {
		&:active {
			box-shadow: none;
		}
		&:hover {
			box-shadow: none;
		}
	}

	&:disabled {
		background-color: ${colorTheme.button.type.disabled};
		border-color: ${colorTheme.button.type.disabled};
		&:active {
			box-shadow: none;
		}
		&:hover {
			box-shadow: none;
		}
	}

	&:hover {
		box-shadow: 0px 0px 0px 2px ${colorTheme.button.type.borderHover}, 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
	}

	@media screen and (max-width: ${theme.point820}) {
		&.small {
			padding-right: 1em;
		}

		&.hiddenTabletText {
			padding: 0.53em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.small {
			padding: 0.8em;
			gap: 0.31em;
		}
	}
`
ButtonWrapper.displayName = 'ButtonWrapper'
