import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { setDeliveryCartCurrentDeliveryCost } from '../../../../redux/action/deliveryCart'
import { getFloat } from '../../../../helper'
import { DeliveryCostInput, DeliveryCostInputWrap, DeliveryCostPeriodMessage, DeliveryCostTitleWrap, DeliveryCostWrap } from './index.styled'
import KeyIcon from '../../../Elements/buttons/KeyIcon'
import KeyBindings from '../../../Elements/keybindings'
import { selectCurrencySymbol, selectFormattedCurrency } from '../../../../redux/selector/system'
import Tooltip from '../../../Elements/tooltip'
import { CartTotalText, CartTotalValue } from '../../../Elements/cart/index.styled'
import { updateClientMonitorCartThunk } from '../../../../redux/thunk/clientMonitorCart'
import { selectDeliveryCartPeriodConfig, selectHasUnpaidOrderInCart, selectOrder } from '../../../../redux/selector/cart'
import { selectCustomerDistance } from '../../../../redux/selector/customer'

const EVENT_Z_INDEX = 6

const DeliveryCost = ({ type }) => {
	const dispatch = useDispatch()
	const deliveryCostInput = useRef()
	const { t } = useTranslation()
	const currentDeliveryCost = useSelector(store => store.deliveryCart.currentDeliveryCost)
	const defaultDeliveryCost = useSelector(store => store.deliveryCart.defaultDeliveryCost)
	const eventZIndex = useSelector(store => store.system.eventZIndex)
	const formattedDefaultDeliveryCost = useSelector(selectFormattedCurrency(defaultDeliveryCost))
	const currencySymbol = useSelector(selectCurrencySymbol)
	const isUpdateMode = !!useSelector(selectOrder(type))
	const isUnpaidOrder = useSelector(selectHasUnpaidOrderInCart(type))
	const customerDistance = useSelector(selectCustomerDistance)
	const deliveryCartPeriodConfig = useSelector(selectDeliveryCartPeriodConfig)
	const periodConfigStartTime = deliveryCartPeriodConfig?.startTime?.slice(0, 5)
	const periodConfigEndTime = deliveryCartPeriodConfig?.endTime?.slice(0, 5)
	const distance = customerDistance.toFixed(1)
	const isLongCurrency = currencySymbol?.length > 1
	const showPeriodConfigMessage = !isUpdateMode && deliveryCartPeriodConfig
	const showErrorMessage = !showPeriodConfigMessage && defaultDeliveryCost !== null && defaultDeliveryCost !== currentDeliveryCost
	const layout = (eventZIndex === EVENT_Z_INDEX ? 'active ' : '') + (showErrorMessage ? 'error ' : '') + (isLongCurrency ? 'long-currency ' : '')

	const focusDeliveryCost = () => {
		deliveryCostInput.current.focus()
		deliveryCostInput.current.select()
	}

	useEffect(() => {
		if (eventZIndex === 6) {
			focusDeliveryCost()
		}
	}, [eventZIndex])

	const setDeliveryCost = event => {
		if (isUnpaidOrder) return
		const deliveryCost = getFloat(event.target.value)
		dispatch(updateClientMonitorCartThunk(type, () => {
			dispatch(setDeliveryCartCurrentDeliveryCost(deliveryCost))
		}))
	}

	const handleArrowUp = () => {
		dispatch(setAppEventZIndex(0))
	}

	const handleArrowDown = () => {
		dispatch(setAppEventZIndex(7))
	}

	const handleEnter = () => {
		dispatch(setAppEventZIndex(0))
	}

	const handleF10 = () => {
		dispatch(setAppEventZIndex(6))
	}

	const handleF9 = () => {
		dispatch(setAppEventZIndex(7))
	}

	const resetDeliveryCost = () => {
		if (currentDeliveryCost <= 0) {
			dispatch(setDeliveryCartCurrentDeliveryCost(0))
		}
	}

	return (
		<>
			<DeliveryCostWrap onClick={() => dispatch(setAppEventZIndex(EVENT_Z_INDEX))}>
				<DeliveryCostTitleWrap>
					<CartTotalText className={layout}>{t('AddOrder.Client.form.Anfahrthosten')}:</CartTotalText>
					<KeyIcon keyButton="F10" zIndex={EVENT_Z_INDEX} onKeyPress={handleF10} />
				</DeliveryCostTitleWrap>
				<Tooltip isShow={showErrorMessage}
								 text={`${t('tooltips.deliveryCost.text')} ${distance} km ${t('tooltips.deliveryCost.amounts')} ${formattedDefaultDeliveryCost}`}
								 position="top"
								 width="22em">
					<DeliveryCostInputWrap className={layout}>
						<DeliveryCostInput data-testid="delivery-cost-input" className={layout}>
							<input type="number"
										 ref={deliveryCostInput}
										 onBlur={resetDeliveryCost}
										 value={(currentDeliveryCost !== null && !isUnpaidOrder) ? currentDeliveryCost : ''}
										 onChange={setDeliveryCost} />
						</DeliveryCostInput>
						<CartTotalValue>{currencySymbol}</CartTotalValue>
					</DeliveryCostInputWrap>
				</Tooltip>
				{showPeriodConfigMessage &&
					<DeliveryCostPeriodMessage>{t('Cart.periodMessage', { startTime: periodConfigStartTime, endTime: periodConfigEndTime })}</DeliveryCostPeriodMessage>}
			</DeliveryCostWrap>

			{eventZIndex === EVENT_Z_INDEX &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 f9={handleF9} />}
		</>
	)
}

DeliveryCost.displayName = 'DeliveryCost'
export default DeliveryCost
