import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const UnpaidInvoicesWrap = styled.div`
	display: grid;
	gap: 0.63em;
	width: 100%;
`
UnpaidInvoicesWrap.displayName = 'UnpaidInvoicesWrap'

export const UnpaidInvoicesTitle = styled.div`
	color: ${colorTheme.deactivationModal.unpaidInvoices.title};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	font-weight: 500;
`
UnpaidInvoicesTitle.displayName = 'UnpaidInvoicesTitle'

export const UnpaidInvoicesTableWrap = styled.div`
`
UnpaidInvoicesTableWrap.displayName = 'UnpaidInvoicesTableWrap'

export const UnpaidInvoicesTable = styled.div`
	border-top: 1px solid ${colorTheme.deactivationModal.unpaidInvoices.border};
	border-bottom: 1px solid ${colorTheme.deactivationModal.unpaidInvoices.border};
	margin-bottom: 0.32em;
	max-height: 15.5em;
	overflow-y: auto;
	padding-right: 0.32em;

	> div:last-child {
		border-bottom: none;
	}

	@media screen and (max-width: ${theme.point720}) {
		max-height: initial;
		margin-bottom: 0.63em;
	}
`
UnpaidInvoicesTable.displayName = 'UnpaidInvoicesTable'

export const UnpaidInvoice = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content;
	padding: 0.2em 0;
	align-items: center;
	gap: 0.63em;
	align-self: stretch;
	border-bottom: 1px dashed ${colorTheme.deactivationModal.unpaidInvoices.invoice.border};
	min-height: 3.25em;

	@media screen and (max-width: ${theme.point720}) {
		min-height: 4.43em;
	}
`
UnpaidInvoice.displayName = 'UnpaidInvoice'

export const UnpaidInvoiceTextWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.18em;
		grid-template-columns: 1fr;
	}
`
UnpaidInvoiceTextWrap.displayName = 'UnpaidInvoiceTextWrap'

export const UnpaidInvoiceText = styled.div`
	color: ${colorTheme.deactivationModal.unpaidInvoices.invoice.text};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1em;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: left;
`
UnpaidInvoiceText.displayName = 'UnpaidInvoiceText'

export const UnpaidInvoiceButton = styled.div`
	display: flex;
	min-height: 2em;
	justify-content: center;
	align-items: center;
	gap: 0.32em;
	border-radius: 0.5em;
	border: 1px solid ${colorTheme.deactivationModal.unpaidInvoices.invoice.buttonBorder};
	padding: 0.1em 0.68em;
	cursor: pointer;
	min-width: 9.37em;

	> svg {
		width: 1.25em;
		height: 1em;
		fill: ${colorTheme.deactivationModal.unpaidInvoices.invoice.buttonIcon};
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 2.5em;
	}
`
UnpaidInvoiceButton.displayName = 'UnpaidInvoiceButton'

export const UnpaidInvoiceButtonText = styled.div`
	color: ${colorTheme.deactivationModal.unpaidInvoices.invoice.buttonText};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1em;
	font-weight: 500;
`
UnpaidInvoiceButtonText.displayName = 'UnpaidInvoiceButtonText'

export const UnpaidInvoiceSkeletonBlock = styled.div`
	width: 9.37em;
	height: 2em;
	border-radius: 0.63em;
	background: ${colorTheme.deactivationModal.unpaidInvoices.invoice.skeleton};
	background-size: 12em;
	background-position: right;
	animation: shine .7s infinite;

	@keyframes shine {
		to {
			background-position: left;
		}
	}

	&.small {
		width: 5.32em;
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 2.5em;

		&.small {
			width: 5.32em;
			height: 1.18em;
		}

		&.middle {
			width: 9.68em;
			height: 1.18em;
		}
	}
`
UnpaidInvoiceSkeletonBlock.displayName = 'UnpaidInvoiceSkeletonBlock'

export const UnpaidInvoicesEmpty = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.63em;
	align-self: stretch;
	margin: 1.87em 0;

	> svg {
		width: 2.32em;
		height: 2.32em;
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
		margin: 1.56em 0;
	}
`
UnpaidInvoicesEmpty.displayName = 'UnpaidInvoicesEmpty'

export const UnpaidInvoicesEmptyText = styled.div`
	color: ${colorTheme.deactivationModal.unpaidInvoices.invoice.emptyText};
	font-size: 1.5em;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`
UnpaidInvoicesEmptyText.displayName = 'UnpaidInvoicesEmptyText'
