import React from 'react'
import CartCoupon from './CartCoupon'
import CartMenu from './CartMenu'
import CartProduct from './CartProduct'
import CartGiftCoupon from './CartGiftCoupon'

const CartItem = React.memo(({
															 active, item, couponDiscountInCurrency, removeAbility, onClick, onClickPlus,
															 onClickMinus, onClickToppingMinus, AddButton, handleRemoveRemark, handleRemoveCoupon,
															 plusBtnColor = null, orderTask = false, savedOrder, enabledDishCourse = false, onClickProduct = () => {}
														 }) => {

	return (
		item?.type === 'MENU' ?
			<CartMenu active={active}
								item={item}
								savedOrder={savedOrder}
								removeAbility={removeAbility}
								AddButton={AddButton}
								onClick={onClick}
								onClickPlus={onClickPlus}
								onClickMinus={onClickMinus}
								handleRemoveRemark={handleRemoveRemark}
								enabledDishCourse={enabledDishCourse}
								onClickProduct={onClickProduct} /> :
			['MARKETING_COUPON', 'STAMP_VOUCHER'].includes(item?.type) ?
				<CartCoupon coupon={item}
										active={active}
										handleRemoveCoupon={handleRemoveCoupon}
										enabledDishCourse={enabledDishCourse}
				/> :
				item?.type === 'GIFT_COUPON' ?
					<CartGiftCoupon coupon={item}
													onClickMinus={onClickMinus}
													AddButton={AddButton}
													onClickPlus={onClickPlus}
													enabledDishCourse={enabledDishCourse}
					/> :
					<CartProduct active={active}
											 item={item}
											 savedOrder={savedOrder}
											 removeAbility={removeAbility}
											 orderTask={orderTask}
											 AddButton={AddButton}
											 onClick={onClick}
											 onClickPlus={onClickPlus}
											 onClickMinus={onClickMinus}
											 onClickToppingMinus={onClickToppingMinus}
											 handleRemoveRemark={handleRemoveRemark}
											 plusBtnColor={plusBtnColor}
											 enabledDishCourse={enabledDishCourse}
											 onClickProduct={onClickProduct}
											 incrementAbility={!['CASH_EXCHANGE', 'EXTRA_DISCOUNT'].includes(item?.type)} />
	)
})

CartItem.displayName = 'CartItem'
export default CartItem
