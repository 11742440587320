import { retrieveErrorFromApi } from '../../../helper'
import { setReceiptIsLoading } from '../../action/receipt'
import { setAppErrors } from '../../action/system'
import getReceipt from '../../axios/receipt/get'

export const getReceiptThunk = (companyIndex, orderPublicId) => dispatch => {
	return getReceipt(companyIndex, orderPublicId)
		.then(res => {
			if (res.data.orderId) {
				dispatch(setReceiptIsLoading(false))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
