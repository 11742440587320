import { TextBig } from '../../OrdersTable/index.styled'
import { getDistanceInKm } from '../../../../../helper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextWrap, TextWrapper, DistanceBlock } from './index.styled'
import NewBlock from './NewBlock'

const CustomerData = React.memo(({ item }) => {
	const { t } = useTranslation()
	const isDeliveryByPlatform = item?.orderType === 'DELIVERY_BY_PLATFORM'
	const partnerDeliveryMetaData = item?.deliveryMetaData?.partner
	const updatedDeliveryByPlatform = !!(item?.orderType === 'DELIVERY' && partnerDeliveryMetaData?.type && partnerDeliveryMetaData?.pickupEta)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const isPartnerDriverOrder = partnerDeliveryMetaData?.deliveryId
	const isNewCustomer = item?.customer?.closedOrdersCount === 0
	const beeperCode = item?.kioskMetaData?.beeperCode

	return (
		<>
			{item.orderType === 'RESTAURANT' &&
				<TextBig>{`${item.restaurantMetaData?.area.name} ${t('order.table.table')} ${item.restaurantMetaData?.table.number}`}</TextBig>}
			{(item.orderType === 'PICKUP' || item.orderType === 'COUNTER') &&
				<TextWrapper>
					<TextBig className="mg-bt">
						{t(`order.table.${item.orderType}`)}
						{beeperCode && t('order.table.pagerCode', { beeperCode })}
					</TextBig>
					<TextWrap>
						<TextBig>{item.customer?.name}</TextBig>
						{isNewCustomer && <NewBlock />}
					</TextWrap>
					<p className="phoneNumber">{item.customer?.phoneNumber}</p>
				</TextWrapper>}
			{item.orderType === 'DELIVERY' && (!isPlatformDriverOrder || isPartnerDriverOrder) &&
				<>
					<TextWrap>
						<TextBig>{item.customer?.name}</TextBig>
						{isNewCustomer && <NewBlock />}
					</TextWrap>
					<p className="street">{item.customer.street} str. {item.customer.streetNumber}, {item.customer.postalCode}
						<DistanceBlock className={(!isNaN(+item.customer.geoData?.distance) && item.customer.geoData?.distance > 0) ? '' : 'hidden'}>
							{getDistanceInKm(item.customer.geoData?.distance)} km
						</DistanceBlock>
					</p>
				</>}
			{isPlatformDriverOrder && !isPartnerDriverOrder &&
				<TextBig>{t(`order.table.platform_driver.${item?.platformMetaData?.platformType}`)}</TextBig>}
		</>)
})

CustomerData.displayName = 'CustomerData'
export default CustomerData
