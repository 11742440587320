import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'

export const DigitalReceiptWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	padding-top: 1.87em;
	flex-direction: column;
	align-items: center;
	gap: 1.25em;
`
DigitalReceiptWrapper.displayName = 'DigitalReceiptWrapper'

export const DigitalReceiptTitle = styled.div`
	color: ${colorTheme.digitalReceipt.title};
	text-align: center;
	font-size: 1.12em;
	font-weight: 500;
`
DigitalReceiptTitle.displayName = 'DigitalReceiptTitle'

export const QrCodeWrap = styled.div`
	display: flex;
	padding: 0.63em;
	align-items: center;
	gap: 0.63em;
	border-radius: 0.63em;
	border: 2px solid ${colorTheme.digitalReceipt.border};
	background: ${colorTheme.digitalReceipt.background};
`
QrCodeWrap.displayName = 'QrCodeWrap'
