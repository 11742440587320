import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { roundPrice } from '../../../helper'
import { selectFormattedCurrency, selectIsNeededZIndex } from '../../../redux/selector/system'
import Button from '../buttons/Button'
import KeyBindings from '../keybindings'
import CustomModal from '../modal/CustomModal'
import { ButtonsWrapper, ChangeCalculatorContent, ChangeCalculatorWrapper, InputsWrapper, KeyboardsWrapper } from './index.styled'
import NumbersKeyboard from './NumbersKeyboard'
import SumKeyboard from './SumKeyboard'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import MoneyInput from './MoneyInput'
import DifferenceSumInput from './DifferenceSumInput'
import { formatValueToString } from './helper'

const EVENT_Z_INDEX = 12

const ModalChangeTipsCalculator = React.memo(({ isOpen, close, totalPrice, handlePayment }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const [focusedInput, setFocusedInput] = useState('customerGivenSum')
	const [customerGivenSum, setCustomerGivenSum] = useState('0')
	const [tipSum, setTipSum] = useState('0')
	const [finalSum, setFinalSum] = useState('0')
	const [positionAfterComma, setPositionAfterComma] = useState(-1)
	const isMobile = useWindowBreakPoint(theme.point720)
	const roundedTotalPrice = roundPrice(totalPrice)
	const customerGivenSumNumber = +customerGivenSum.replace(',', '.')
	const tipSumNumber = +tipSum.replace(',', '.')
	const finalSumNumber = +finalSum.replace(',', '.')
	const differenceSum = (customerGivenSumNumber > roundedTotalPrice) || tipSumNumber || finalSumNumber ? customerGivenSumNumber - (roundedTotalPrice + tipSumNumber) : 0
	const formattedCustomerGivenSum = useSelector(selectFormattedCurrency(customerGivenSumNumber))
	const formattedTipSum = useSelector(selectFormattedCurrency(tipSumNumber))
	const formattedFinalSum = useSelector(selectFormattedCurrency(finalSumNumber))
	const formattedDifferenceSum = useSelector(selectFormattedCurrency(differenceSum))
	const isDifferenceSumError = differenceSum < 0

	useEffect(() => {
		// setCustomerGivenSum(totalPrice)
	}, [isOpen])

	const handleReset = useCallback(() => {
		setCustomerGivenSum('0')
		setTipSum('0')
		setFinalSum('0')
		setPositionAfterComma(-1)
		setFocusedInput('customerGivenSum')
	}, [])

	const handleClear = useCallback(() => {
		if (focusedInput === 'customerGivenSum') {
			setCustomerGivenSum('0')
		} else if (focusedInput === 'tipSum') {
			setTipSum('0')
			setFinalSum('0')
		} else if (focusedInput === 'finalSum') {
			setFinalSum('0')
		}
		setPositionAfterComma(-1)
	}, [focusedInput])

	const handleSumType = useCallback((sum) => {
		if (focusedInput === 'customerGivenSum') {
			setCustomerGivenSum(`${+customerGivenSum + +sum}`)
		} else if (focusedInput === 'tipSum') {
			setTipSum(`${+tipSum + +sum}`)
			setFinalSum(`${+tipSum + +sum + roundedTotalPrice}`)
		} else if (focusedInput === 'finalSum') {
			setFinalSum(`${+finalSum + +sum}`)
			setTipSum(`${+finalSum + +sum - roundedTotalPrice}`)
		}
	}, [focusedInput, customerGivenSum, tipSum, roundedTotalPrice, finalSum])

	const updateValue = useCallback((currentValue, value, isBackspace) => {
		if (isBackspace) {
			let newValue = currentValue.slice(0, -1)
			if (newValue.endsWith('.')) {
				setPositionAfterComma(0)
				newValue = newValue.slice(0, -1)
			} else if (positionAfterComma > 0) {
				setPositionAfterComma((prev) => prev - 1)
			} else {
				setPositionAfterComma(-1)
			}
			return newValue
		}

		if (positionAfterComma === 0) {
			setPositionAfterComma(1)
			return currentValue + '.' + value
		} else if (positionAfterComma === 1) {
			setPositionAfterComma(2)
			return currentValue + '' + value
		} else if (positionAfterComma !== 2) {
			return currentValue + '' + value
		}
		return currentValue
	}, [positionAfterComma])

	const handleKeyboardType = useCallback((value, isBackspace = false) => {
		if (focusedInput === 'customerGivenSum') {
			setCustomerGivenSum(updateValue(customerGivenSum, value, isBackspace))
		} else if (focusedInput === 'tipSum') {
			const updateTipSum = updateValue(tipSum, value, isBackspace)
			setTipSum(updateTipSum)
			setFinalSum(`${+updateTipSum + roundedTotalPrice}`)
		} else if (focusedInput === 'finalSum') {
			const updateFinalSum = updateValue(finalSum, value, isBackspace)
			setFinalSum(updateFinalSum)
			setTipSum(`${+updateFinalSum - roundedTotalPrice}`)
		}
	}, [focusedInput, customerGivenSum, tipSum, finalSum, roundedTotalPrice, updateValue])

	const handleCommaClick = useCallback(() => {
		if (positionAfterComma === -1) {
			setPositionAfterComma(0)
		}
	}, [positionAfterComma])

	const handleEnter = useCallback(() => {
		// todo: do we need enter button?
	}, [])

	const handleClose = useCallback(() => {
		close()
		handleReset()
	}, [close, handleReset])

	const handleGreenButtonClick = useCallback(() => {
		handleReset()
		handlePayment({ totalPrice, tips: tipSumNumber })
	}, [handleReset, handlePayment, totalPrice, tipSumNumber])

	const filter = v => v.replace(/[^0-9.,]/g, '')

	const handleCalculatorSumOnlyMobile = (e, inputType) => {
		if (!isMobile) return
		let inputValue = e.target.value
		if (/^-?\d*\.?\d*$/.test(inputValue) || (/^-?\d*\.?\d*,$/.test(inputValue))) {
			if (inputType === 'customerGivenSum') {
				setCustomerGivenSum(inputValue.replace('.', ','))
			} else if (inputType === 'tipSum') {
				setTipSum(inputValue.replace('.', ','))
			} else if (inputType === 'finalSum') {
				setFinalSum(inputValue.replace('.', ','))
			}
		} else {
			inputValue = filter(inputValue).replace('.', ',')
			if (inputType === 'customerGivenSum') {
				setCustomerGivenSum(inputValue)
			} else if (inputType === 'tipSum') {
				setTipSum(inputValue)
			} else if (inputType === 'finalSum') {
				setFinalSum(inputValue)
			}
		}
	}

	const handleOnBlurForMobile = (e, inputType) => {
		if (!isMobile) return
		const value = e.target.value
		const formattedValue = value ? formatValueToString(value) : value

		if (inputType === 'customerGivenSum') {
			setCustomerGivenSum(formattedValue)
		} else if (inputType === 'tipSum') {
			setTipSum(formattedValue)
			const finalSum = parseFloat(formattedValue.replace(',', '.')) + roundedTotalPrice
			const formattedFinalSum = formatValueToString(finalSum)
			setFinalSum(formattedFinalSum)
		} else if (inputType === 'finalSum') {
			setFinalSum(formattedValue)
			const tips = parseFloat(formattedValue.replace(',', '.')) - roundedTotalPrice
			const formattedTips = formatValueToString(tips)
			setTipSum(formattedTips)
		}
	}

	const handleFocus = (input) => () => {
		setFocusedInput(input)
		setPositionAfterComma(-1)
		if (isMobile) {
			if (input === 'customerGivenSum') {
				if (!+customerGivenSum) setCustomerGivenSum('')
			} else if (input === 'tipSum') {
				if (!+tipSum) setTipSum('')
			} else if (input === 'finalSum') {
				if (!+finalSum) setFinalSum('')
			}
		}
	}

	const handleCalculateTips = useCallback(() => {
		setFinalSum(`${customerGivenSum}`)
		if (isMobile) {
			const tips = parseFloat(customerGivenSum.replace(',', '.')) - roundedTotalPrice
			const formattedTips = formatValueToString(tips)
			setTipSum(formattedTips)
		} else {
			setTipSum(`${+customerGivenSum - roundedTotalPrice}`)
		}
	}, [customerGivenSum, roundedTotalPrice, isMobile])

	const handleChangeActiveInput = useCallback(() => {
		if (focusedInput === 'customerGivenSum') {
			setFocusedInput('finalSum')
		} else if (focusedInput === 'finalSum') {
			setFocusedInput('tipSum')
		} else {
			setFocusedInput('customerGivenSum')
		}
	}, [focusedInput])

	return (
		<ChangeCalculatorWrapper>
			<CustomModal isOpen={isOpen}
									 close={handleClose}
									 id="modal-change-tips"
									 data-testid="modal-change-tips"
									 size="auto"
									 title={t('changeTipsCalculator.title')}
									 titlePrice={roundedTotalPrice}
									 titleUpperCase={true}
									 titleColor="blue"
									 disableBackButton={true}
									 titleInOneRow={true}
									 zIndex={EVENT_Z_INDEX}>
				<ChangeCalculatorContent>
					<InputsWrapper>
						<MoneyInput value={isMobile ? customerGivenSum : formattedCustomerGivenSum}
												name="get-money"
												textForMoneyInput={t('changeTipsCalculator.getMoney')}
												onChange={(e) => handleCalculatorSumOnlyMobile(e, 'customerGivenSum')}
												onBlur={(e) => handleOnBlurForMobile(e, 'customerGivenSum')}
												onFocus={handleFocus('customerGivenSum')}
												focused={focusedInput === 'customerGivenSum'} />
						<MoneyInput value={isMobile ? finalSum : formattedFinalSum}
												name="final-sum"
												textForMoneyInput={t('changeTipsCalculator.sumWithTips')}
												onChange={(e) => handleCalculatorSumOnlyMobile(e, 'finalSum')}
												onBlur={(e) => handleOnBlurForMobile(e, 'finalSum')}
												onFocus={handleFocus('finalSum')}
												focused={focusedInput === 'finalSum'}
												error={tipSumNumber < 0} />
						<MoneyInput value={isMobile ? tipSum : formattedTipSum}
												name="tip-sum"
												textForMoneyInput={t('changeTipsCalculator.tip')}
												onChange={(e) => handleCalculatorSumOnlyMobile(e, 'tipSum')}
												onBlur={(e) => handleOnBlurForMobile(e, 'tipSum')}
												onFocus={handleFocus('tipSum')}
												focused={focusedInput === 'tipSum'} />
						<DifferenceSumInput error={isDifferenceSumError} sum={formattedDifferenceSum} />
					</InputsWrapper>

					{!isMobile &&
						<KeyboardsWrapper>
							<SumKeyboard handleType={handleSumType} isTipsSums={focusedInput === 'tipSum'} />
							<NumbersKeyboard handleClick={handleKeyboardType}
															 handleCommaClick={handleCommaClick}
															 enter={handleEnter}
															 reset={handleClear} />
						</KeyboardsWrapper>}
				</ChangeCalculatorContent>

				<ButtonsWrapper>
					<Button name="back" icon={'back'} text={t('Cart.Buttons.back')} keyButton="escape" keyText="Esc" zIndex={EVENT_Z_INDEX} color="gray" onClick={handleClose} />
					<Button name="back" text={t('changeTipsCalculator.calculateTips')} zIndex={EVENT_Z_INDEX} color="blue" onClick={handleCalculateTips} disabled={customerGivenSumNumber <= roundedTotalPrice} />
					<Button name="send" icon={'print'} text={t('Cart.Buttons.print')} keyButton="F2" zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} disabled={isDifferenceSumError || tipSumNumber < 0} />
				</ButtonsWrapper>
			</CustomModal>

			{isNeededZIndex && !isMobile &&
				<KeyBindings enter={handleEnter}
										 zero={() => handleKeyboardType('0')}
										 one={() => handleKeyboardType('1')}
										 two={() => handleKeyboardType('2')}
										 three={() => handleKeyboardType('3')}
										 four={() => handleKeyboardType('4')}
										 five={() => handleKeyboardType('5')}
										 six={() => handleKeyboardType('6')}
										 seven={() => handleKeyboardType('7')}
										 eight={() => handleKeyboardType('8')}
										 nine={() => handleKeyboardType('9')}
										 comma={() => handleCommaClick('.')}
										 tab={handleChangeActiveInput}
										 backspace={() => handleKeyboardType('', true)} />}
		</ChangeCalculatorWrapper>
	)
})

ModalChangeTipsCalculator.displayName = 'ModalChangeTipsCalculator'
export default ModalChangeTipsCalculator
