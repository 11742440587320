import { APPEND_INVOICES, SET_INVOICE_LINK_IS_LOADING, SET_INVOICES, SET_INVOICES_IS_LOADING, SET_INVOICES_IS_NOT_FOUND, SET_INVOICES_TOTAL_LENGTH } from '../../actionTypes/zohoInvoice'

export const setInvoices = value => ({ type: SET_INVOICES, value })

export const appendInvoices = value => ({ type: APPEND_INVOICES, value })

export const setInvoicesTotalLength = value => ({ type: SET_INVOICES_TOTAL_LENGTH, value })

export const setInvoicesIsLoading = value => ({ type: SET_INVOICES_IS_LOADING, value })

export const setInvoiceLinkIsLoading = value => ({ type: SET_INVOICE_LINK_IS_LOADING, value })

export const setInvoicesIsNotFound = value => ({ type: SET_INVOICES_IS_NOT_FOUND, value })
