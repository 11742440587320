import { ADD_NEW_PRE_ORDER_DAYS, ADD_NEW_PRE_ORDER_TODAY, ADD_NEW_UNPAID_ORDER, ADD_ORDERS, ADD_PRE_ORDERS_DAYS, ADD_PRE_ORDERS_TODAY, ADD_UNPAID_ORDERS, RE_INITIALIZE_ORDER, REMOVE_PRE_ORDER_DAYS, REMOVE_PRE_ORDER_TODAY, REMOVE_UNPAID_ORDER, SET_IS_PROCESSING_CONFIRM_ORDER, SET_IS_PROCESSING_DENY_ORDER, SET_ORDER_PUBLIC_ID, SET_ORDERS, SET_ORDERS_LOADING, SET_ORDERS_TOTAL_LENGTH, SET_PRE_ORDERS_DAYS, SET_PRE_ORDERS_DAYS_TOTAL_LENGTH, SET_PRE_ORDERS_TODAY, SET_PRE_ORDERS_TODAY_TOTAL_LENGTH, SET_SELECTED_ORDER, SET_SHOW_ORDER_CLOSED_ERROR_MODAL, SET_SHOW_TODAY_PRE_ORDERS_MODAL, SET_SUM_ORDERS, SET_UNPAID_ORDERS, SET_UNPAID_ORDERS_TOTAL_LENGTH, UPDATE_ORDER, UPDATE_PRE_ORDER_DAYS, UPDATE_PRE_ORDER_TODAY, UPDATE_UNPAID_ORDER } from '../../actionTypes/order'
import { ADD_NEW_ORDER, REMOVE_ORDER } from '../../actionTypes'

export const reInitializeOrder = () => ({ type: RE_INITIALIZE_ORDER })

export const addOrders = value => ({ type: ADD_ORDERS, value })

export const setSelectedOrder = value => ({ type: SET_SELECTED_ORDER, value })

export const setOrders = value => ({ type: SET_ORDERS, value })

export const addNewOrder = value => ({ type: ADD_NEW_ORDER, value })

export const removeOrder = value => ({ type: REMOVE_ORDER, value })

export const setOrdersLoading = value => ({ type: SET_ORDERS_LOADING, value })

export const setOrdersTotalLength = value => ({ type: SET_ORDERS_TOTAL_LENGTH, value })

export const updateOrder = value => ({ type: UPDATE_ORDER, value })

export const setSumOrders = value => ({ type: SET_SUM_ORDERS, value })

export const setShowOrderClosedErrorModal = value => ({ type: SET_SHOW_ORDER_CLOSED_ERROR_MODAL, value })

export const addPreOrdersToday = value => ({ type: ADD_PRE_ORDERS_TODAY, value })

export const setPreOrdersTodayTotalLength = value => ({ type: SET_PRE_ORDERS_TODAY_TOTAL_LENGTH, value })

export const addPreOrdersDays = value => ({ type: ADD_PRE_ORDERS_DAYS, value })

export const setPreOrdersDaysTotalLength = value => ({ type: SET_PRE_ORDERS_DAYS_TOTAL_LENGTH, value })

export const addNewPreOrderToday = value => ({ type: ADD_NEW_PRE_ORDER_TODAY, value })

export const updatePreOrderToday = value => ({ type: UPDATE_PRE_ORDER_TODAY, value })

export const removePreOrderToday = value => ({ type: REMOVE_PRE_ORDER_TODAY, value })

export const addNewPreOrderDays = value => ({ type: ADD_NEW_PRE_ORDER_DAYS, value })

export const updatePreOrderDays = value => ({ type: UPDATE_PRE_ORDER_DAYS, value })

export const removePreOrderDays = value => ({ type: REMOVE_PRE_ORDER_DAYS, value })

export const setPreOrdersToday = value => ({ type: SET_PRE_ORDERS_TODAY, value })

export const setPreOrdersDays = value => ({ type: SET_PRE_ORDERS_DAYS, value })

export const setShowTodayPreOrdersModal = value => ({ type: SET_SHOW_TODAY_PRE_ORDERS_MODAL, value })

export const addUnpaidOrders = value => ({ type: ADD_UNPAID_ORDERS, value })

export const setUnpaidOrdersTotalLength = value => ({ type: SET_UNPAID_ORDERS_TOTAL_LENGTH, value })

export const setUnpaidOrders = value => ({ type: SET_UNPAID_ORDERS, value })

export const addNewUnpaidOrder = value => ({ type: ADD_NEW_UNPAID_ORDER, value })

export const removeUnpaidOrder = value => ({ type: REMOVE_UNPAID_ORDER, value })

export const updateUnpaidOrder = value => ({ type: UPDATE_UNPAID_ORDER, value })

export const setIsProcessingConfirmOrder = value => ({ type: SET_IS_PROCESSING_CONFIRM_ORDER, value })

export const setIsProcessingDenyOrder = value => ({ type: SET_IS_PROCESSING_DENY_ORDER, value })

export const setOrderPublicId = value => ({ type: SET_ORDER_PUBLIC_ID, value })
