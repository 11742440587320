import { getAppEnvironment } from './helper'

const internalEnv = getAppEnvironment() === 'live' ? '' : (window.REACT_APP_ENVIRONMENT + '.')

export const productServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/product`

export const customerServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/customer`

export const orderServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/order`

export const orderViewServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/order-view`

export const printServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/print`

export const reportServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/report`

export const phoneServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/phone`

export const geoDataServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/geodata`

export const takeawayPluginUrl = `https://${internalEnv}internal.api.liefersoft.de/takeaway-plugin`

export const apiPluginUrl = `https://${internalEnv}internal.api.liefersoft.de/api`

export const restaurantServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/restaurant`

export const ordersmartPluginUrl = `https://${internalEnv}internal.api.liefersoft.de/ordersmart-plugin`

export const cashBookServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/cashbook`

export const websocketProxyUrl = `https://${internalEnv}internal.api.liefersoft.de/websocketproxy`

export const uberEatPluginUrl = `https://${internalEnv}internal.api.liefersoft.de/ubereat-plugin`

export const tseServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/tse`

export const migrationServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/migration`

export const fiscalServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/fiscal`

export const registrationServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/registration`

export const shopServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/shop`

export const onlineShopUIUrl = `https://${internalEnv}shop.liefersoft.de`

export const woltPluginUrl = `https://${internalEnv}internal.api.liefersoft.de/wolt-plugin`

export const checkoutServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/checkout`

export const marketingServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/marketing`

export const imageServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/image`

export const cloudinaryUrl = 'https://api.cloudinary.com/v1_1'

export const kioskUIUrl = `https://${internalEnv}kiosk.liefersoft.de`

export const paymentServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/payment`

export const foodoraPluginUrl = `https://${internalEnv}internal.api.liefersoft.de/foodora-plugin`

export const kitchenServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/kitchen`

export const billingServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/billing`

export const cashDeskServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/cashdesk`

export const invoiceServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/invoice`

export const digitalMenuServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/digital-menu`

export const digitalMenuCheckoutServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/digitalmenu-checkout`

export const subscriptionServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/subscription`

export const receiptServiceUrl = `https://${internalEnv}internal.api.liefersoft.de/receipt`

export const orderAndPayUIUrl = `https://${internalEnv}order-and-pay.liefersoft.de`

export const receiptUIUrl = `https://${internalEnv}receipt.liefersoft.de`
