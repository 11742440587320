import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ErrorMessageWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.44em;
	align-items: start;
	margin: 0.94em 0;

	svg {
		width: 1.25em;
		height: 1.25em;
		flex-shrink: 0;
		fill: ${colorTheme.message.error.icon};
	}

&.not-marginBtm {
	margin-bottom: 0;
}

&.textLeft {
	text-align: left;
}
`
ErrorMessageWrapper.displayName = 'ErrorMessageWrapper'

export const ErrorMessageText = styled.div`
	font-family: ${theme.fontRoboto};
	font-size: 1.13em;
	font-weight: 500;
	color: ${colorTheme.message.error.text};

	a {
		color: ${colorTheme.message.error.link};
	}
`
ErrorMessageText.displayName = 'ErrorMessageText'
