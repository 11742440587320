import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const KitchenMonitorConfig = lazyWithRetry(() => import(/* webpackChunkName: "kitchen-monitor-settings" */'../../component/KitchenMonitorSettings/KitchenMonitorConfig'))

const KitchenMonitorSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			 <KitchenMonitorConfig />
		</Suspense>
	)
})

KitchenMonitorSettingsPage.displayName = 'KitchenMonitorSettingsPage'
export default KitchenMonitorSettingsPage
