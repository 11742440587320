import React from 'react'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import ButtonIcon from '../../../../../img/svg/button'
import { ActionButton, ActionButtonsWrapper } from '../../../table/ActionButtons/index.styled'
import { CartProductWrap, Product, ProductCol, ProductName, ProductNr, ProductTotal } from '../CartProduct/index.styled'
import { useTranslation } from 'react-i18next'

const CartCoupon = React.memo(({ coupon, active, handleRemoveCoupon }) => {
	const { t } = useTranslation()
	const [touchPad] = useLocalStorage('touchPad', false)
	const name = coupon.name
	const isCanceledItem = coupon?.status === 'CANCELED'
	const isStampVoucher = coupon?.type === 'STAMP_VOUCHER'
	const layout = (touchPad ? 'touchPad ' : '') + (active ? 'active' : '') + (isCanceledItem ? ' strikethrough' : '')
	const deleteButtonLayout = (touchPad ? 'touchPad-cart ' : 'cart')

	return (
		<CartProductWrap className={layout}>
			<Product className={layout}>
				<ProductNr className={layout} />
				<ProductName className={layout}>
					{t('Cart.coupon')} {name}
				</ProductName>
				<ProductCol className={layout} />
				<ProductTotal className={layout} />
				{!isCanceledItem && !isStampVoucher && <ActionButtonsWrapper>
					<ActionButton onClick={handleRemoveCoupon} data-testid="delete-coupon" className={deleteButtonLayout}>
						<ButtonIcon icon="delete" />
					</ActionButton>
				</ActionButtonsWrapper>}
			</Product>
		</CartProductWrap>
	)
})

CartCoupon.displayName = 'CartCoupon'
export default CartCoupon
