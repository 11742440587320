export const SET_CART_PAYMENT_PAYMENTS = 'SET_CART_PAYMENT_PAYMENTS'
export const SET_CART_PAYMENT_TERMINALS = 'SET_CART_PAYMENT_TERMINALS'
export const APPEND_CART_PAYMENT_TERMINALS = 'APPEND_CART_PAYMENT_TERMINALS'
export const SET_CART_PAYMENT_TERMINALS_LENGTH = 'SET_CART_PAYMENT_TERMINALS_LENGTH'
export const SET_CART_PAYMENT_SELECTED_TERMINAL = 'SET_CART_PAYMENT_SELECTED_TERMINAL'
export const SET_PROCESS_PAYMENT_IS_LOADING = 'SET_PROCESS_PAYMENT_IS_LOADING'
export const SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION = 'SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION'
export const SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION_INVALID = 'SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION_INVALID'
export const SET_CART_PAYMENT_TERMINAL_PROCESS_ERROR = 'SET_CART_PAYMENT_TERMINAL_PROCESS_ERROR'
export const SET_CART_PAYMENT_INVOICE_CONFIGS = 'SET_CART_PAYMENT_INVOICE_CONFIGS'
export const SET_CART_PAYMENT_SESSION_CREATE_IS_LOADING = 'SET_CART_PAYMENT_SESSION_CREATE_IS_LOADING'
export const SET_CART_PAYMENT_SESSION_PROCESS_IS_LOADING = 'SET_CART_PAYMENT_SESSION_PROCESS_IS_LOADING'
export const SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION = 'SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION'
export const SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_IS_CREATING = 'SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_IS_CREATING'
export const SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_STARTED = 'SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_STARTED'
export const SET_CART_PAYMENT_GIFT_COUPONS = 'SET_CART_PAYMENT_GIFT_COUPONS'
export const SET_CART_PAYMENT_TAP_2_PAY_SESSION_CREATE_IS_LOADING = 'SET_CART_PAYMENT_TAP_2_PAY_SESSION_CREATE_IS_LOADING'
export const SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION = 'SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION'
export const SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION_INVALID = 'SET_CART_PAYMENT_TAP_2_PAY_PAYMENT_SESSION_INVALID'
export const SET_CART_PAYMENT_TAP_2_PAY_CREATE_SESSION_ERROR = 'SET_CART_PAYMENT_TAP_2_PAY_CREATE_SESSION_ERROR'
export const SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN = 'SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN'
export const SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN_ERROR = 'SET_CART_PAYMENT_TAP_2_PAY_CONNECTION_TOKEN_ERROR'
export const SET_CART_PAYMENT_TAP_2_PAY_ENABLED = 'SET_CART_PAYMENT_TAP_2_PAY_ENABLED'
export const SET_CART_PAYMENT_TAP_2_PAY_ERROR_TYPE = 'SET_CART_PAYMENT_TAP_2_PAY_ERROR_TYPE'
export const SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS = 'SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS'
export const SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS_ERROR = 'SET_CART_PAYMENT_TAP_2_PAY_ACCOUNT_DETAILS_ERROR'
export const SET_CART_PAYMENT_TAP_2_PAY_RETRY_IS_LOADING = 'SET_CART_PAYMENT_TAP_2_PAY_RETRY_IS_LOADING'
export const SET_CART_PAYMENT_DEPOSITS = 'SET_CART_PAYMENT_DEPOSITS'
