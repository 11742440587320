import { createSelector } from 'reselect'

const selectValidatedFlyerCoupon = store => store.coupon?.validatedFlyerCoupon
const selectFlyerCouponIsInvalid = store => store.coupon?.flyerCouponIsInvalid
const selectFlyerCouponIsUsed = store => store.coupon?.flyerCouponIsUsed

export const selectFlyerCouponError = (totalOrderPrice, t) => createSelector(
	selectFlyerCouponIsInvalid,
	selectFlyerCouponIsUsed,
	selectValidatedFlyerCoupon,
	(flyerCouponIsInvalid, flyerCouponIsUsed, flyerCoupon) => {
		if (flyerCouponIsInvalid) return t('FlyerCoupon.flyerCouponInvalid')
		if (flyerCouponIsUsed) return t('FlyerCoupon.flyerCouponIsUsed')
		if (totalOrderPrice && (totalOrderPrice < flyerCoupon?.minOrderAmount) ) return t('FlyerCoupon.couponMinimumOrderValue')
		return null
	},
)
