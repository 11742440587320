import styled from 'styled-components'
import { theme } from '../../style/theme'
import { colorTheme } from '../../style/theme/colorTheme'

export const MainWrapperStyled = styled.div`
	border-radius: 0.88rem 0 0 0.88rem;
	background: ${colorTheme.main.background};
	overflow-y: auto;
	padding: 0;
	grid-column: 2;
	grid-row: 2;
	transition: all 0.2s;

	&.centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.authPage {
		border-radius: 1.5rem 1.5rem 0 0;
		overflow-y: visible;
	}

	&.with-recaptcha {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 0.63em;
	}

	&.kitchenMonitor {
		border-radius: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		border-radius: 0.75rem 0.75rem 0 0;
		grid-column: 1;

		&.authPage {
			grid-column: 1;
			border-radius: 1.72rem 1.72rem 0 0;
		}

		&.with-recaptcha {
			padding-bottom: 0.72em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		overflow-y: visible;

		&.centered {
			display: block;
		}

		&:after {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			background-color: ${colorTheme.main.background};
			z-index: 100;
			opacity: 0;
			transition: all 0.2s;
		}

		&:not(.toggled) {
			position: relative;
			border-radius: 0.75rem 0 0 0;
			filter: blur(2px) contrast(33%);

			&:after {
				display: block;
				opacity: 1;
			}

			> * {
				width: 100%;
			}
		}

		&.authPage {
			border-radius: 1.5rem 1.5rem 0 0;
		}

		&.with-recaptcha {
			padding-bottom: 0.63em;
		}

		&.restaurant-mob {
			background: ${colorTheme.main.backgroundRestaurantMob};;
		}

		&.partner-portal {
			overflow-y: auto;
		}
	}
`
