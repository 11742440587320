export const getColorType = (color) => {
	let colorType

	if (['success', 'primary', 'danger', 'light', 'secondary_light'].includes(color)) {
		return color
	}

	switch (color) {
		case 'green':
			colorType = 'success'
			break
		case 'blue':
			colorType = 'primary'
			break
		case 'red':
			colorType = 'danger'
			break
		case 'white':
			colorType = 'light'
			break
		case 'gray-light':
			colorType = 'secondary_light'
			break
		case 'gray-lighter':
			colorType = 'secondary_gray_light'
			break
		case 'gray-lightest':
			colorType = 'secondary_gray_lighter'
			break
		case 'outline-gray':
			colorType = 'outline_gray'
			break
		case 'gray':
		default:
			colorType = 'secondary'
			break
	}

	return colorType
}
