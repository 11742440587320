import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const FlyerCouponConfig = lazyWithRetry(() => import(/* webpackChunkName: "flyer-coupon-config" */'../../component/Coupon/flyerCoupons/config'))

const FlyerCouponConfigPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<FlyerCouponConfig />
		</Suspense>
	)
})

FlyerCouponConfigPage.displayName = 'FlyerCouponConfigPage'
export default FlyerCouponConfigPage
