import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { SelectWrapper } from './SelectControlled/index.styled'

export const RestaurantDiscountWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	gap: 0.63em;
	margin-bottom: 1.25em;

	&.desk-cols-2 {
		grid-column: 1 / 3;
		grid-template-columns: repeat(2, 1fr);

		${SelectWrapper} {
			grid-column: 1;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		&.desk-cols-2 {
			grid-column: initial;
			grid-template-columns: 1fr;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 1.875em;
	}
`
RestaurantDiscountWrapper.displayName = 'RestaurantDiscountWrapper'

export const DiscountFormInputs = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: auto 6.4em;
	gap: 0.63em;

	&.desk-cols-2 {
		grid-column: 1 / 3;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.desk-cols-2 {
			grid-column: initial;
			grid-template-columns: 1fr;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 0.63em;
	}
`
DiscountFormInputs.displayName = 'DiscountFormInputs'

export const CouponFormInputs = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: auto 12.5em;
	gap: 0.63em;

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 0.63em;
		grid-template-columns: 1fr;
	}
`
CouponFormInputs.displayName = 'CouponFormInputs'

export const FlyerCouponWrap = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;`
FlyerCouponWrap.displayName = 'FlyerCouponWrap'

export const DiscountTitle = styled.div`
	font-family: ${theme.fontRobotoBold};
	display: flex;
	gap: 0.25em;
	font-weight: 500;
	font-size: 1.25em;
	color: ${colorTheme.restaurantDiscount.title};
	text-align: left;

	> svg {
		width: 1.2em;
		height: 1.2em;
		flex-shrink: 0;
	}
`
DiscountTitle.displayName = 'DiscountTitle'

export const DiscountMessage = styled.div`
	font-size: 1em;
	display: flex;
	gap: 0.43em;
	text-align: left;
	color: ${colorTheme.restaurantDiscount.message.title};

	> svg {
		width: 1.5em;
		height: 1.5em;
		flex-shrink: 0;
		fill: ${colorTheme.restaurantDiscount.message.icon};
	}

	&.desk-cols-2 {
		grid-row: 2;
		grid-column: 2;
		transform: translateY(30%);
	}

	@media screen and (max-width: ${theme.point820}) {
		&.desk-cols-2 {
			grid-row: initial;
			grid-column: initial;
			transform: none;
		}
	}
`
DiscountMessage.displayName = 'DiscountMessage'
