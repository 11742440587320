import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../../../Elements/modal/CustomModal'
import { useTranslation } from 'react-i18next'
import { AreaColorCircle, ModalTableWaiterNotifiedHeaderTitle, ModalTableWaiterNotifiedItemsBlock, ModalTableWaiterNotifiedTitleArea, ModalTableWaiterNotifiedTitleText, ModalTableWaiterNotifiedTitleWrapper, ModalTableWaiterNotifiedWrapper } from './index.styled'
import { selectRestaurantSelectedArea, selectTableWaiterNotifiedOrders } from '../../../../../redux/selector/restaurant'
import { fetchTableOrdersThunk, updateTableWaiterNotificationStatusThunk } from '../../../../../redux/thunk/restaurant/order'
import CartItem from './CartItem'
import Button from '../../../../Elements/buttons/Button'
import { setRestaurantShowTableWaiterNotifiedModal, setRestaurantTable } from '../../../../../redux/action/restaurant'
import CircleLoader from '../../../../Elements/loaders/CircleLoader'
import { setTableCartOrders, setTableCartSplits } from '../../../../../redux/action/tableCart'
import { fetchTableSplitsThunk } from '../../../../../redux/thunk/restaurant/split'

const Z_INDEX = 94

const ModalTableWaiterNotified = React.memo(() => {
	const { t } = useTranslation()
	const selectedTable = useSelector(state => state.restaurant.table)
	const showTableWaiterNotifiedModal = useSelector(state => state.restaurant.showTableWaiterNotifiedModal)
	const waiterNotifiedOrders = useSelector(selectTableWaiterNotifiedOrders)
	const tableArea = useSelector(selectRestaurantSelectedArea)
	const { accessToken: waiterAccessToken } = useSelector(state => state.modalLogin)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const hasModalLogin = !!waiterAccessToken
	const areaTitle = tableArea?.name
	const areaColor = tableArea?.meta?.color

	const title =
		<ModalTableWaiterNotifiedTitleWrapper>
			<ModalTableWaiterNotifiedTitleArea>
				<AreaColorCircle color={areaColor} />
				<ModalTableWaiterNotifiedTitleText className="area">{areaTitle}</ModalTableWaiterNotifiedTitleText>
			</ModalTableWaiterNotifiedTitleArea>
			<ModalTableWaiterNotifiedTitleText>{t('restaurant.tables.modal.table')} #{selectedTable?.number}</ModalTableWaiterNotifiedTitleText>
		</ModalTableWaiterNotifiedTitleWrapper>

	useEffect(() => {
		if (showTableWaiterNotifiedModal && selectedTable?.id) {
			dispatch(fetchTableOrdersThunk(selectedTable?.id))
			dispatch(fetchTableSplitsThunk(selectedTable?.id))
		}
	}, [showTableWaiterNotifiedModal, selectedTable?.id]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setRestaurantShowTableWaiterNotifiedModal(false))
		dispatch(setRestaurantTable(null))
		dispatch(setTableCartOrders([]))
		dispatch(setTableCartSplits([]))
	}, [dispatch])

	const handleWaiterSubmit = useCallback(async () => {
		setLoading(true)
		const updatedWaiterNotifiedOrders = waiterNotifiedOrders.map(order => ({
			...order,
			items: order.items.map(item => ({
				itemPositionId: item.itemPositionId,
				kitchenMeta: { notificationStatus: 'WAITER_SUBMITTED' },
			})),
		}))
		await dispatch(updateTableWaiterNotificationStatusThunk(selectedTable?.id, updatedWaiterNotifiedOrders, hasModalLogin))
		setLoading(false)
		handleClose()
	}, [dispatch, selectedTable, waiterNotifiedOrders, hasModalLogin, handleClose])

	return (
		<CustomModal isOpen={showTableWaiterNotifiedModal}
								 close={handleClose}
								 size="x-small"
								 title={title}
								 zIndex={Z_INDEX}
								 disableBackButton={true}
								 button={
									 <Button name="ok" icon={!loading && 'checkmark'} color="green"
													 text={t('buttons.controls.ok')}
													 onClick={handleWaiterSubmit}
													 disabled={loading}
													 keyButton="F2" keyText="F2" zIndex={Z_INDEX}>
										 {loading && <CircleLoader />}
									 </Button>
								 }>
			<ModalTableWaiterNotifiedWrapper>
				<ModalTableWaiterNotifiedHeaderTitle>
					{t('restaurant.tables.modal.table_items_prepared')}
				</ModalTableWaiterNotifiedHeaderTitle>
				<ModalTableWaiterNotifiedItemsBlock>
					{waiterNotifiedOrders.map((order, orderIndex) =>
						order.items.map((item, itemIndex) =>
							<CartItem key={`${orderIndex}-${itemIndex}`} index={`${orderIndex}-${itemIndex}`} item={item} />,
						))}
				</ModalTableWaiterNotifiedItemsBlock>
			</ModalTableWaiterNotifiedWrapper>
		</CustomModal>
	)
})

ModalTableWaiterNotified.displayName = 'ModalTableWaiterNotified'
export default ModalTableWaiterNotified
