import { ADD_NEW_PRE_ORDER_DAYS, ADD_NEW_PRE_ORDER_TODAY, ADD_NEW_UNPAID_ORDER, ADD_ORDERS, ADD_PRE_ORDERS_DAYS, ADD_PRE_ORDERS_TODAY, ADD_UNPAID_ORDERS, RE_INITIALIZE_ORDER, REMOVE_PRE_ORDER_DAYS, REMOVE_PRE_ORDER_TODAY, REMOVE_UNPAID_ORDER, SET_IS_PROCESSING_CONFIRM_ORDER, SET_IS_PROCESSING_DENY_ORDER, SET_ORDER_PUBLIC_ID, SET_ORDERS, SET_ORDERS_LOADING, SET_ORDERS_TOTAL_LENGTH, SET_PRE_ORDERS_DAYS, SET_PRE_ORDERS_DAYS_TOTAL_LENGTH, SET_PRE_ORDERS_TODAY, SET_PRE_ORDERS_TODAY_TOTAL_LENGTH, SET_SELECTED_ORDER, SET_SHOW_ORDER_CLOSED_ERROR_MODAL, SET_SHOW_TODAY_PRE_ORDERS_MODAL, SET_SUM_ORDERS, SET_UNPAID_ORDERS, SET_UNPAID_ORDERS_TOTAL_LENGTH, UPDATE_ORDER, UPDATE_PRE_ORDER_DAYS, UPDATE_PRE_ORDER_TODAY, UPDATE_UNPAID_ORDER } from '../../actionTypes/order'
import { ADD_NEW_ORDER, REMOVE_ORDER } from '../../actionTypes'
import { getSortedOrders } from './constants'

const initialState = {
	orders: [],
	ordersLoading: false,
	selectedOrder: null,
	ordersTotalLength: 0,
	sumOrders: 0,
	showOrderClosedErrorModal: false,
	preOrdersToday: [],
	preOrdersDays: [],
	preOrdersTodayTotalLength: 0,
	preOrdersDaysTotalLength: 0,
	showTodayPreOrderModal: false,
	unpaidOrders: [],
	unpaidOrdersTotalLength: 0,
	isProcessingConfirmOrder: false,
	isProcessingDenyOrder: false,
	orderPublicId: null
}

export function orderReducer (state = initialState, action) {
	switch (action.type) {
		case RE_INITIALIZE_ORDER:
			return initialState
		case ADD_ORDERS:
			let ordersCopy = [...state.orders]
			ordersCopy = ordersCopy.concat(action.value)
			ordersCopy = ordersCopy.filter((item, index, items) =>
				items.filter((subItem, subIndex) =>
					item.orderId === subItem.orderId && index !== subIndex && index < subIndex).length === 0)
			return {
				...state,
				orders: ordersCopy,
			}
		case SET_SELECTED_ORDER:
			return {
				...state,
				selectedOrder: action.value,
			}
		case SET_ORDERS:
			return {
				...state,
				orders: action.value,
			}
		case ADD_NEW_ORDER:
			let ordersNewCopy = [...state.orders]
			ordersNewCopy.unshift(action.value.order)
			ordersNewCopy = getSortedOrders(ordersNewCopy, action.value.reverse)
			return {
				...state,
				orders: ordersNewCopy,
			}
		case REMOVE_ORDER:
			let ordersRemovedCopy = [...state.orders]
			ordersRemovedCopy = ordersRemovedCopy.filter(order => order.orderId !== action.value.orderId)
			return {
				...state,
				orders: ordersRemovedCopy,
			}
		case SET_ORDERS_TOTAL_LENGTH:
			return {
				...state,
				ordersTotalLength: action.value,
			}
		case UPDATE_ORDER:
			let ordersUpdateCopy = [...state.orders]
			const orderToUpdate = ordersUpdateCopy.find(order => order.orderId === action.value.order.orderId)
			if (orderToUpdate) {
				ordersUpdateCopy[ordersUpdateCopy.indexOf(orderToUpdate)] = action.value.order
				ordersUpdateCopy = getSortedOrders(ordersUpdateCopy, action.value.reverse)
			}
			return {
				...state,
				orders: ordersUpdateCopy,
			}
		case SET_ORDERS_LOADING:
			return {
				...state,
				ordersLoading: action.value,
			}
		case SET_SUM_ORDERS:
			return {
				...state,
				sumOrders: action.value,
			}
		case SET_SHOW_ORDER_CLOSED_ERROR_MODAL:
			return {
				...state,
				showOrderClosedErrorModal: action.value,
			}
		case ADD_PRE_ORDERS_TODAY:
			let preOrdersTodayCopy = [...state.preOrdersToday]
			preOrdersTodayCopy = preOrdersTodayCopy.concat(action.value)
			preOrdersTodayCopy = preOrdersTodayCopy.filter((item, index, items) =>
				items.filter((subItem, subIndex) =>
					item.orderId === subItem.orderId && index !== subIndex && index < subIndex).length === 0)
			return {
				...state,
				preOrdersToday: preOrdersTodayCopy,
			}
		case SET_PRE_ORDERS_TODAY_TOTAL_LENGTH:
			return {
				...state,
				preOrdersTodayTotalLength: action.value,
			}
		case ADD_PRE_ORDERS_DAYS:
			let preOrdersDaysCopy = [...state.preOrdersDays]
			preOrdersDaysCopy = preOrdersDaysCopy.concat(action.value)
			preOrdersDaysCopy = preOrdersDaysCopy.filter((item, index, items) =>
				items.filter((subItem, subIndex) =>
					item.orderId === subItem.orderId && index !== subIndex && index < subIndex).length === 0)
			return {
				...state,
				preOrdersDays: preOrdersDaysCopy,
			}
		case SET_PRE_ORDERS_DAYS_TOTAL_LENGTH:
			return {
				...state,
				preOrdersDaysTotalLength: action.value,
			}
		case ADD_NEW_PRE_ORDER_TODAY:
			let preOrdersTodayNewCopy = [...state.preOrdersToday]
			preOrdersTodayNewCopy.unshift(action.value.order)
			preOrdersTodayNewCopy = getSortedOrders(preOrdersTodayNewCopy, action.value.reverse)
			return {
				...state,
				preOrdersToday: preOrdersTodayNewCopy,
			}
		case UPDATE_PRE_ORDER_TODAY:
			let preOrdersTodayUpdateCopy = [...state.preOrdersToday]
			const preOrdersTodayToUpdate = preOrdersTodayUpdateCopy.find(order => order.orderId === action.value.order.orderId)
			if (preOrdersTodayToUpdate) {
				preOrdersTodayUpdateCopy[preOrdersTodayUpdateCopy.indexOf(preOrdersTodayToUpdate)] = action.value.order
				preOrdersTodayUpdateCopy = getSortedOrders(preOrdersTodayUpdateCopy, action.value.reverse)
			}
			return {
				...state,
				preOrdersToday: preOrdersTodayUpdateCopy,
			}
		case REMOVE_PRE_ORDER_TODAY:
			let preOrdersTodayRemovedCopy = [...state.preOrdersToday]
			preOrdersTodayRemovedCopy = preOrdersTodayRemovedCopy.filter(order => order.orderId !== action.value.orderId)
			return {
				...state,
				preOrdersToday: preOrdersTodayRemovedCopy,
			}
		case ADD_NEW_PRE_ORDER_DAYS:
			let preOrdersDaysNewCopy = [...state.preOrdersDays]
			preOrdersDaysNewCopy.unshift(action.value.order)
			preOrdersDaysNewCopy = getSortedOrders(preOrdersDaysNewCopy, action.value.reverse)
			return {
				...state,
				preOrdersDays: preOrdersDaysNewCopy,
			}
		case UPDATE_PRE_ORDER_DAYS:
			let preOrdersDaysUpdateCopy = [...state.preOrdersDays]
			const preOrdersDaysToUpdate = preOrdersDaysUpdateCopy.find(order => order.orderId === action.value.order.orderId)
			if (preOrdersDaysToUpdate) {
				preOrdersDaysUpdateCopy[preOrdersDaysUpdateCopy.indexOf(preOrdersDaysToUpdate)] = action.value.order
				preOrdersDaysUpdateCopy = getSortedOrders(preOrdersDaysUpdateCopy, action.value.reverse)
			}
			return {
				...state,
				preOrdersDays: preOrdersDaysUpdateCopy,
			}
		case REMOVE_PRE_ORDER_DAYS:
			let preOrdersDaysRemovedCopy = [...state.preOrdersDays]
			preOrdersDaysRemovedCopy = preOrdersDaysRemovedCopy.filter(order => order.orderId !== action.value.orderId)
			return {
				...state,
				preOrdersDays: preOrdersDaysRemovedCopy,
			}
		case SET_PRE_ORDERS_TODAY:
			return {
				...state,
				preOrdersToday: action.value,
			}
		case SET_PRE_ORDERS_DAYS:
			return {
				...state,
				preOrdersDays: action.value,
			}
		case SET_SHOW_TODAY_PRE_ORDERS_MODAL:
			return {
				...state,
				showTodayPreOrderModal: action.value,
			}
		case ADD_UNPAID_ORDERS:
			let unpaidOrdersCopy = [...state.unpaidOrders]
			unpaidOrdersCopy = unpaidOrdersCopy.concat(action.value)
			unpaidOrdersCopy = unpaidOrdersCopy.filter((item, index, items) =>
				items.filter((subItem, subIndex) =>
					item.orderId === subItem.orderId && index !== subIndex && index < subIndex).length === 0)
			return {
				...state,
				unpaidOrders: unpaidOrdersCopy,
			}
		case SET_UNPAID_ORDERS_TOTAL_LENGTH:
			return {
				...state,
				unpaidOrdersTotalLength: action.value,
			}
		case SET_UNPAID_ORDERS:
			return {
				...state,
				unpaidOrders: action.value,
			}
		case ADD_NEW_UNPAID_ORDER:
			let unpaidOrdersNewCopy = [...state.unpaidOrders]
			unpaidOrdersNewCopy.unshift(action.value.order)
			unpaidOrdersNewCopy = getSortedOrders(unpaidOrdersNewCopy, action.value.reverse)
			return {
				...state,
				unpaidOrders: unpaidOrdersNewCopy,
			}
		case REMOVE_UNPAID_ORDER:
			let unpaidOrdersRemovedCopy = [...state.unpaidOrders]
			unpaidOrdersRemovedCopy = unpaidOrdersRemovedCopy.filter(order => order.orderId !== action.value.orderId)
			return {
				...state,
				unpaidOrders: unpaidOrdersRemovedCopy,
			}
		case UPDATE_UNPAID_ORDER:
			let unpaidOrdersUpdateCopy = [...state.unpaidOrders]
			const unpaidOrderToUpdate = unpaidOrdersUpdateCopy.find(order => order.orderId === action.value.order.orderId)
			if (unpaidOrderToUpdate) {
				unpaidOrdersUpdateCopy[unpaidOrdersUpdateCopy.indexOf(unpaidOrderToUpdate)] = action.value.order
				unpaidOrdersUpdateCopy = getSortedOrders(unpaidOrdersUpdateCopy, action.value.reverse)
			}
			return {
				...state,
				unpaidOrders: unpaidOrdersUpdateCopy,
			}
		case SET_IS_PROCESSING_CONFIRM_ORDER:
			return {
				...state,
				isProcessingConfirmOrder: action.value,
			}
		case SET_IS_PROCESSING_DENY_ORDER:
			return {
				...state,
				isProcessingDenyOrder: action.value,
			}
		case SET_ORDER_PUBLIC_ID:
			return {
				...state,
				orderPublicId: action.value
			}
		default:
			return state
	}
}
