import { kitchenOrderFilter } from '../../helper/ordersFilter'
import { addNewKitchenOrder, removeKitchenOrder, setKitchenMonitorConfigsUpdate, setKitchenOrdersTotalLength, updateKitchenOrder } from '../../redux/action/kitchenMonitor'
import { selectLastFoldedKitchenOrder } from '../../redux/selector/kitchen'


export const processKitchenMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'kitchen.KITCHEN_ORDER_PLACED':
			handleKitchenMonitorAddOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_ITEMS_CHANGED':
		case 'kitchen.KITCHEN_ORDER_ITEM_CANCELED':
			handleKitchenMonitorUpdateOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_SUB_ITEM_CANCELED':
			handleKitchenMonitorUpdateOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_ITEM_PREPARED':
			handleKitchenMonitorUpdateOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_DAY_COUNTER_ADDED':
			handleKitchenMonitorUpdateOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_CANCELED':
			handleKitchenMonitorDeleteOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_CLOSED':
			handleKitchenMonitorDeleteOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_PREPARED':
			handleKitchenMonitorDeleteOrder(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_ORDER_DISPLAY_STATUS_CHANGED':
			handleKitchenMonitorUpdateOrderDisplayStatus(message.data, dispatch, getState)
			break
		case 'kitchen.KITCHEN_MONITOR_CONFIG_UPDATED':
			handleKitchenMonitorConfigUpdate(message.data, dispatch, getState)
			break
		default:
			// eslint-disable-next-line
			console.warn('Unknown kitchen message type: ', JSON.stringify(message))
			break
	}
}

const handleKitchenMonitorAddOrder = (data, dispatch, getState) => {
	const kitchenOrders = getState().kitchenMonitor.kitchenOrders
	const orderFilterTrue = kitchenOrderFilter(data?.kitchenConfigMonitorId, data.order, { date: getState().orderfilter.date })
	const existOrder = kitchenOrders.find(order => order.orderId === data.orderId)
	const PER_LOAD = 10

	if (orderFilterTrue) {
		if (!existOrder) {
			dispatch(setKitchenOrdersTotalLength(getState().kitchenMonitor.ordersTotalLength + 1))
			if (kitchenOrders.length < PER_LOAD) {
				dispatch(addNewKitchenOrder(data.order))
			}
		}
	}
}

const handleKitchenMonitorUpdateOrder = (data, dispatch, getState) => {
	const kitchenOrders = getState().kitchenMonitor.kitchenOrders
	const existOrder = kitchenOrders.find(order => order.orderId === data.orderId)
	const orderFilterTrue = kitchenOrderFilter(data?.kitchenConfigMonitorId, data.order, { date: getState().orderfilter.date })

	if (orderFilterTrue) {
		if (existOrder) {
			dispatch(updateKitchenOrder(data.order))
		}
	}
}

const handleKitchenMonitorUpdateOrderDisplayStatus = (data, dispatch, getState) => {
	const kitchenOrders = getState().kitchenMonitor.kitchenOrders
	const existOrder = kitchenOrders.find(order => order?.orderId === data.orderId)
	const orderFilterTrue = kitchenOrderFilter(data?.kitchenConfigMonitorId, data.order, { date: getState().orderfilter.date })
	const shouldOpenKitchenOrder = data.order.displayStatus === 'OPENED'

	if (orderFilterTrue) {
		if (existOrder) {
			dispatch(updateKitchenOrder(data.order))
		} else {
			if (shouldOpenKitchenOrder) {
				dispatch(addNewKitchenOrder(data.order))
				const lastFoldedKitchenOrder = selectLastFoldedKitchenOrder(getState())
				if (lastFoldedKitchenOrder) {
					dispatch(removeKitchenOrder(lastFoldedKitchenOrder))
				}
			}
		}
	}
}

const handleKitchenMonitorDeleteOrder = (data, dispatch, getState) => {
	const kitchenOrders = getState().kitchenMonitor.kitchenOrders
	const existOrder = kitchenOrders.find(order => order.orderId === data.orderId)

	if (existOrder) {
		dispatch(removeKitchenOrder(data.order))
		dispatch(setKitchenOrdersTotalLength(getState().kitchenMonitor.ordersTotalLength - 1))
	}
}

export const handleKitchenMonitorConfigUpdate = (data, dispatch, getState) => {
	if (window.location.pathname === '/kitchen-monitor') {
		const configs = getState().kitchenMonitor.kitchenMonitorConfigs
		const isExistConfig = !!configs.find(item => item?.id === data?.kitchenMonitorConfig?.id)
		if (isExistConfig) dispatch(setKitchenMonitorConfigsUpdate(data?.kitchenMonitorConfig))
	}
}
