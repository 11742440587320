import { SET_COUPONS, APPEND_COUPONS, SET_COUPON, SET_COUPONS_LENGTH, SET_COUPON_ERROR_IS_EXIST, SET_COUPON_DISABLED_FOR_KASSE, SET_GIFT_COUPON, SET_GIFT_COUPON_IS_INVALID, SET_GIFT_COUPONS, SET_GIFT_COUPONS_LENGTH, APPEND_GIFT_COUPONS, SET_GIFT_COUPON_IS_USED, SET_GIFT_COUPON_IS_NOT_EXIST, SET_GIFT_COUPON_IS_CANCELED, SET_FLYER_COUPON_ERROR_IS_EXIST, SET_FLYER_COUPON, SET_FLYER_COUPONS, SET_COUPON_ACTIVE_TAB, SET_VALIDATED_FLYER_COUPON, SET_FLYER_COUPON_IS_USED, SET_FLYER_COUPON_IS_INVALID, SET_FLYER_COUPON_LOADING } from '../../actionTypes/coupon'

const initialState = {
	coupons: [],
	coupon: null,
	couponsLength: 0,
	couponIsExist: false,
	couponDisabledForKasse: false,
	giftCoupons: [],
	giftCouponsLength: 0,
	giftCouponIsInvalid: null,
	giftCouponIsUsed: false,
	giftCouponIsNotExist: false,
	giftCouponIsCanceled: false,
	giftCoupon: null,
	flyerCoupons: [],
	flyerCoupon: null,
	flyerCouponIsExist: false,
	couponActiveTab: 'COUPONS',
	flyerCouponLoading: false,
	flyerCouponIsInvalid: null,
	validatedFlyerCoupon: null,
	flyerCouponIsUsed: false,
}

export function couponReducer (state = initialState, action) {
	switch (action.type) {
		case SET_COUPONS:
			return {
				...state,
				coupons: action.value
			}
		case APPEND_COUPONS:
			return {
				...state,
				coupons: state.coupons.concat(...action.value)
			}
		case SET_COUPONS_LENGTH:
			return {
				...state,
				couponsLength: action.value
			}
		case SET_COUPON:
			return {
				...state,
				coupon: action.value
			}
		case SET_COUPON_ERROR_IS_EXIST:
			return {
				...state,
				couponIsExist: action.value
			}
		case SET_COUPON_DISABLED_FOR_KASSE:
			return {
				...state,
				couponDisabledForKasse: action.value
			}
		case SET_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: action.value
			}
		case APPEND_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: state.giftCoupons.concat(...action.value)
			}
		case SET_GIFT_COUPONS_LENGTH:
			return {
				...state,
				giftCouponsLength: action.value
			}
		case SET_GIFT_COUPON_IS_INVALID:
			return {
				...state,
				giftCouponIsInvalid: action.value
			}
		case SET_GIFT_COUPON_IS_USED:
			return {
				...state,
				giftCouponIsUsed: action.value
			}
		case SET_GIFT_COUPON_IS_NOT_EXIST:
			return {
				...state,
				giftCouponIsNotExist: action.value
			}
		case SET_GIFT_COUPON_IS_CANCELED:
			return {
				...state,
				giftCouponIsCanceled: action.value
			}
		case SET_GIFT_COUPON:
			return {
				...state,
				giftCoupon: action.value
			}
		case SET_FLYER_COUPONS:
			return {
				...state,
				flyerCoupons: action.value
			}
		case SET_FLYER_COUPON:
			return {
				...state,
				flyerCoupon: action.value
			}
		case SET_FLYER_COUPON_ERROR_IS_EXIST:
			return {
				...state,
				flyerCouponIsExist: action.value
			}
		case SET_COUPON_ACTIVE_TAB:
			return {
				...state,
				couponActiveTab: action.value
			}
		case SET_FLYER_COUPON_IS_INVALID:
			return {
				...state,
				flyerCouponIsInvalid: action.value,
			}
		case 	SET_FLYER_COUPON_IS_USED:
			return {
				...state,
				flyerCouponIsUsed: action.value,
			}
		case SET_VALIDATED_FLYER_COUPON:
			return {
				...state,
				validatedFlyerCoupon: action.value,
			}
		case SET_FLYER_COUPON_LOADING:
			return {
				...state,
				flyerCouponLoading: action.value,
			}
		default:
			return state
	}
}
