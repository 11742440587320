import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { filterNumber, formatCurrencyOfTextInput, getFloat } from '../../../../helper'
import Button from '../../buttons/Button'
import TextInputControlled from '../../inputs/TextInput/controlled'
import CustomModal from '../../modal/CustomModal'
import { DepositPaymentModalWrap, ErrorText } from './index.styled'
import { selectCurrencySymbol } from '../../../../redux/selector/system'

const EVENT_Z_INDEX = 110

const DepositPaymentModal = React.memo(({
	isOpen, close, totalPrice, addDeposit = () => {
	},
}) => {
	const { t } = useTranslation()
	const currencySymbol = useSelector(selectCurrencySymbol)
	const [depositAmount, setDepositAmount] = useState('0,00')
	const [showDepositAmountError, setShowDepositAmountError] = useState(false)

	const resetErrors = useCallback(() => {
		setShowDepositAmountError(false)
	}, [])

	const handleChange = useCallback((e) => {
		const value = filterNumber(e.target.value)
		setDepositAmount(value)
		resetErrors()
	}, [resetErrors])

	const handleBlur = useCallback(() => {
		formatCurrencyOfTextInput(depositAmount, setDepositAmount)
	}, [depositAmount])

	const onClose = useCallback(() => {
		setDepositAmount('0,00')
		resetErrors()
		close()
	}, [close, resetErrors])

	const handleAddDeposit = useCallback(() => {
		const formattedDepositAmount = getFloat(depositAmount)
		if (formattedDepositAmount > totalPrice) {
			setShowDepositAmountError(true)
			return
		}

		addDeposit(formattedDepositAmount)
		onClose()
	}, [depositAmount, totalPrice, addDeposit, onClose])

	return (
		<CustomModal isOpen={isOpen}
								 close={onClose}
								 title={t('Cart.DepositPaymentModal.title', { currencySymbol })}
								 zIndex={EVENT_Z_INDEX}
								 showBackButton={true}
								 mobileFooter={true}
								 button={<Button onClick={handleAddDeposit}
																 text={t('Cart.DepositPaymentModal.button')}
																 icon="funds"
																 color="green"
																 keyButton="F2"
																 zIndex={EVENT_Z_INDEX} />}>
			<DepositPaymentModalWrap>
				{showDepositAmountError && <ErrorText>{t('Cart.DepositPaymentModal.amountError')}</ErrorText>}
				<TextInputControlled name="deposit-amount"
														 testId="deposit-amount"
														 value={depositAmount}
														 onChange={handleChange}
														 onBlur={handleBlur}
														 color="gray"
														 className="text-size-28" />
			</DepositPaymentModalWrap>
		</CustomModal>
	)
})

DepositPaymentModal.displayName = 'DepositPaymentModal'
export default DepositPaymentModal
