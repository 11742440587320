import { createSelector } from 'reselect'
import { sortKitchenOrders } from './helper'
import { selectTimerCounter } from '../timerCounter'
import { getPreorderStartPrepareTime } from '../../../component/KitchenMonitor/helper'
import { compareAsc } from 'date-fns'
import { selectCategories } from '../products'

export const selectKitchenOrders = store => store.kitchenMonitor.kitchenOrders

export const selectSortedKitchenOrders = createSelector(
	selectKitchenOrders,
	(kitchenOrders) => {
		return [...kitchenOrders].sort(sortKitchenOrders)
	},
)

export const selectOpenedKitchenOrders = createSelector(
	selectSortedKitchenOrders,
	(sortedKitchenOrders) => {
		return sortedKitchenOrders.filter(order => order?.displayStatus === 'OPENED')
	},
)

export const selectLastOpenedKitchenOrder = createSelector(
	selectOpenedKitchenOrders,
	(openedOrders) => {
		return openedOrders[openedOrders.length - 1]
	},
)

export const selectFoldedKitchenOrders = createSelector(
	selectSortedKitchenOrders,
	(sortedKitchenOrders) => {
		return sortedKitchenOrders.filter(order => order?.displayStatus === 'FOLDED')
	},
)

export const selectLastFoldedKitchenOrder = createSelector(
	selectFoldedKitchenOrders,
	(foldedOrders) => {
		return foldedOrders[foldedOrders.length - 1]
	},
)

export const selectShouldShowPreorderTime = (order) => createSelector(
	selectTimerCounter,
	(counter) => {
		const now = new Date()
		const preorderStartPrepareTime = getPreorderStartPrepareTime(order)
		return compareAsc(preorderStartPrepareTime, now) === 1
	},
)

export const selectKitchenMonitorCategories = store => store.kitchenMonitor.kitchenMonitorConfig?.categories

export const selectCombinedCategories = createSelector(
	selectCategories,
	selectKitchenMonitorCategories,
	(categories, categoriesConfig) => {
		if (!categoriesConfig) return
		if (categories?.length === categoriesConfig?.length) {
			return categoriesConfig
		} else {
			return categories.reduce((result, item) => {
				if (!result.find((el) => el.id === item.id)) {
					result.push({ id: item.id, name: item.title, enabled: true })
				}
				return result
			}, [...categoriesConfig])
		}
	},
)

export const selectKitchenMonitorConfigs = store => store.kitchenMonitor.kitchenMonitorConfigs || []

export const selectEnabledKitchenMonitorConfigs = createSelector(
	selectKitchenMonitorConfigs,
	(kitchenMonitorConfigs) => {
		return kitchenMonitorConfigs.filter(config => config.enabled)
	},
)

export const selectSelectedKitchenMonitorConfig = (kitchenMonitorConfigId) => createSelector(
	selectEnabledKitchenMonitorConfigs,
	(kitchenMonitorConfigs) => {
		return kitchenMonitorConfigs.find(config => config.id === kitchenMonitorConfigId)
	},
)

export const selectKitchenMonitorConfigCardCount = (kitchenMonitorConfigId) => createSelector(
	selectEnabledKitchenMonitorConfigs,
	(kitchenMonitorConfigs) => {
		return kitchenMonitorConfigs?.find(config => config.id === kitchenMonitorConfigId)?.displayCardCount || 3
	},
)

export const selectKitchenDisplayCardClassname = (kitchenMonitorConfigId) => createSelector(
	selectKitchenMonitorConfigCardCount(kitchenMonitorConfigId),
	(cardCount) => {
		const cardClassesByCount = { 2: 'cards-2', 3: 'cards-3', 4: 'cards-4', 5: 'cards-5' }
		return cardClassesByCount?.[cardCount]
	},
)

export const selectCategoriesForKitchenMonitor = createSelector(
	selectCategories,
	(categories) => {
		return categories.map(item => ({ id: item.id, name: item.title, enabled: true }))
	},
)

export const selectKitchenMonitorConfigIsLoading = store => store.kitchenMonitor.kitchenMonitorConfigIsLoading
export const selectKitchenOrdersIsLoading = store => store.kitchenMonitor.kitchenOrdersIsLoading
export const selectShowKitchenMonitorSkeleton = store => store.kitchenMonitor.showKitchenMonitorSkeleton

export const selectKitchenMonitorIsLoading = createSelector(
	selectKitchenMonitorConfigIsLoading,
	selectKitchenOrdersIsLoading,
	selectShowKitchenMonitorSkeleton,
	(kitchenMonitorConfigIsLoading, kitchenOrdersIsLoading, showKitchenMonitorSkeleton) => {
		return kitchenMonitorConfigIsLoading || kitchenOrdersIsLoading || showKitchenMonitorSkeleton
	},
)
