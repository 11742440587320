import { APPEND_INVOICES, SET_INVOICE_LINK_IS_LOADING, SET_INVOICES, SET_INVOICES_IS_LOADING, SET_INVOICES_IS_NOT_FOUND, SET_INVOICES_TOTAL_LENGTH } from '../../actionTypes/zohoInvoice'

const initialState = {
	invoices: [],
	invoicesTotalLength: 0,
	invoicesIsLoading: false,
	invoiceLinkIsLoading: false,
	invoicesIsNotFound: false
}

export function zohoInvoiceReducer (state = initialState, action) {
	switch (action.type) {
		case SET_INVOICES:
			return {
				...state,
				invoices: action.value,
			}
		case APPEND_INVOICES:
			return {
				...state,
				invoices: state.invoices.concat(...action.value),
			}
		case SET_INVOICES_TOTAL_LENGTH:
			return {
				...state,
				invoicesTotalLength: action.value,
			}
		case SET_INVOICES_IS_LOADING:
			return {
				...state,
				invoicesIsLoading: action.value,
			}
		case SET_INVOICE_LINK_IS_LOADING:
			return {
				...state,
				invoiceLinkIsLoading: action.value,
			}
		case SET_INVOICES_IS_NOT_FOUND:
			return {
				...state,
				invoicesIsNotFound: action.value,
			}
		default:
			return state
	}
}
