import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const SumMain = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	gap: 0.25em 0.32em;

	width: 100%;
	height: 100%;
	max-height: 19.32em;
	max-width: 25.37em;
	-webkit-filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 18px 109px);

	@media screen and (max-width: ${theme.point820}) {
		height: 15.2em;
		max-width: 27.67em;
	}

	@media screen and (max-width: ${theme.point520}) {
		margin-top: 0.63em;
		height: 10em;
		max-width: none;
	}
`
SumMain.displayName = 'SumMain'

export const SumButton = styled.button`
	background-color: ${colorTheme.cartModal.changeCalculator.sum.background};
	border-radius: 0.33em;
	border: 1px solid transparent;
	position: relative;

	color: ${colorTheme.cartModal.changeCalculator.sum.text};
	font-size: 1.88em;
	font-family: ${theme.fontRobotoMedium};
	user-select: none;
	cursor: pointer;
	transition: all 0.2s ease 0s;

	&:hover {
		border: 1px solid ${colorTheme.cartModal.changeCalculator.sum.hoverBorder};
		box-shadow: 0 0 0.93em 0.18em rgba(10, 28, 63, 0.34);
		z-index: 1;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.25em;
		border-radius: 0.6em;
	}
`
SumButton.displayName = 'SumButton'
