import React from 'react'
import { SwitchWrapper } from '../index.styled'

const SwitchInputControlled = React.memo(({ name, checked, onChange, label, greenOnChecked = true, disabled, testId }) => {
	return (
		<SwitchWrapper greenOnChecked={greenOnChecked}>
			<input type="checkbox"
						 checked={!!checked}
						 onChange={onChange}
						 name={name}
						 tabIndex={-1}
						 disabled={disabled}
						 data-testid={testId} />
			<span><span className="switch-label">{label}</span></span>
		</SwitchWrapper>
	)
})

SwitchInputControlled.displayName = 'SwitchInputControlled'
export default SwitchInputControlled
