import React from 'react'
import MainIcon from '../../../../img/svg/main'
import { ErrorMessageText, ErrorMessageWrapper } from './index.styled'

const ErrorMessage = React.memo(({ id, center, refProp, children, className }) => {
	const layout = (center ? 'center ' : '') + (className ? className : '')

	return (
		<ErrorMessageWrapper id={id} ref={refProp} className={layout}>
			<MainIcon icon="error" />
			<ErrorMessageText>{children}</ErrorMessageText>
		</ErrorMessageWrapper>
	)
})

ErrorMessage.displayName = 'ErrorMessage'
export default ErrorMessage
