import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { RecaptchaWrapper } from '../../Recaptcha/index.styled'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ModalWrapper = styled.div`
	font-size: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 9;

	&.modal-enter-done {
		opacity: 1;
		z-index: 999;
		background-color: #000000bf;
	}

	&.modal-enter {
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.33);
	}

	&.modal-enter-active {
		opacity: 1;
		z-index: 999;
		background-color: #000000bf;
	}

	&.modal-exit {
		opacity: 1;
		z-index: 999;
		background-color: #000000bf;
	}

	&.modal-exit-active {
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.33);
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1rem;
		opacity: 1;
		background-color: transparent;
		backdrop-filter: none;

		&.modal-enter {
			transform: translateY(100%);
		}

		&.modal-enter-active {
			opacity: 1;
			transform: translateY(0);
		}

		&.modal-exit {
			transform: translateY(0);
		}

		&.modal-exit-active {
			opacity: 1;
			transform: translateY(100%);
		}
	}
`
ModalWrapper.displayName = 'ModalWrapper'

export const ModalMain = styled.main`
	position: absolute;
	height: auto;
	width: 31%;
	min-width: 510px;
	box-sizing: border-box;
	background-color: ${colorTheme.modal.background};
	border: 2px solid ${colorTheme.modal.border};
	font-size: 1em;
	padding: 2.8em 3.1em;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	text-align: center;
	border-radius: 3.75em;

	&.with-recaptcha {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		${RecaptchaWrapper} {
			margin: 2.5em auto 0;
		}
	}

	&.small {
		min-width: auto;
	}

	&.x-small {
		width: 34.5%;
	}

	&.s-medium {
		width: 40%;
	}

	&.medium {
		width: 60%;
	}

	&.big {
		max-width: 1000px;
		width: 90%;
	}

	&.large {
		width: 90%;
		max-height: 90%;
		overflow: auto;
	}

	&.larger {
		width: 95%;
		height: 96%;
		overflow: auto;
	}

	&.x-larger {
		width: 96.9%;
		height: 94.5vh;
	}

	&.auto {
		width: auto;
		min-width: auto;
	}

	@media screen and (max-width: ${theme.point820}) {
		&, &.small, &.x-small, &.medium, &.big, &.large, &.auto, &.larger, &.x-larger {
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			transform: none;
			padding: 0;
			border-radius: 0;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);
			min-width: auto;
			height: 100%;
		}

		> .btn-close-modal {
			display: none;
		}

		&.x-larger {
			display: grid;
			grid-template-rows: min-content auto min-content min-content;
		}

		&.with-recaptcha {
			${RecaptchaWrapper} {
				margin: 0 auto 0.63em;
				padding: 0 1.25em;
			}
		}
	}
`
ModalMain.displayName = 'ModalMain'

export const ModalMainBlock = styled.div`
	height: 100%;
`
ModalMainBlock.displayName = 'ModalMainBlock'

export const ModalHeader = styled.h2`
	color: ${colorTheme.modal.header.defaultText};
	min-height: ${props => props.heading ? '0' : '2.4em'};
	text-align: ${props => props.heading ? 'left' : 'center'};
	display: flex;
	flex-wrap: wrap;
	justify-content: ${props => props.heading ? 'flex-start' : 'center'};
	align-items: center;
	gap: 0.45em;
	padding: 0 6em;

	&.titleUpperCase {
		text-transform: uppercase;
	}

	&.blue {
		color: ${colorTheme.modal.header.blueText};
	}

	&.red {
		color: ${colorTheme.modal.header.redText};
	}

	> .price {
		margin-left: 0.3em;
	}

	@media screen and (max-width: ${theme.point820}) {
		position: sticky;
		z-index: 101;
		top: 0;
		padding: .5em 1em .5em 0.63em;
		min-height: 3.75em;
		margin: 0;
		background-color: ${colorTheme.modal.header.background};
		box-shadow: 0 4px 4px 0 #00000026;
		border-bottom: 1px solid ${colorTheme.modal.header.border};
		justify-content: space-between;
		flex-wrap: nowrap;
		text-align: start;
	}
`
ModalHeader.displayName = 'ModalHeader'

export const ModalHeaderTitle = styled.div`
	font-size: 1em;
	display: flex;
	align-items: center;
	gap: .63em;

	:not(.big, .large, .larger, .x-larger) {
		flex-direction: column;
	}

	&.titleInOneRow {
		flex-direction: row;
	}

	@media screen and (max-width: ${theme.point820}) {
		:not(.big, .large, .larger, .x-larger) {
			flex-direction: row;
		}
	}
`
ModalHeaderTitle.displayName = 'ModalHeaderTitle'

export const ModalHeaderTitleBlock = styled.div`
	font-size: 1em;
	display: flex;
	align-items: center;
	gap: .94em;

	> svg.header-icon {
		width: 3.31em;
		height: 3.31em;
		margin-top: -0.75em;
		margin-bottom: -0.25em;
	}

	@media screen and (max-width: ${theme.point820}) {
		> svg.header-icon {
			display: none;
		}
	}
`
ModalHeaderTitleBlock.displayName = 'ModalHeaderTitleBlock'

export const ModalHeaderTitleText = styled.div`
	font-family: ${theme.fontRobotoBold};
	font-size: 1.5em;
`
ModalHeaderTitleText.displayName = 'ModalHeaderTitleText'

export const ModalBody = styled.div`
	margin-top: ${props => props.heading ? '0' : '2.7em'};

	&.larger {
		height: 88%;
	}

	&.height-scroll {
		max-height: calc(100vh - 20em);
		overflow-y: auto;
	}

	@media screen and (max-width: ${theme.point820}) {
		padding: 0 0.63em 0.01%;
		margin-top: 1.25em;
		max-height: none;
		overflow-y: visible;
		height: auto;
		position: relative;
		margin-bottom: 6.25em;

		&.height-scroll {
			max-height: none;
			overflow-y: visible;
		}
	}
`

export const ModalFooter = styled.div`
	display: flex;
	padding-top: 1.25em;
	gap: 0.63em;
	justify-content: space-between;

	> :not(.back) {
		margin-left: auto;
	}

	> .btn-center {
		margin: 0 auto;
	}

	&.dividedFooterLine {
		border-top: 1px solid ${colorTheme.modal.footer.line};
	}

	&.big-gap {
		gap: 1.88em;
	}

	&.big-top {
		padding-top: 1.88em;
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
		padding: 0.63em;
		position: fixed;
		bottom: 0;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
		background-color: ${colorTheme.modal.header.background};

		&.footerColor {
			background-color: ${props => props.colorFooter};
		}

		&.dividedFooterLine {
			border-color: transparent;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		> * {
			width: 100%;
		}

		&.mobileFooter {
			flex-direction: column;
		}

		&.big-gap {
			gap: 1.25em;
		}
	}
`

export const ModalCloseBlock = styled.div`
	position: absolute;
	right: 3.1em;
	top: 2.8em;
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		width: 2.1em;
		height: 2.1em;
		fill: ${colorTheme.modal.closeKey.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		display: none;

		&.mobile {
			display: flex;
			position: static;

			svg {
				width: 1.19em;
				height: 1.19em;
			}
		}
	}
`
ModalCloseBlock.displayName = 'ModalCloseBlock'

export const ModalCloseKey = styled.div`
	width: 2.9em;
	height: 2.9em;
	box-sizing: border-box;
	border-radius: 0.5em;
	border: 1px solid ${colorTheme.modal.closeKey.border};
	background-color: ${colorTheme.modal.closeKey.background};
	color: ${colorTheme.modal.closeKey.text};
	font-family: ${theme.fontRobotoBold};
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8em;
	line-height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1.7em;
`
