import { SET_STAMPS_CONFIG } from '../../actionTypes/stamps'

const initialState = {
	config: null,
}

export function stampsReducer (state = initialState, action) {
	switch (action.type) {
		case SET_STAMPS_CONFIG:
			return {
				...state,
				config: action.value,
			}
		default:
			return state
	}
}
