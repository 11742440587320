import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const NotificationModalBtnWrapper = styled.button`
	font-size: 1rem;
	width: max-content;
	min-height: 3.63em;
	background-color: ${colorTheme.Tap2PayNotificationModal.button.background};
	padding: .3em 1em;
	border-radius: .75em;
	text-transform: uppercase;
	border: 2px solid transparent;
	transition: all .2s ease-in-out;
	cursor: pointer;

	@media screen and (max-width: ${theme.point820}) {
		min-height: 4.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 3.63em;
		width: 100%;
	}
`
NotificationModalBtnWrapper.displayName = 'NotificationModalBtnWrapper'

export const NotificationModalBtnText = styled.div`
	font-size: 1.5em;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.Tap2PayNotificationModal.button.text};
	font-weight: 500;
	transition: color .2s ease-in-out;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.5em;
	}
`
NotificationModalBtnText.displayName = 'NotificationModalBtnText'