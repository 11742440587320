import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectActivationData } from '../../../redux/selector/company'

const keys = {
	enter: { bindingKey: 'Enter', regex: /Enter/i },
	escape: { bindingKey: 'Escape', regex: /Escape/i },
	arrowUp: { bindingKey: 'ArrowUp', regex: /ArrowUp/i },
	arrowDown: { bindingKey: 'ArrowDown', regex: /ArrowDown/i },
	arrowRight: { bindingKey: 'ArrowRight', regex: /ArrowRight/i },
	arrowLeft: { bindingKey: 'ArrowLeft', regex: /ArrowLeft/i },
	tab: { bindingKey: 'Tab', regex: /Tab/i },
	delete: { bindingKey: 'Delete', regex: /Delete/i },
	backspace: { bindingKey: 'Backspace', regex: /Backspace/i },
	plus: { bindingKey: '+', regex: /Plus/i },
	minus: { bindingKey: '-', regex: /Minus/i },
	f1: { bindingKey: 'F1', regex: /^F1$/i },
	f2: { bindingKey: 'F2', regex: /^F2$/i },
	f3: { bindingKey: 'F3', regex: /^F3$/i },
	f4: { bindingKey: 'F4', regex: /^F4$/i },
	f5: { bindingKey: 'F5', regex: /^F5$/i },
	f6: { bindingKey: 'F6', regex: /^F6$/i },
	f7: { bindingKey: 'F7', regex: /^F7$/i },
	f8: { bindingKey: 'F8', regex: /^F8$/i },
	f9: { bindingKey: 'F9', regex: /^F9$/i },
	f10: { bindingKey: 'F10', regex: /^F10$/i },
	f11: { bindingKey: 'F11', regex: /^F11$/i },
	f12: { bindingKey: 'F12', regex: /^F12$/i },
	space: { bindingKey: ' ', regex: /Space/i },
	shift_f: { bindingKey: 'F', regex: /shift\+f/i, shift: true },
	zero: { bindingKey: '0', regex: '0' },
	one: { bindingKey: '1', regex: '1' },
	two: { bindingKey: '2', regex: '2' },
	three: { bindingKey: '3', regex: '3' },
	four: { bindingKey: '4', regex: '4' },
	five: { bindingKey: '5', regex: '5' },
	six: { bindingKey: '6', regex: '6' },
	seven: { bindingKey: '7', regex: '7' },
	eight: { bindingKey: '8', regex: '8' },
	nine: { bindingKey: '9', regex: '9' },
	comma: { bindingKey: '.', regex: '.' },
}

const getKeyBinding = (key, callback) => {
	if (callback) {
		return { [key.bindingKey]: { callback, shift: key.shift, preventDefault: true } }
	} else {
		return { [key.bindingKey]: null }
	}
}

const KeyBindings = React.memo(({
	enter, escape, arrowUp, arrowDown, arrowRight, arrowLeft, tab, delet, plus, minus, space,
	f1, f2, f3, f4, f5, f6, f7, f8, f9, f10, f11, f12,
	zero, one, two, three, four, five, six, seven, eight, nine, comma, backspace,
	customKey, customKeyPress,
}) => {
	const { isDeactivated } = useSelector(selectActivationData)

	useEffect(() => {
		let keyBindingMap = {
			...getKeyBinding(keys.enter, enter),
			...getKeyBinding(keys.escape, escape),
			...getKeyBinding(keys.arrowUp, arrowUp),
			...getKeyBinding(keys.arrowDown, arrowDown),
			...getKeyBinding(keys.arrowRight, arrowRight),
			...getKeyBinding(keys.arrowLeft, arrowLeft),
			...getKeyBinding(keys.tab, tab),
			...getKeyBinding(keys.delete, delet),
			...getKeyBinding(keys.plus, plus),
			...getKeyBinding(keys.minus, minus),
			...getKeyBinding(keys.f1, f1),
			...getKeyBinding(keys.f2, f2),
			...getKeyBinding(keys.f3, f3),
			...getKeyBinding(keys.f4, f4),
			...getKeyBinding(keys.f5, f5),
			...getKeyBinding(keys.f6, f6),
			...getKeyBinding(keys.f7, f7),
			...getKeyBinding(keys.f8, f8),
			...getKeyBinding(keys.f9, f9),
			...getKeyBinding(keys.f10, f10),
			...getKeyBinding(keys.f11, f11),
			...getKeyBinding(keys.f12, f12),
			...getKeyBinding(keys.space, space),
			...getKeyBinding(keys.zero, zero),
			...getKeyBinding(keys.one, one),
			...getKeyBinding(keys.two, two),
			...getKeyBinding(keys.three, three),
			...getKeyBinding(keys.four, four),
			...getKeyBinding(keys.five, five),
			...getKeyBinding(keys.six, six),
			...getKeyBinding(keys.seven, seven),
			...getKeyBinding(keys.eight, eight),
			...getKeyBinding(keys.nine, nine),
			...getKeyBinding(keys.comma, comma),
			...getKeyBinding(keys.backspace, backspace),
		}
		if (customKey) {
			const existCustomKey = Object.entries(keys).find(([, value]) => customKey.match(value.regex))?.[1]
			if (!existCustomKey) {
				// eslint-disable-next-line
				console.error(`Unknown key binging ${customKey}`)
			} else {
				keyBindingMap = { ...keyBindingMap, ...getKeyBinding(existCustomKey, customKeyPress) }
			}
		}
		const handleKeyDown = (event) => {
			if (isDeactivated) {
				return
			}
			const keyBindingMapElement = keyBindingMap[event.key]
			if (keyBindingMapElement && (keyBindingMapElement.shift === undefined || event.shiftKey === keyBindingMapElement.shift)) {
				if (keyBindingMapElement.preventDefault) {
					event.preventDefault()
				}
				keyBindingMapElement.callback(event)
			}
		}
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [arrowDown, arrowLeft, arrowRight, arrowUp, comma, customKey, customKeyPress, delet, backspace, eight,
		enter, escape, f1, f10, f11, f12, f2, f3, f4, f5, f6, f7, f8, f9, five, four, minus, nine,
		one, plus, seven, six, space, tab, three, two, zero, isDeactivated])

	return null
})
KeyBindings.displayName = 'KeyBindings'

export default KeyBindings
