import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const TseInformationModalWrapper = styled.div`
	font-size: 1rem;

	@media print {
		max-height: 41em;
		column-gap: 1.88em;
	}
`

TseInformationModalWrapper.displayName = 'TseInformationModalWrapper'

export const TseInformationButtonsWrap = styled.div`
	display: flex;
	gap: 1.25em;

	:not(.back) {
		margin-left: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
	}
`

TseInformationButtonsWrap.displayName = 'TseInformationButtonsWrap'

export const InfoItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25em;
	justify-content: start;
	align-items: flex-start;
	font-size: 1.25em;
	text-align: left;
`

InfoItem.displayName = 'InfoItem'

export const InfoWrapper = styled.div`
	font-size: 1em;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 1.88em;

	&.deu-type {
		${InfoItem} {
			&:nth-child(3) {
				grid-column: 1 / 3;
			}

			&:nth-child(4) {
				grid-column: 1 / 3;
			}
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.6em 0;
		grid-template-columns: 1fr;

		&.deu-type {
			${InfoItem} {
				grid-column: 1;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.5em 0;
	}
`

InfoWrapper.displayName = 'InfoWrapper'

export const InfoItemTitle = styled.p`
	font-family: ${theme.fontRobotoBold};
	font-weight: 700;
	color: ${colorTheme.tseInformationModal.title};
`

InfoItemTitle.displayName = 'InfoItemTitle'

export const InfoItemData = styled.p`
	font-family: ${theme.fontRobotoRegular};
	color: ${colorTheme.tseInformationModal.text};
	word-break: break-all;

	&.certificate {
		font-size: 0.6em;
	}
`

InfoItemData.displayName = 'InfoItemData'

export const NullOrderDataWrapper = styled.div`
	height: 29em;

	@media screen and (max-width: ${theme.point820}) {
		min-height: 100%;
	}
`

NullOrderDataWrapper.displayName = 'NullOrderDataWrapper'

export const NullOrdersWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1.88em 2.19em;
	padding-bottom: 1.88em;

	@media screen and (max-width: ${theme.point1280}) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: repeat(2, 1fr);
	}
`

NullOrdersWrapper.displayName = 'NullOrdersWrapper'

export const NullOrderBlock = styled.div`
	font-size: 1.25em;
	display: flex;
	align-items: center;
	gap: 0.5em;
	color: ${colorTheme.tseInformationModal.title};

	> svg {
		width: 1.3em;
		height: 1.15em;
		fill: ${colorTheme.tseInformationModal.icon};
	}
`

NullOrderBlock.displayName = 'NullOrderBlock'

export const EmptyNullOrder = styled.div`
	font-size: 1.5em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.63em;
	height: 100%;
	color: ${colorTheme.tseInformationModal.empty.title};

	> svg {
		width: 1.88em;
		height: 1.67em;
		fill: ${colorTheme.tseInformationModal.empty.icon};
	}
`

EmptyNullOrder.displayName = 'EmptyNullOrder'
