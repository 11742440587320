export const cancelItem = (items, itemIndex) => {
	return items.map((item, index) => {
			if (itemIndex === index) {
				const copySubItems = item.items?.length ? [...item.items].map(subItem => ({ ...subItem, status: 'CANCELED' })) : []
				return { ...item, items: copySubItems, status: 'CANCELED' }
			} else {
				return item
			}
		},
	)
}

export const cancelSubItem = (items, itemIndex, subItemIndex) => {
	return items.map((el, index) => {
		if (itemIndex === index) {
			const copySubItems = [...el.items].map((subItem, subIndex) => subItemIndex === subIndex ? { ...subItem, status: 'CANCELED' } : subItem)
			return { ...el, items: copySubItems }
		} else {
			return el
		}
	})
}

export const removeStatusPositionIdAndDecrementQuantity = (selectItem) => {
	const { status, itemPositionId, items, ...rest } = selectItem
	const updatedSubItems = items?.map(subItem => {
		const { itemPositionId, ...restSubItem } = subItem
		return restSubItem
	})
	return { ...rest, items: updatedSubItems, quantity: selectItem.quantity - 1 }
}

export const checkSavedCartItemsRemoveAbility = (items, selectItem ) => {
	if (selectItem.type === 'EXTRA_DISCOUNT') return true
	const itemsWithPositionId = items.filter(item =>  item?.status !== 'CANCELED' && item?.type !== 'EXTRA_DISCOUNT' && item?.type !== 'MARKETING_COUPON')
	return itemsWithPositionId.length > 1
}

export const getActualItemsUnpaidOrder = (items, couponPrice) => {
	return items
		.map(item => ({
			...item, items: item.items?.filter(subItem => subItem?.status !== 'CANCELED'),
		}))
		.filter(item => item?.status !== 'CANCELED')
		.map(el => el.type === 'MARKETING_COUPON' ? { ...el, price: -couponPrice } : el)
}
