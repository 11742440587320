import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrencySymbol } from '../../../../redux/selector/system'
import { SumButton, SumMain } from './index.styled'
import { defaultSums, tipsSums } from './constants'

const SumKeyboard = React.memo(({ handleType, isTipsSums }) => {
	const currencySymbol = useSelector(selectCurrencySymbol)
	const sums = isTipsSums ? tipsSums : defaultSums

	const handleClick = useCallback((value) => {
		handleType(value)
	}, [handleType])

	return (
		<SumMain>
			{sums.map(sum => (
				<SumButton key={sum} onClick={() => handleClick(sum)}>{sum + currencySymbol}</SumButton>
			))}
		</SumMain>
	)
})

SumKeyboard.displayName = 'SumKeyboard'
export default SumKeyboard
