import React from 'react'
import Currency from '../../../../../Elements/currency'
import { Table, TableIcon, TablePrice } from '../../index.styled'
import { getTablePrice, tableIsBusy } from '../../../../../../helper'
import { swapTableMoveThunk, swapTableThunk } from '../../../../../../redux/thunk/restaurant/table'
import { setRestaurantOrdersMove, setRestaurantSwapModal } from '../../../../../../redux/action/restaurant'
import { useDispatch, useSelector } from 'react-redux'
import RestaurantIcon from '../../../../../../img/svg/restaurant'

const SwapTable = ({ index, table }) => {
	const { table: selectedTable, move } = useSelector(state => state.restaurant)
	const dispatch = useDispatch()
	const isDisabled = selectedTable?.id === table.id

	const selectTable = (table) => {
		if (table?.payBeforeOrders?.length > 0 || isDisabled) {
			return
		}

		if (move && move.length) {
			dispatch(swapTableMoveThunk(selectedTable.id, { items: move, table: { id: table.id } }))
		} else if (move !== null) {
			dispatch(swapTableThunk(selectedTable.id, { tableId: table.id }))
		}
		dispatch(setRestaurantSwapModal(false))
		dispatch(setRestaurantOrdersMove([]))
	}

	const layout = 'swap ' + (tableIsBusy(table) ? 'busy' : '') + (selectedTable?.id === table.id ? ' disabled' : '')

	return (
		<Table key={index}
					 data-testid={`swap_table_${index}`}
					 onClick={() => selectTable(table)}
					 className={layout}>
			<TableIcon className={layout}>
				<div className="number">{table.number}</div>
				<RestaurantIcon icon="table-mobile" />
			</TableIcon>
			{tableIsBusy(table) &&
				<TablePrice className={(selectedTable?.id === table.id ? ' selected' : '')}>
					<Currency>{getTablePrice(table)}</Currency>
				</TablePrice>}
		</Table>
	)
}

SwapTable.displayName = 'SwapTable'
export default SwapTable
