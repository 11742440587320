import React from 'react'
import { getTablePrice, tableIsBusy } from '../../../../../helper'
import { RestWrapper } from '../index.styled'
import { useSelector } from 'react-redux'
import { selectTableWaiterCall } from '../../../../../redux/selector/restaurant'
import { tableComponents } from '../../../../RestaurantSchema/constants'
import RestTableComponent from '../../../../RestaurantSchema/tables/RestTableComponent'
import { checkIsTableWaiterNotified } from '../../../../../redux/selector/restaurant/helper'

const RestaurantTable = ({ index, table, bgdColor, testId, onClick, zIndex, processedBy, disabled }) => {
	const tableWaiterCall = useSelector(selectTableWaiterCall(table))
	const isWaiterNotifiedTable = checkIsTableWaiterNotified(table)
	const layoutForRestWrapper = (bgdColor ? 'border ': '') + (disabled ? 'disabled ' : '')

	const preparedDataTable = {
		number: table.number,
		width: table.meta.width.value,
		height: table.meta.height.value,
		chairCount: table.meta.chairCount,
		formType: table.meta.formType,
		isEm: true,
		orders: table.orders,
		splits: table.splits,
		payBeforeOrders: table.payBeforeOrders,
		id: table.id
	}
	const renderRestTable = () => {
		const totalPrice = getTablePrice(table)
		const color = tableIsBusy(table) ? 'red' : 'green'
		const animationClassname = (isWaiterNotifiedTable) ? 'scalePulse' : ''
		const TableComponent = tableComponents[preparedDataTable.formType]
		return TableComponent ? <TableComponent data={preparedDataTable} index={index} color={color}
																						onClick={onClick} testId={testId}
																						id={`table_${index + 1}`}
																						animationClassname={animationClassname}
																						disabled={disabled}>

			<RestTableComponent data={preparedDataTable} i={index}
													totalPrice={totalPrice}
													uuid={preparedDataTable.id}
													processedBy={processedBy}
													tableWaiterCall={tableWaiterCall}
													isReadyFood={isWaiterNotifiedTable}
													disabled={disabled}/>
		</TableComponent> : null
	}

	return (
		<RestWrapper id="restaurant-tables" key={index} {...table.meta} bgdColor={bgdColor} className={layoutForRestWrapper} zIndex={zIndex}>
			{renderRestTable()}
		</RestWrapper>
	)
}

RestaurantTable.displayName = 'RestaurantTable'
export default RestaurantTable
