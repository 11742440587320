import getBusinessScopeDiscount from '../../axios/order/discount/businessScope/get'
import { setCartDiscount, setCartDiscountType, setCartInitialDiscount } from '../../action/cartDiscount'

export const getBusinessScopeDiscountThunk =  (businessScope) => async dispatch => {
	try {
		const res = await getBusinessScopeDiscount(businessScope)
		const businessScopeDiscount = res.data
		dispatch(setCartInitialDiscount(businessScopeDiscount))
		dispatch(setCartDiscount(businessScopeDiscount.discount))
		dispatch(setCartDiscountType(businessScopeDiscount.discountType))
		return businessScopeDiscount
	} catch (error) {
		dispatch(setCartInitialDiscount({ discount: 0, discountType: 'PERCENTS' }))
		dispatch(setCartDiscount(0))
		dispatch(setCartDiscountType('PERCENTS'))
	}
}
