import { TextBig } from '../../OrdersTable/index.styled'
import MainIcon from '../../../../../img/svg/main'
import Currency from '../../../currency'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TableTdElementsTooltip from '../TableTdElementsTooltip'
import { OrderUnpaidContent, OrderUnpaidText, OrderUnpaidWrapper } from './index.styled'
import { TableTdGrid } from '../../style/table/td/index.styled'

const OrderPayment = React.memo(({ item, className }) => {
	const { t } = useTranslation()
	const layout = className ? className : ''

	return (
		item.payments.map((payment, i) =>
			!payment.payed ?
				<OrderUnpaidWrapper key={i}>
					<OrderUnpaidContent>
						<MainIcon className="unpaid" icon={payment.method} />
						<TextBig className={'unpaid ' + layout}><Currency>{payment.total}</Currency></TextBig>
					</OrderUnpaidContent>
					<OrderUnpaidText className="small unpaid">{t('Modal.unpaid_order')}</OrderUnpaidText>
				</OrderUnpaidWrapper> :
				<TableTdGrid key={i}>
					<TableTdElementsTooltip text={payment?.paymentName ? payment.paymentName : t(`Filter.payment.${payment.method}`)}>
						<MainIcon icon={payment?.paymentName ? 'other-payment' : payment.method} />
					</TableTdElementsTooltip>
					<TextBig className={layout}><Currency>{payment.total}</Currency></TextBig>
				</TableTdGrid>)
	)
})

OrderPayment.displayName = 'OrderPayment'
export default OrderPayment
