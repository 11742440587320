export const SET_PAYMENT_CONFIGS = 'SET_PAYMENT_CONFIGS'
export const SET_PAYMENT_IS_LOADING = 'SET_PAYMENT_IS_LOADING'
export const SET_PAYMENT_TEST_STATUS = 'SET_PAYMENT_TEST_STATUS'
export const SET_PAYMENT_STRIPE_ACCOUNT = 'SET_PAYMENT_STRIPE_ACCOUNT'
export const SET_PAYMENT_STRIPE_ACCOUNT_NOT_FOUND = 'SET_PAYMENT_STRIPE_ACCOUNT_NOT_FOUND'
export const SET_PAYMENT_STRIPE_IS_LOADING = 'SET_PAYMENT_STRIPE_IS_LOADING'
export const SET_PAYMENT_ACTIVE_TAB = 'SET_PAYMENT_ACTIVE_TAB'
export const SET_TERMINAL = 'SET_TERMINAL'
export const SET_TERMINALS = 'SET_TERMINALS'
export const APPEND_TERMINALS = 'APPEND_TERMINALS'
export const SET_TERMINALS_LENGTH = 'SET_TERMINALS_LENGTH'
export const SET_TERMINAL_ID = 'SET_TERMINAL_ID'
export const SET_TERMINAL_ERROR_IS_INVALID = 'SET_TERMINAL_ERROR_IS_INVALID'
export const SET_PAYMENT_PAYPAL_ACCOUNT = 'SET_PAYMENT_PAYPAL_ACCOUNT'
export const SET_PAYMENT_PAYPAL_ACCOUNT_NOT_FOUND = 'SET_PAYMENT_PAYPAL_ACCOUNT_NOT_FOUND'
export const SET_PAYMENT_PAYPAL_IS_LOADING = 'SET_PAYMENT_PAYPAL_IS_LOADING'
export const SET_PAYPAL_NOTIFICATION = 'SET_PAYPAL_NOTIFICATION'
export const SET_STRIPE_NOTIFICATION = 'SET_STRIPE_NOTIFICATION'
export const SET_PAYMENT_INVOICE_CONFIGS = 'SET_PAYMENT_INVOICE_CONFIGS'
export const SET_TAP2PAY_NOTIFICATION_MODAL_SHOW = 'SET_TAP2PAY_NOTIFICATION_MODAL_SHOW'
export const SET_ADDITIONAL_PAYMENT_CONFIGS = 'SET_ADDITIONAL_PAYMENT_CONFIGS'
