import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const TableList = styled.div`
	font-size: 1rem;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-rows: 1fr;
	gap: 0.63em;
	filter: none;
	transition: 0.2s;
	margin-bottom: 2.5em;

	.loader {
		grid-column: 3 / 5;
		height: 2em;
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: repeat(4, 1fr);
		gap: 0.31em;

		.loader {
			grid-column: 2 / 4;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: repeat(3, 1fr);
		gap: 0.31em;
		filter: none;
		margin-bottom: 0;

		.loader {
			grid-column: 2;
		}
	}
`
TableList.displayName = 'TableList'

export const Table = styled.div`
  min-height: 10.7em;
  background: ${colorTheme.restaurant.tableMobile.background};
  border-radius: 0.75em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  padding: 0;
	border: 2px solid ${colorTheme.restaurant.tableMobile.background};

  &.busy {
		border: 2px solid ${colorTheme.restaurant.tableMobile.busy.background};
    background: ${colorTheme.restaurant.tableMobile.busy.background};
  }

  &.selected {
    background: ${colorTheme.restaurant.tableMobile.selected.background};
  }

  &.swap {
    min-height: 9.4em;
  }

	&.disabled {
		cursor: not-allowed;
		background: ${colorTheme.restaurant.tableMobile.disabled.background};
		border: 2px dashed ${colorTheme.restaurant.tableMobile.disabled.border};
	}

	@keyframes changeColor {
		0%, 100% {
			border: 2px solid ${colorTheme.restaurant.tableMobile.busy.background};
			background: ${colorTheme.restaurant.tableMobile.busy.background};
		}
		50% {
			background:${colorTheme.restaurant.tableMobile.animation.background} ;
			border: 2px solid ${colorTheme.restaurant.tableMobile.animation.border};
		}
	}

	&.changeColor {
		animation: changeColor 1.2s ease-in-out infinite alternate;
	}

  @media screen and (max-width: ${theme.point820}) {
    &, &.swap {
      min-height: 7.4em;
    }
  }
`
Table.displayName = 'Table'

export const TableIcon = styled.div`
	width: 6.2em;
	display: flex;
	justify-content: center;
	align-items: center;

	> svg {
		width: 4.18em;
		height: 4.12em;
	}

	.number {
		position: absolute;
		top: auto;
		left: auto;
		color: ${colorTheme.restaurant.tableMobile.number};
		font-size: 1.88em;
		font-family: ${theme.fontRobotoRegular};
		text-align: center;
	}

	&.swap {
		width: 5.1em;
	}

	&.swap .number {
		color: ${colorTheme.restaurant.tableMobile.disabled.title};
	}

	&.busy {
		.number {
			color: ${colorTheme.restaurant.tableMobile.disabled.title};
		}
	}

	&.disabled {
		background: ${colorTheme.restaurant.tableMobile.disabled.background};

		.colorTable {
			fill: ${colorTheme.restaurant.tableMobile.disabled.disabled};
		}

		.number {
			color: ${colorTheme.restaurant.tableMobile.disabled.title};
		}

		.backgroundTable {
			fill: ${colorTheme.restaurant.tableMobile.disabled.backgroundTable};
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 4.19em;

		.number {
			font-size: 1.25em;
		}
	}
`
TableIcon.displayName = 'TableIcon'

export const TablePrice = styled.div`
  color: ${colorTheme.restaurant.tableMobile.price};
  font-size: 1.5em;
  font-family: ${theme.fontRobotoRegular};
  margin-top: 0.63em;

  &.selected {
    color: ${colorTheme.restaurant.tableMobile.selected.price};
  }

  @media screen and (max-width: ${theme.point820}) {
    font-size: 1.13em;
    margin-top: 0.4em;

		&.readyFood {
			font-size: 0.88em;
		}
  }
`
TablePrice.displayName = 'TablePrice'

export const RestWrapper = styled.div`
  padding: 20px;
  position: absolute;
  transform: translate(${props => props.coordX.value}em, ${props => props.coordY.value}em);
	background-color: ${props => props.bgdColor ? colorTheme.restaurant.background : ''};
	border-radius: 0.7em;
  z-index: ${props => props.zIndex || 0};

	display: flex;
	flex-direction: column;
	cursor: pointer;

	&.border {
		border: 2px dashed  ${colorTheme.restaurant.border};
	}

	&.disabled {
		cursor: not-allowed;
	}
`
RestWrapper.displayName = 'RestWrapper'

export const RestWall = styled.div`
  border: 1px ${colorTheme.restaurant.wall.border} solid;
  text-align: center;
  height: ${props => props.height.value}em;
  width: ${props => props.width.value}em;
  background-color: ${props => props.color};
`
RestWall.displayName = 'RestWall'


export const AreasListWrapper = styled.div`
	display: flex;
	padding: 1.25em 0;
	flex-direction: column;
	align-items: stretch;
	gap: .63em;
	flex-shrink: 0;
	background: ${colorTheme.restaurant.areas.backgroundWrapper};
`
AreasListWrapper.displayName = 'AreasListWrapper'
