import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MainIcon from '../../../../../../img/svg/main'
import { selectCurrencySymbol, selectHasAnyGroup } from '../../../../../../redux/selector/system'
import TextInputControlled from '../../../../../Elements/inputs/TextInput/controlled'
import { DiscountFormInputs, DiscountMessage, DiscountTitle, RestaurantDiscountWrapper } from './index.styled'
import { roundPrice } from '../../../../../../helper'
import SelectInputControlled from '../../../../../Elements/inputs/SelectInput/controlled'
import SelectControlled from './SelectControlled'
import { convert2PresetDiscountOptions, getOptions } from '../helper'
import RestaurantFlyerCoupon from './RestaurantFlyerCoupon'
import { setFlyerCouponIsInvalid, setFlyerCouponIsUsed, setValidatedFlyerCoupon } from '../../../../../../redux/action/coupon'

const regexOnlyPositiveNumbers = /^(\d*)([.,]\d+)?$/

const RestaurantDiscount = ({
	titlePrice,
	customerDiscount, setCustomerDiscount, customerDiscountType,
	handleDiscountChange, handleDiscountTypeChange, setCustomerDiscountHasError,
	presetDiscountId, setPresetDiscountId, extraDiscountOrderItem, showPaymentTypeModal,
	disabled = false, updatePaymentMode, modalLayout,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const currencySymbol = useSelector(selectCurrencySymbol)
	const isAuthorizedAsWaiter = useSelector(state => !!state.modalLogin.accessToken)
	const userIsWaiter = useSelector(selectHasAnyGroup(['WAITER']))
	const presetDiscounts = useSelector(store => store.restaurantSettings.presetDiscounts)
	const [selectedDiscountOption, setSelectedDiscountOption] = useState(null)
	const selectedFlyerCoupon = selectedDiscountOption?.type === 'FLYER_COUPON'

	const defaultDiscountOption = { id: null, label: t('restaurant.tables.modal.choose_discount'), type: null }
	const customDiscountOption = { id: null, label: t('restaurant.tables.modal.custom_discount'), type: 'CUSTOM' }
	const flyerCoupon = { id: null, label: t('restaurant.tables.modal.enter_one-time_coupon'), type: 'FLYER_COUPON' }
	const presetDiscountsOptions = convert2PresetDiscountOptions(presetDiscounts)

	const discountOptions = getOptions(updatePaymentMode, presetDiscountsOptions, customDiscountOption, defaultDiscountOption, flyerCoupon)
	const errorTranslationText = 'restaurant.tables.modal.discount_error'
	const discountFromWaiterSettings = useSelector(state => state.restaurantSettings.waiterSettings)?.discount || 0

	useEffect(() => {
		const hasErrorMessage = handleValidateFields()
		if (hasErrorMessage) {
			setCustomerDiscountHasError(true)
		} else {
			setCustomerDiscountHasError(false)
		}
	}, [customerDiscount, customerDiscountType]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal && presetDiscounts.length) {
			setSelectedDiscountOption(defaultDiscountOption)
		} else {
			setSelectedDiscountOption(defaultDiscountOption)
		}
	}, [presetDiscounts.length, showPaymentTypeModal]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal && extraDiscountOrderItem) {
			if (presetDiscountId) {
				const selectedPresetDiscountOption = discountOptions.find(discount => discount.id === presetDiscountId)
				setSelectedDiscountOption(selectedPresetDiscountOption)
			} else {
				setSelectedDiscountOption(customDiscountOption)
			}
		}
	}, [showPaymentTypeModal, extraDiscountOrderItem, presetDiscountId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedFlyerCoupon) {
			dispatch(setFlyerCouponIsInvalid(false))
			dispatch(setFlyerCouponIsUsed(false))
			dispatch(setValidatedFlyerCoupon(null))		}
	}, [selectedFlyerCoupon]) //eslint-disable-line react-hooks/exhaustive-deps

	const handleValidateFields = () => {
		if (!regexOnlyPositiveNumbers.test(customerDiscount)) return t('restaurant.tables.modal.positive_number')
		if (isAuthorizedAsWaiter || userIsWaiter) {
			if (customerDiscountType === 'PERCENTS') {
				if (customerDiscount > discountFromWaiterSettings) return t(errorTranslationText, { discount: `${discountFromWaiterSettings}%` })
			} else {
				const currencyFromPercent = roundPrice(titlePrice * discountFromWaiterSettings / 100)
				if (customerDiscount > currencyFromPercent) return t(errorTranslationText, { discount: `${currencyFromPercent}${currencySymbol}` })
			}
		} else {
			if (customerDiscountType === 'PERCENTS') {
				if (customerDiscount > 100) return t(errorTranslationText, { discount: '100%' })
			} else {
				if (customerDiscount > titlePrice) return t('restaurant.tables.modal.discount_more_than_sum')
			}
		}
	}

	const onBlurDiscount = () => {
		if (customerDiscount === '') setCustomerDiscount(0)
	}

	const chooseOption = useCallback((option) => {
		setPresetDiscountId(option.id)
		setSelectedDiscountOption(option)
		setCustomerDiscountHasError(false)
	}, [setCustomerDiscountHasError, setPresetDiscountId])

	return (
		<RestaurantDiscountWrapper className={modalLayout}>
			<DiscountTitle><MainIcon icon="discount" />{t('restaurant.tables.modal.add_discount')}</DiscountTitle>

			<SelectControlled testId="discount-options"
												onClick={chooseOption}
												selectedOption={selectedDiscountOption?.label}
												options={discountOptions} />

			{selectedDiscountOption?.type === 'CUSTOM' &&
				<DiscountFormInputs className={modalLayout}>
					<TextInputControlled name="discount"
															 label={t('restaurant.tables.modal.discount_sum')}
															 testId="discount"
															 value={customerDiscount}
															 onChange={handleDiscountChange}
															 errorMessage={handleValidateFields()}
															 hasLongTextError={true}
															 onBlur={onBlurDiscount}
															 color="gray"
															 disabled={disabled} />
					<SelectInputControlled name="discountType"
																 testId="discountType"
																 value={customerDiscountType}
																 color="purple"
																 onChange={e => handleDiscountTypeChange(e)}
																 disabled={disabled}>
						<option key="0" value="PERCENTS">%</option>
						<option key="1" value="CURRENCY">{currencySymbol}</option>
					</SelectInputControlled>
				</DiscountFormInputs>}
			{selectedFlyerCoupon && <RestaurantFlyerCoupon titlePrice={titlePrice}
																										 selectedDiscountOption={selectedDiscountOption} />}

			{!selectedFlyerCoupon && <DiscountMessage className={modalLayout}><MainIcon icon="info" />{t('restaurant.tables.modal.discount_conditions')}</DiscountMessage>}
		</RestaurantDiscountWrapper>
	)
}

RestaurantDiscount.displayName = 'RestaurantDiscount'
export default RestaurantDiscount
