import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const NotificationModalWrapper = styled.div`
	font-size: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	transition: none;

	&.active {
		transition: all .2s ease-in-out;
		visibility: visible;
		opacity: 1;
		z-index: ${props => props.zIndex || 100};
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1rem;
		opacity: 1;
		background-color: transparent;
		backdrop-filter: none;
		transition: all 250ms;
	}
`
NotificationModalWrapper.displayName = 'NotificationModalWrapper'

export const NotificationModalMain = styled.main`
	border-radius: 3.75em;
	width: 56.88em;
	height: 40.63em;
	border: 2px solid ${colorTheme.Tap2PayNotificationModal.border};
	background-image: url('/img/tap2PayNotification/Tap2PayAdvBack.webp');

	/*fixed xy axis*/
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	padding: 5.62em 3.12em 1.875em;
	margin-top: 0;

	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
		height: 100%;
		border-radius: 0;
		border: none;
		z-index: 1;
		padding: 3.75em 0 0;
		left: 0;
		top: auto;
		bottom: 0;
		transform: translate(0, 0);
		box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);
		margin: 0;
		background-image: none;
		background: ${colorTheme.Tap2PayNotificationModal.background};
	}
`
NotificationModalMain.displayName = 'NotificationModalMain'

export const NotificationModalBody = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 2.18em;
	margin-top: 1.87em;

	@media screen and (max-width: ${theme.point820}) {
		margin-top: 0;
		flex-direction: column;
		align-items: center;
		gap: 1.43em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.25em;
	}
`

export const NotificationModalCloseBlock = styled.div`
	position: absolute;
	right: 3.1em;
	top: 2.8em;
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		width: 2.1em;
		height: 2.1em;
		fill: ${colorTheme.Tap2PayNotificationModal.closeKey.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		display: none;

		&.mobile {
			display: flex;
			position: static;

			svg {
				width: 1.19em;
				height: 1.19em;
			}
		}
	}
`
NotificationModalCloseBlock.displayName = 'NotificationModalCloseBlock'

export const NotificationModalCloseKey = styled.div`
	width: 2.9em;
	height: 2.9em;
	box-sizing: border-box;
	border-radius: 0.5em;
	border: 1px solid ${colorTheme.Tap2PayNotificationModal.closeKey.border};
	color: ${colorTheme.Tap2PayNotificationModal.closeKey.text};
	font-family: ${theme.fontRobotoBold};
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8em;
	line-height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1.7em;
`

export const NotificationModalHeaderLeft = styled.div`
	z-index: 1;
	left: -2px;
	top: -2px;
	transform: none;
	width: auto;
	height: 4.31em;
	padding: 0.1em 3.13em;
	text-align: center;
	clip-path: inset(0 0 0 round 3.75em 0 3.75em 0);
	background-color: ${colorTheme.Tap2PayNotificationModal.header.background};
	position: absolute;
	display: flex;
	align-items: center;

	@media screen and (max-width: ${theme.point820}) {
		clip-path: none;
		width: 100%;
		height: 4.28em;
		top: 0;
		left: 0;
		margin: 0;
		border-radius: 0;
		padding: 0 1.14em 0 0.72em;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 3.75em;
		padding: 0 1em 0 0.63em;
	}
`
NotificationModalHeaderLeft.displayName = 'NotificationModalHeaderLeft'

export const NotificationModalHeaderLeftText = styled.div`
	color: ${colorTheme.Tap2PayNotificationModal.header.defaultText};
	font-size: 2em;
	font-weight: 700;
	text-transform: uppercase;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.5em;
	}
`
NotificationModalHeaderLeftText.displayName = 'NotificationModalHeaderLeftText'

export const NotificationModalContent = styled.div`
	border-radius: 1.5em;
	border: none;

	@media screen and (max-width: ${theme.point820}) {
		border-radius: 0;
		width: 100%;
		height: 100%;
		background: url('/img/tap2PayNotification/Tap2PayAdvBack.webp') lightgray 50% / cover no-repeat;
		position: relative;
		padding: 1.87em 1.25em 2.5em;
		overflow-y: auto;
	}
`
NotificationModalContent.displayName = 'NotificationModalContent'

export const NotificationModalInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.63em;
	justify-content: center;
	margin-bottom: 1.25em;

	@media screen and (max-width: ${theme.point820}) {
		gap: 1.43em;
		margin-bottom: 0;
		align-items: center;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.25em;
	}
`
NotificationModalInfo.displayName = 'NotificationModalInfo'

export const NotificationModalTitle = styled.div`
	color: ${colorTheme.Tap2PayNotificationModal.title};
	font-size: 2.25em;
	font-weight: 500;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 2.57em;
		text-align: center;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 2.25em;
	}
`
NotificationModalTitle.displayName = 'NotificationModalTitle'

export const NotificationModalText = styled.div`
	color: ${colorTheme.Tap2PayNotificationModal.text};
	font-size: 1.87em;
	font-weight: 500;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 2em;
		text-align: center;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.75em;
	}
`
NotificationModalText.displayName = 'NotificationModalText'

export const NotificationModalBtnWrap = styled.div`
	margin-top: 1.88em;

	@media screen and (max-width: ${theme.point820}) {
		margin-top: 2.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 1.88em;
		width: 100%;
	}
`
NotificationModalBtnWrap.displayName = 'NotificationModalBtnWrap'

export const NotificationModalPhoneImg = styled.div`
	height: 30em;
	position: relative;

	> img {
		height: 100%;
	}

	@media screen and (max-width: ${theme.point820}) {
		height: 34.2em;
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 30em;
	}
`
NotificationModalPhoneImg.displayName = 'NotificationModalPhoneImg'

