import { getNeededDateFormat } from '../../../../../../helper'

export const isShowIngredients = (item) => {
	return !!(['MAIN_DISH', 'BEVERAGE'].includes(item.type) && item?.ingredients)
}

export const getOrderGroupCategories = (items) => {
	let category = null
	if (!items) return
	return items
		.reduce((res, el) => {
			if (!el?.category) {
				const products = items.filter((e) => !e?.category)
				return !res.find((e) => e.category === '') ? [...res, { category: '', products }] : res
			} else if (category !== el?.category) {
				const products = items.filter((e) => e.category === el.category)
				category = el.category
				return !res.find((e) => e.category === el.category) ? [...res, { category, products }] : res
			}
			return res
		}, [])
		.sort((a, b) => {
			if (a.category.length === 0) return 1
			if (b.category.length === 0) return -1
			return 0
		})
}

export const getOrderTime = (orderDate) => {
	const date = getNeededDateFormat(orderDate, 'dd.MM')
	const time = getNeededDateFormat(orderDate, 'H:mm')
	return { date, time }
}

export const countOrderItems = (order) => {
	if (!order || !order.items) return 0
	const filteredItems = order.items.filter(item => item.type !== 'EXTRA_DISCOUNT')
	const countItems = (items) => {
		return items.reduce((total, item) => {
			if (item.type === 'MENU' && item.items && item.items.length > 0) {
				return total + item.quantity * countItems(item.items)
			}
			return total + item.quantity
		}, 0)
	}

	return countItems(filteredItems)
}

const getOrderItemsByCategory = (categoryCount) => Object.entries(categoryCount)
	.map(([category, count]) => `${category}: ${count}`)
	.join(', ')

const getSortedCategoriesData = (data) => {
	const sortedKeys = Object.keys(data).sort((a, b) => {
		if (a === 'Sonstiges') return 1
		if (b === 'Sonstiges') return -1
		return a.localeCompare(b)
	})
	return Object.fromEntries(sortedKeys.map(key => [key, data[key]]))
}

export const countOrderItemsByCategory = (order) => {
	if (!order || !order.items) return null
	const filteredItems = order.items.filter(item => item.type !== 'EXTRA_DISCOUNT')

	const categoryCount = filteredItems.reduce((acc, item) => {
		if (item.type === 'MENU') {
			item.items.forEach(subItem => {
				const category = subItem.category || 'Sonstiges'
				acc[category] = (acc[category] || 0) + (subItem.quantity * item.quantity)
			})
		} else {
			const category = item.category || 'Sonstiges'
			acc[category] = (acc[category] || 0) + item.quantity
		}
		return acc
	}, {})
	const sortedCategories = getSortedCategoriesData(categoryCount)

	return getOrderItemsByCategory(sortedCategories)
}


