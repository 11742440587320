import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompanyIndex } from '../../../../redux/selector/system'
import { UnpaidInvoice, UnpaidInvoiceButton, UnpaidInvoiceButtonText, UnpaidInvoicesEmpty, UnpaidInvoicesEmptyText, UnpaidInvoicesTable, UnpaidInvoicesTableWrap, UnpaidInvoicesTitle, UnpaidInvoicesWrap, UnpaidInvoiceText, UnpaidInvoiceTextWrap } from './index.styled'
import { InView, useInView } from 'react-intersection-observer'
import { TableLoader } from '../../../Elements/table/style/table/loader/index.styled'
import { getInvoiceLinkThunk, getInvoicesThunk } from '../../../../redux/thunk/zohoInvoice'
import InfoMessage from '../../../Elements/message/InfoMessage'
import Loader from '../../../Elements/loaders'
import colors from '../../../../style/colors'
import UnpaidInvoiceSkeleton from './skeleton'
import ButtonIcon from '../../../../img/svg/button'
import MainIcon from '../../../../img/svg/main'
import { getNeededDateFormat } from '../../../../helper'
import { setInvoicesIsLoading } from '../../../../redux/action/zohoInvoice'

const perLoad = 10

const UnpaidInvoices = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const invoices = useSelector(store => store.zohoInvoice.invoices)
	const totalLength = useSelector(store => store.zohoInvoice.invoicesTotalLength)
	const invoicesIsLoading = useSelector(store => store.zohoInvoice.invoicesIsLoading)
	const invoiceLinkIsLoading = useSelector(store => store.zohoInvoice.invoiceLinkIsLoading)
	const currentLength = invoices?.length
	const hasInvoices = invoices?.length > 0

	useEffect(() => {
		if (companyIndex) {
			dispatch(setInvoicesIsLoading(true))
			onLoad(0, 10)
		}
	}, [companyIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	const onLoad = useCallback((page, size) => {
		dispatch(getInvoicesThunk(page, size, companyIndex, 'OVERDUE'))
	}, [dispatch, companyIndex])

	const { ref, inView } = useInView({
		threshold: 0,
	})

	const loadTable = useCallback(() => {
		if (onLoad) onLoad(Math.ceil(currentLength / perLoad), perLoad)
	}, [currentLength, onLoad])

	useEffect(() => {
		if ((totalLength === currentLength && currentLength === 0) || (inView && totalLength > currentLength)) {
			loadTable()
		}
	}, [totalLength, currentLength]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleInViewChange = useCallback((inView) => {
		if (inView && totalLength > currentLength) {
			loadTable()
		}
	}, [totalLength, currentLength, loadTable])

	const handleViewInvoice = useCallback(async (id) => {
		const link = await dispatch(getInvoiceLinkThunk(companyIndex, id))
		if (link) {
			window.open(link, '_blank')
		}
	}, [dispatch, companyIndex])

	return (
		<UnpaidInvoicesWrap>
			<UnpaidInvoicesTitle>{t('app.modals.unpaidInvoices.title')}</UnpaidInvoicesTitle>
			<UnpaidInvoicesTableWrap>
				<UnpaidInvoicesTable>
					{invoicesIsLoading &&
						(Array(3)).fill().map((el, index) =>
							<UnpaidInvoiceSkeleton key={'skeleton-' + index} />)}
					{!invoicesIsLoading && hasInvoices &&
						invoices?.map((invoice, index) => <UnpaidInvoice key={index}>
							<UnpaidInvoiceTextWrap>
								<UnpaidInvoiceText>{getNeededDateFormat(invoice.invoiceDate)}</UnpaidInvoiceText>
								<UnpaidInvoiceText>{t('app.modals.unpaidInvoices.invoiceNumber')} {invoice.invoiceNumber}</UnpaidInvoiceText>
							</UnpaidInvoiceTextWrap>
							<UnpaidInvoiceButton onClick={() => handleViewInvoice(invoice.id)}>
								{invoiceLinkIsLoading ? <Loader color={colors.blue} zoom={0.5} /> : <>
									<ButtonIcon icon="eye" fill="red" />
									<UnpaidInvoiceButtonText>{t('app.modals.unpaidInvoices.view')}</UnpaidInvoiceButtonText></>}
							</UnpaidInvoiceButton>
						</UnpaidInvoice>)}
					{!invoicesIsLoading && !hasInvoices &&
						<UnpaidInvoicesEmpty>
							<MainIcon icon="invoice" />
							<UnpaidInvoicesEmptyText>{t('app.modals.unpaidInvoices.empty')}</UnpaidInvoicesEmptyText>
						</UnpaidInvoicesEmpty>}
				</UnpaidInvoicesTable>
				<InView as={TableLoader} onChange={handleInViewChange} style={{ marginTop: 0, height: 0 }}>
					<span id="total-count" style={{ opacity: 0 }} ref={ref}>{totalLength}</span>
				</InView>
			</UnpaidInvoicesTableWrap>
			<InfoMessage className="no-bottom" fullWidth={true}>{t('app.modals.unpaidInvoices.info')}</InfoMessage>
		</UnpaidInvoicesWrap>
	)
})

UnpaidInvoices.displayName = 'UnpaidInvoices'
export default UnpaidInvoices
