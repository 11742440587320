import { appendDeliveryConfigPolygon, appendDeliveryConfigs, setDeliveryConfigLength, setDeliveryConfigPolygonError, setDeliveryConfigPolygons, setDeliveryConfigPolygonsLength, setDeliveryConfigs, setDeliveryConfigType, setDeliveryPeriodsConfig } from '../../action/deliveryConfig'
import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import { setDeliveryCartDefaultDeliveryCost, setDeliveryCartFreeFrom, setDeliveryCartMaxDistance, setDeliveryCartMinimumPrice, setDeliveryCartPeriodConfig, setShowDeliveryConfigError } from '../../action/deliveryCart'
import fetchDeliveryConfigs from '../../axios/customer/deliveryConfig'
import deleteDeliveryConfig from '../../axios/customer/deliveryConfig/delete'
import storeDeliveryConfig from '../../axios/customer/deliveryConfig/post'
import updateDeliveryConfig from '../../axios/customer/deliveryConfig/put'
import getDeliveryConfig from '../../axios/customer/deliveryConfig/get'
import getDeliveryTypeConfig from '../../axios/customer/deliveryConfig/getType'
import updateDeliveryTypeConfig from '../../axios/customer/deliveryConfig/putType'
import createDeliveryConfigPolygon from '../../axios/customer/deliveryConfig/polygon/post'
import getDeliveryConfigAllPolygon from '../../axios/customer/deliveryConfig/polygon'
import updateDeliveryConfigPolygon from '../../axios/customer/deliveryConfig/polygon/put'
import deleteDeliveryConfigPolygon from '../../axios/customer/deliveryConfig/polygon/delete'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import getDeliveryPeriods from '../../axios/customer/deliveryPeriods/get'
import updateDeliveryPeriods from '../../axios/customer/deliveryPeriods/put'

export const getDeliveryConfigsThunk = (page, size) => dispatch => {
	return fetchDeliveryConfigs(page, size)
		.then(res => {
			dispatch(setDeliveryConfigLength(parseInt(res.headers['x-delivery-configs-total'])))
			if (page > 0 && size > 0) {
				dispatch(appendDeliveryConfigs(res.data))
				return
			}
			dispatch(setDeliveryConfigs(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storeDeliveryConfigThunk = (data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return storeDeliveryConfig(data)
		.then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
			return dispatch(getDeliveryConfigsThunk(0, 10))
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteDeliveryConfigThunk = (id) => dispatch => {
	return deleteDeliveryConfig(id)
		.then(() => {
			return dispatch(getDeliveryConfigsThunk(0, 10))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateDeliveryConfigThunk = (id, data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return updateDeliveryConfig(id, data)
		.then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
			return dispatch(getDeliveryConfigsThunk(0, 10))
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const resetDeliveryConfigThunk = () => dispatch => {
	dispatch(setDeliveryCartDefaultDeliveryCost(null))
	dispatch(setDeliveryCartMinimumPrice(0))
	dispatch(setDeliveryCartFreeFrom(null))
	dispatch(setDeliveryCartMaxDistance(null))
	dispatch(setShowDeliveryConfigError(false))
	dispatch(setDeliveryCartPeriodConfig(null))
}

export const getDeliveryConfigThunk = (distance, lat, lon, onSuccess = () => {
}) => (dispatch) => {
	dispatch(resetDeliveryConfigThunk())

	return getDeliveryConfig(distance, lat, lon)
		.then(res => {
			const deliveryConfig = res?.data
			onSuccess(deliveryConfig)
			dispatch(setDeliveryCartMinimumPrice(deliveryConfig.minimumPrice))
			dispatch(setDeliveryCartFreeFrom(deliveryConfig.freeFrom))
			dispatch(setDeliveryCartDefaultDeliveryCost(deliveryConfig.deliveryCost))
			dispatch(setDeliveryCartPeriodConfig(deliveryConfig.periodConfig))
		}).catch(error => {
			const isDeliveryConfigNotFound = error.response.data?.apierror.errorStatusCode === 'delivery_config_not_found'
			const isCoordsOutsidePolygons = error.response.data?.apierror.errorStatusCode === 'coords_is_outside_polygons'

			if (isDeliveryConfigNotFound || isCoordsOutsidePolygons) {
				const data = error.response.data?.apierror.data
				onSuccess(data)
				dispatch(setShowDeliveryConfigError(true))
				dispatch(setDeliveryCartMinimumPrice(data.minimumPrice))
				dispatch(setDeliveryCartFreeFrom(data.freeFrom))
				dispatch(setDeliveryCartDefaultDeliveryCost(data.deliveryCost))
				if (isDeliveryConfigNotFound) dispatch(setDeliveryCartMaxDistance(data.maxDistance))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const getDeliveryTypeConfigThunk = () => dispatch => {
	return getDeliveryTypeConfig()
		.then(res => {
			dispatch(setDeliveryConfigType(res.data.type))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateDeliveryTypeConfigThunk = (data) => dispatch => {

	return updateDeliveryTypeConfig(data)
		.then((res) => {
			dispatch(setDeliveryConfigType(res.data.type))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getDeliveryConfigAllPolygonThunk = (page, size, callbackSuccess) => dispatch => {
	return getDeliveryConfigAllPolygon(page, size)
		.then(res => {
			dispatch(setDeliveryConfigPolygonsLength(parseInt(res.headers['x-delivery-polygon-configs-total'])))
			if (page > 0 && size > 0) {
				dispatch(appendDeliveryConfigPolygon(res.data))
				return
			}
			if (typeof callbackSuccess === 'function' && !res.data.length) {
				callbackSuccess()
			}
			dispatch(setDeliveryConfigPolygons(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createDeliveryConfigPolygonThunk = (data, callbackSuccess, callbackError) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return createDeliveryConfigPolygon(data)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
			dispatch(getDeliveryConfigAllPolygonThunk(0, 30))
			if (typeof callbackSuccess === 'function') callbackSuccess()
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			const isDuplicateColor = error.response.data?.apierror.errorStatusCode === 'delivery_polygon_config_duplicate_color'
			const isNotValidPolygon = error.response.data?.apierror.errorStatusCode === 'delivery_polygon_config_is_not_valid'

			if (isNotValidPolygon) {
				return dispatch(setDeliveryConfigPolygonError(true))
			}

			if (isDuplicateColor && typeof callbackError === 'function') {
				callbackError()
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const updateDeliveryConfigPolygonThunk = (id, data, callbackSuccess, callbackError) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return updateDeliveryConfigPolygon(id, data)
		.then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
			if (typeof callbackSuccess === 'function') callbackSuccess()
			return dispatch(getDeliveryConfigAllPolygonThunk(0, 30))
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			const isDuplicateColor = error.response.data?.apierror.errorStatusCode === 'delivery_polygon_config_duplicate_color'
			const isNotValidPolygon = error.response.data?.apierror.errorStatusCode === 'delivery_polygon_config_is_not_valid'

			if (isNotValidPolygon) {
				return dispatch(setDeliveryConfigPolygonError(true))
			}

			if (isDuplicateColor && typeof callbackError === 'function') {
				callbackError()
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const deleteDeliveryConfigPolygonThunk = (id) => dispatch => {
	return deleteDeliveryConfigPolygon(id)
		.then(() => {
			return dispatch(getDeliveryConfigAllPolygonThunk(0, 30))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getDeliveryPeriodsThunk = () => dispatch => {
	return getDeliveryPeriods()
		.then(res => {
			dispatch(setDeliveryPeriodsConfig(res.data?.[0]))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateDeliveryPeriodsThunk = (id, data) => dispatch => {
	return updateDeliveryPeriods(id, data)
		.then(res => {
			return dispatch(setDeliveryPeriodsConfig(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
