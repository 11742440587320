import React from 'react'
import MainIcon from '../../../../img/svg/main'
import { TableData, TableDataContent, TableDataIconWrap, TableDataNumber, TableDataPrice, TableDataPriceWrap, TableDataWaiter, TableDataWaiterWrap } from '../SVGTables/index.styled'
import Currency from '../../../Elements/currency'
import { RestTableWaiter, RestTableWaiterWrap } from '../../../Restaurant/areas/tables/table/index.styled'
import { useTranslation } from 'react-i18next'
import { getRestTableData } from './helper'

const RestTableComponent = React.memo(({
	data, i, handleObjClick = () => {
	}, isQRCode,
	isSettingsArea, isSettingsForm,
	totalPrice, uuid, processedBy, tableWaiterCall, isReadyFood, disabled
}) => {
	const { t } = useTranslation()
	const layoutData = data?.formType.toLowerCase() + ' '
	const isExistPaidBeforeOrders = data.payBeforeOrders?.length > 0
	const paidBeforeOrdersMoreThanOne = data.payBeforeOrders?.length > 1
	const paidBeforeOrdersAndOrders = isExistPaidBeforeOrders && (data.orders?.length > 0 || data.splits?.length > 0)
	const isVertical = data.width < data.height
	const restTableData = getRestTableData({ isSettingsForm, isSettingsArea, isReadyFood, isQRCode, isExistPaidBeforeOrders, paidBeforeOrdersMoreThanOne, paidBeforeOrdersAndOrders, totalPrice, processedBy, t })
	const icons = restTableData?.icons
	const className = restTableData?.className
	const price = restTableData?.price
	const text = restTableData?.text
	const waiterName = restTableData?.waiterName
	const layout = (isVertical ? 'vertical ' : '') + (className ? `${className} ` : '') + (disabled ? 'disabled ' : '')
	const tableNumber = data?.number
	const testIdParam = tableNumber ? tableNumber : i

	return (
		<TableData className={layoutData + layout} id={uuid}>
			<TableDataContent className={layout}>
				<TableDataNumber className={layout + ' number '}>{tableNumber}</TableDataNumber>
				{(price || text) &&
					<TableDataPriceWrap className={layout}>
						{price && <TableDataPrice className={layout + ' total-price'}><Currency>{price}</Currency></TableDataPrice>}
						{text && <TableDataPrice className={layout}>{text}</TableDataPrice>}
					</TableDataPriceWrap>}
				{icons.length > 0 &&
					<TableDataIconWrap onClick={() => handleObjClick(data?.id, 'table')}
														 onGotPointerCapture={() => handleObjClick(data?.id, 'table')}
														 className={layout}
														 data-testid={`table-icon-${testIdParam}`}>
						{icons.map((icon, index) => <MainIcon key={index} icon={icon} />)}
					</TableDataIconWrap>}
			</TableDataContent>
			{waiterName && !tableWaiterCall &&
				<TableDataWaiterWrap className={layoutData + layout}>
					<TableDataWaiter data-testid={`table-waiter-${testIdParam}`} className={layout}>
						{waiterName}
					</TableDataWaiter>
				</TableDataWaiterWrap>}

			{tableWaiterCall && (
				<RestTableWaiterWrap className="waiter-call" data-testid="table-waiter-call">
					<MainIcon icon="waiter-call" />
					<RestTableWaiter>{t('restaurant.waiterCall')} {processedBy}</RestTableWaiter>
				</RestTableWaiterWrap>
			)}
		</TableData>
	)
})
RestTableComponent.displayName = 'RestTableComponent'
export default RestTableComponent
