import { SET_RECEIPT_IS_LOADING } from '../../actionTypes/receipt'

const initialState = {
	receiptIsLoading: false
}

export function receiptReducer (state = initialState, action) {
	switch (action.type) {
		case SET_RECEIPT_IS_LOADING:
			return {
				...state,
				receiptIsLoading: action.value
			}
		default:
			return state
	}
}
