import React from 'react'
import { InputErrorText, InputErrorWrap, MoneyInputBorderContainer, MoneyInputContainer, MoneyInputField, MoneyLabelText, MoneyWrapper } from '../index.styled'
import { getCurrencySymbol } from '../../../../helper'
import { useSelector } from 'react-redux'
import { selectCompanyData } from '../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'
import { useTranslation } from 'react-i18next'

const MoneyInput = React.memo(({ name, value, textForMoneyInput, onChange, onBlur, onFocus, focused, error }) => {
	const { t } = useTranslation()
	const currencyCode = useSelector(selectCompanyData('currency'))
	const isMobile = useWindowBreakPoint(theme.point720)
	const layout = (focused ? 'focused ' : '') + (error ? 'error ' : '')

	return (
		<MoneyWrapper onClick={onFocus}>
			<MoneyLabelText htmlFor={`calculator-${name}`}>{textForMoneyInput} ({getCurrencySymbol(currencyCode)})</MoneyLabelText>
			<MoneyInputBorderContainer className={layout}>
				<MoneyInputContainer className={layout}>
					<MoneyInputField value={value}
															name={name}
															readOnly={!isMobile && true}
															onChange={onChange}
															onBlur={onBlur} 
															id={`calculator-${name}`}
															inputMode="decimal"
															className={layout} />
				</MoneyInputContainer>
				{error && <InputErrorWrap className={layout + 'money-input'}>
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17" fill="none">
							<path d="M17.8299 14.7611L10.3728 0.713744C9.77276 -0.270467 8.31562 -0.270467 7.71562 0.713744L0.172762 14.7611C-0.341524 15.7453 0.34419 16.998 1.54419 16.998H16.5442C17.5728 16.998 18.3442 15.8348 17.8299 14.7611ZM10.1156 13.6874C10.1156 14.4032 9.60133 14.8506 9.00133 14.8506C8.40133 14.8506 7.88705 14.3137 7.88705 13.6874V12.9716C7.88705 12.3453 8.40133 11.8085 9.00133 11.8085C9.60133 11.8085 10.1156 12.3453 10.1156 12.9716V13.6874ZM10.1156 9.92953C10.1156 10.6453 9.60133 11.0927 9.00133 11.0927C8.40133 11.0927 7.88705 10.5559 7.88705 9.92953V5.45585C7.88705 4.82953 8.40133 4.29269 9.00133 4.29269C9.60133 4.29269 10.1156 4.82953 10.1156 5.45585V9.92953Z" fill="#8F2F36" />
						</svg>
						<InputErrorText>{t('changeTipsCalculator.errorTips')}</InputErrorText>
					</div>
				</InputErrorWrap>}
			</MoneyInputBorderContainer>
		</MoneyWrapper>
	)
})


MoneyInput.displayName = 'MoneyInput'
export default MoneyInput
