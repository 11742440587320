import React from 'react'
import { RestBarCounter } from './index.styled'

const BarCounter = React.memo(({ data, index, children, onClick, color, testId, id, showIcon, isQRCode, animationClassname, disabled }) => {
	const layout = (color ? `${color} ` : '') + (data.isEm ? 'sizeWithEm ' : '') + (isQRCode ? 'qr-code ' : '') + (showIcon ? 'icon ' : '') + (animationClassname ? animationClassname + ' ' : '')  + (disabled ? 'disabled ' : '')
	return (
		<RestBarCounter id={id} {...data} onClick={onClick} className={layout} data-testid={testId}>
			{children}
		</RestBarCounter>
	)
})
BarCounter.displayName = 'BarCounter'
export default BarCounter
