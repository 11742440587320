import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { subscriptionServiceUrl } from '../../../../urls'
import { getLocalStorageAccessToken } from '../../../../helper/clientMonitor'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
}

const accessToken = getLocalStorageAccessToken()

if (accessToken) {
	headers.Authorization = `Bearer ${accessToken}`
}

const subscriptionInstance = axios.create({
	baseURL: subscriptionServiceUrl,
	headers,
})

subscriptionInstance.interceptors.request.use(config => {
	return requestInterceptor(config)
}, error => Promise.reject(error))

subscriptionInstance.interceptors.response.use(response => response, error => {
	return responseInterceptor(error)
})

export default subscriptionInstance
