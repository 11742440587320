import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../../../Elements/table'
import TableTr from '../../../../../Elements/table/tr'
import { TableTd, TableTdGrid, TableTdStatus } from '../../../../../Elements/table/style/table/td/index.styled'
import { TableOrderMobileTitle, TextBig } from '../../../../../Elements/table/OrdersTable/index.styled'
import { useSelector } from 'react-redux'
import { selectRestaurantPaidBeforeOrdersTable } from '../../../../../../redux/selector/restaurant'
import { getNeededDateFormat } from '../../../../../../helper'
import TableTdElementsTooltip from '../../../../../Elements/table/TableTdElements/TableTdElementsTooltip'
import MainIcon from '../../../../../../img/svg/main'
import Currency from '../../../../../Elements/currency'
import { statusOptionsStatic } from '../../../../../../redux/reducer/order/constants'

const PAYMENT_METHOD = 'ONLINE'

const PaidBeforeOrdersTable = React.memo(({ activeRow, selectOrder }) => {
	const { t } = useTranslation()
	const paidBeforeOrdersOnTable = useSelector(selectRestaurantPaidBeforeOrdersTable)

	const tableHeadersStatic = useMemo(() => [
		{ title: t('order.table.time') },
		{ title: t('order.table.sum') },
		{ title: t('order.table.status') },
	], [t])

	return (
		<CustomTable headers={tableHeadersStatic}
								 id="paid-before-orders-table">
			{paidBeforeOrdersOnTable?.map((order, i) => {
					const totalPrice = order?.totalPrice
					const isExistBeingPreparedItem = order?.items?.some(item => item.status === 'BEING_PREPARED')
					const orderStatus = isExistBeingPreparedItem ? 'BEING_PREPARED' : order?.items?.[0]?.status
					const statusLabel = statusOptionsStatic.find(status => status.value === orderStatus)?.label
					return (
						<TableTr key={i}
										 isActive={i === activeRow}
										 onClick={() => selectOrder(order, i)}
										 className="row-tablet-cols-3 bold-border">
							<TableTd>
								<TableOrderMobileTitle>{tableHeadersStatic[0].title}</TableOrderMobileTitle>
								<TableTdGrid>
									{getNeededDateFormat(order.placeDate, 'HH:mm')}
								</TableTdGrid>
							</TableTd>
							<TableTd>
								<TableOrderMobileTitle>{tableHeadersStatic[1].title}</TableOrderMobileTitle>
								<TableTdGrid key={i}>
									<TableTdElementsTooltip text={t(`Filter.payment.${PAYMENT_METHOD}`)}>
										<MainIcon icon={PAYMENT_METHOD} />
									</TableTdElementsTooltip>
									<TextBig className="small"><Currency>{totalPrice}</Currency></TextBig>
								</TableTdGrid>
							</TableTd>
							<TableTdStatus className={'const-color ' + (orderStatus ? `${orderStatus}` : '')}>
								<TableOrderMobileTitle>{tableHeadersStatic[2].title} </TableOrderMobileTitle>
								<TableTdGrid>
									<MainIcon icon={orderStatus} />
									<TextBig>{statusLabel ? t(statusLabel) : ''}</TextBig>
								</TableTdGrid>
							</TableTdStatus>
						</TableTr>
					)
				},
			)}
		</CustomTable>
	)
})

PaidBeforeOrdersTable.displayName = 'PaidBeforeOrdersTable'
export default PaidBeforeOrdersTable
