import React, { useCallback, useState } from 'react'
import { CouponFormInputs, FlyerCouponWrap } from '../index.styled'
import TextInputControlled from '../../../../../../Elements/inputs/TextInput/controlled'
import Button from '../../../../../../Elements/buttons/Button'
import ErrorMessage from '../../../../../../Elements/message/ErrorMessage'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompanyIndex } from '../../../../../../../redux/selector/system'
import { selectFlyerCouponError } from '../../../../../../../redux/selector/coupon'
import { setFlyerCouponIsInvalid, setFlyerCouponIsUsed, setFlyerCouponLoading, setValidatedFlyerCoupon } from '../../../../../../../redux/action/coupon'
import { getIsValidFlyerCouponThunk } from '../../../../../../../redux/thunk/coupon'

const RestaurantFlyerCoupon = React.memo(({ titlePrice }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [couponNumber, setCouponNumber] = useState('')
	const validatedFlyerCoupon = useSelector(state => state.coupon?.validatedFlyerCoupon)
	const flyerCouponIsInvalid = useSelector(state => state.coupon?.flyerCouponIsInvalid)
	const flyerCouponLoading = useSelector(state => state.coupon?.flyerCouponLoading)
	const textButtonForFlyerCoupon = validatedFlyerCoupon ? t('buttons.controls.delete') : t('FlyerCoupon.checkCoupon')
	const companyIndex = useSelector(selectCompanyIndex)
	const disabledCheckFlyerCoupon = flyerCouponLoading
	const errorMessages = useSelector(selectFlyerCouponError(titlePrice, t))

	const resetErrors = useCallback(() => {
		dispatch(setFlyerCouponIsInvalid(false))
		dispatch(setFlyerCouponIsUsed(false))
	}, [dispatch])

	const handleCouponNumberChange = useCallback((e) => {
		const flyerCoupon = e.target.value
		const trimmedCouponNumber = flyerCoupon.trim()
		setCouponNumber(trimmedCouponNumber)
		resetErrors()
	}, [resetErrors])

	const removeFlyerCoupon = useCallback(() => {
		setCouponNumber('')
		dispatch(setFlyerCouponIsInvalid(false))
		dispatch(setFlyerCouponIsUsed(false))
		dispatch(setValidatedFlyerCoupon(null))
	}, [dispatch])

	const handleFlyerCoupon = useCallback(() => {
		if (validatedFlyerCoupon) {
			removeFlyerCoupon()
		} else {
			dispatch(setFlyerCouponLoading(true))
			dispatch(getIsValidFlyerCouponThunk(companyIndex, couponNumber))
		}
	}, [dispatch, couponNumber, companyIndex, validatedFlyerCoupon, removeFlyerCoupon])

	return (
		<FlyerCouponWrap>
			<CouponFormInputs>
				<TextInputControlled name="flyer-coupon"
														 label={t('FlyerCoupon.coupon')}
														 testId="flyer-coupon"
														 value={couponNumber}
														 onChange={handleCouponNumberChange}
														 color="gray"
														 showCheck={!!validatedFlyerCoupon}
														 disabled={!!validatedFlyerCoupon}
														 className={flyerCouponIsInvalid ? 'error' : ''} />
				<Button name="checkFlyerCoupon"
								testId="checkFlyerCoupon"
								color={validatedFlyerCoupon ? 'gray' : 'blue'}
								icon={validatedFlyerCoupon ? 'basket' : 'magnifying'}
								onClick={handleFlyerCoupon}
								text={textButtonForFlyerCoupon}
								disabled={disabledCheckFlyerCoupon} />
			</CouponFormInputs>
			{errorMessages && <ErrorMessage className="not-marginBtm textLeft">{errorMessages}</ErrorMessage>}

		</FlyerCouponWrap>
	)
})
RestaurantFlyerCoupon.displayName = 'RestaurantFlyerCoupon'
export default RestaurantFlyerCoupon
