import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const NotificationModalInfoWrapper = styled.div`
	display: flex;
	margin-top: 1.25em;
	align-items: flex-start;
	gap: 0.63em;
	align-self: stretch;

	> svg {
		width: 1.93em;
		height: 1.75em;
		fill: ${colorTheme.Tap2PayNotificationModal.message.icon};
		flex-shrink: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		align-items: center;
		flex-direction: column;
		margin-top: 0;

		> svg {
			width: 2.21em;
			height: 2em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;

		> svg {
			width: 1.93em;
			height: 1.75em;
		}
	}
`
NotificationModalInfoWrapper.displayName = 'NotificationModalInfoWrapper'

export const NotificationModalInfoText = styled.div`
	color: ${colorTheme.Tap2PayNotificationModal.message.text};
	font-size: 1.5em;
	font-weight: 500;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.72em;
		text-align: center;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.5em;
	}
`
NotificationModalInfoText.displayName = 'NotificationModalInfoText'
