import { SET_DATEV_ACCOUNTING_DATA, SET_DATEV_PLATFORM_RESTAURANTS_DATA, SET_DATEV_LOADING, SET_DATEV_ADDITIONAL_PAYMENT_CONFIGS } from '../../actionTypes/datev'

const initialState = {
	accountingData: null,
	platformRestaurantsData: {},
	additionalPaymentConfigs: [],
	loading: false
}

export function datevReducer (state = initialState, action) {
	switch (action.type) {
		case SET_DATEV_ACCOUNTING_DATA:
			return {
				...state,
				accountingData: action.value
			}

		case SET_DATEV_PLATFORM_RESTAURANTS_DATA:
			return {
				...state,
				platformRestaurantsData: action.value
			}

		case SET_DATEV_ADDITIONAL_PAYMENT_CONFIGS:
			return {
				...state,
				additionalPaymentConfigs: action.value
			}

		case SET_DATEV_LOADING:
			return {
				...state,
				loading: action.value
			}

		default:
			return state
	}
}


