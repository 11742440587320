export const SET_COUPON = 'SET_COUPON'
export const SET_COUPONS = 'SET_COUPONS'
export const APPEND_COUPONS = 'APPEND_COUPONS'
export const SET_COUPONS_LENGTH = 'SET_COUPONS_LENGTH'
export const SET_COUPON_ERROR_IS_EXIST = 'SET_COUPON_ERROR_IS_EXIST'
export const SET_COUPON_DISABLED_FOR_KASSE = 'SET_COUPON_DISABLED_FOR_KASSE'
export const SET_GIFT_COUPONS = 'SET_GIFT_COUPONS'
export const SET_GIFT_COUPONS_LENGTH = 'SET_GIFT_COUPONS_LENGTH'
export const APPEND_GIFT_COUPONS = 'APPEND_GIFT_COUPONS'
export const SET_GIFT_COUPON_IS_INVALID = 'SET_GIFT_COUPON_IS_INVALID'
export const SET_GIFT_COUPON_IS_USED = 'SET_GIFT_COUPON_IS_USED'
export const SET_GIFT_COUPON_IS_NOT_EXIST = 'SET_GIFT_COUPON_IS_NOT_EXIST'
export const SET_GIFT_COUPON_IS_CANCELED = 'SET_GIFT_COUPON_IS_CANCELED'
export const SET_GIFT_COUPON = 'SET_GIFT_COUPON'
export const SET_FLYER_COUPON = 'SET_FLYER_COUPON'
export const SET_FLYER_COUPONS = 'SET_FLYER_COUPONS'
export const SET_FLYER_COUPON_ERROR_IS_EXIST = 'SET_FLYER_COUPON_ERROR_IS_EXIST'
export const SET_COUPON_ACTIVE_TAB = 'SET_COUPON_ACTIVE_TAB'
export const SET_FLYER_COUPON_IS_INVALID = 'SET_FLYER_COUPON_IS_INVALID'
export const SET_VALIDATED_FLYER_COUPON = 'SET_VALIDATED_FLYER_COUPON'
export const SET_FLYER_COUPON_LOADING = 'SET_FLYER_COUPON_LOADING'
export const SET_FLYER_COUPON_IS_USED = 'SET_FLYER_COUPON_IS_USED'

