import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const KitchenMonitorSettings = lazyWithRetry(() => import(/* webpackChunkName: "kitchen-monitor-settings" */'../../component/KitchenMonitorSettings'))

const KitchenMonitorSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			 <KitchenMonitorSettings />
		</Suspense>
	)
})

KitchenMonitorSettingsPage.displayName = 'KitchenMonitorSettingsPage'
export default KitchenMonitorSettingsPage
