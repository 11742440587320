import { retrieveErrorFromApi } from '../../../helper'
import { appendInvoices, setInvoiceLinkIsLoading, setInvoices, setInvoicesIsLoading, setInvoicesIsNotFound, setInvoicesTotalLength } from '../../action/zohoInvoice'
import { setAppErrors } from '../../action/system'
import fetchInvoices from '../../axios/zohoInvoice'
import getInvoiceLink from '../../axios/zohoInvoice/links/get'

export const getInvoicesThunk = (page, size, companyIndex, status) => dispatch => {
	return fetchInvoices(page, size, companyIndex, status)
		.then(res => {
			dispatch(setInvoicesTotalLength(res.data.totalElements))
			if (page > 0 && size > 0) {
				dispatch(appendInvoices(res.data.content))
				dispatch(setInvoicesIsLoading(false))
				return
			}
			dispatch(setInvoices(res.data.content))
			dispatch(setInvoicesIsLoading(false))
		}).catch(error => {
			dispatch(setInvoicesIsLoading(false))
			if (error.response.status === 404) {
				dispatch(setInvoicesIsNotFound(true))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const getInvoiceLinkThunk = (companyIndex, id) => async (dispatch) => {
	dispatch(setInvoiceLinkIsLoading(true))
	try {
		const res = await getInvoiceLink(companyIndex, id)
		dispatch(setInvoiceLinkIsLoading(false))
		return res.data?.[0]?.url
	} catch (error) {
		dispatch(setInvoiceLinkIsLoading(false))
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}
