import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setRestaurantCouponActivateModal, setRestaurantModalChangeCalculatorIsOpen, setRestaurantModalChangeTipsCalculatorIsOpen, setRestaurantModalInvoiceConfigsIsOpen, setRestaurantModalInvoiceCustomerDataIsOpen, setRestaurantModalTerminalButtonsIsOpen, setRestaurantModalTerminalPaymentProcessIsOpen, setRestaurantPaymentTypeModal, setRestaurantShowDepositPaymentModal } from '../../../../../redux/action/restaurant'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import RestaurantDiscount from './RestaurantDiscount'
import { getCartPaymentInvoiceConfigsThunk, getCartPaymentTerminalsThunk, getCartPaymentThunk } from '../../../../../redux/thunk/cartPayment'
import { setCartPaymentDeposits, setCartPaymentGiftCoupons, setCartPaymentSelectedTerminal } from '../../../../../redux/action/cartPayment'
import { PaymentCost, PaymentHeader, PaymentTitle, PaymentTitleWrapper } from '../../../../Elements/cart/index.styled'
import Currency from '../../../../Elements/currency'
import { calculateGiftCouponsBalance } from '../../../../CashDesk/helper'
import { setFlyerCouponIsInvalid, setFlyerCouponIsUsed, setGiftCoupon, setValidatedFlyerCoupon } from '../../../../../redux/action/coupon'
import { getGiftCouponsSum } from '../../../../../helper/getGiftCouponsPayment'
import { selectExtraDiscountItemOnTable, selectGiftCouponOnTable, selectMarketingCouponItemOnTable, selectSelectedPresetDiscount } from '../../../../../redux/selector/restaurant'
import { roundPrice } from '../../../../../helper'
import PaymentModals from './modals'
import { selectContainsAnyRole, selectFormattedCurrency, selectHasAnyGroup, selectIsTestCompany } from '../../../../../redux/selector/system'
import { QrCodeButtonText } from '../../../../Elements/cart/ModalPayment/buttonTexts'
import { isDisabledTerminalTips } from '../../../../../redux/selector/cartPayment/helper'
import { setShowTap2PayAvailabilityCheckerModal } from '../../../../../redux/action/tap2Pay'
import { isMobileAppMode } from '../../../../../helper/mode'
import { convert2DepositPayment } from './helper'
import { getDepositsSum } from '../../../../../helper/getDepositsPayment'

const EVENT_Z_INDEX = 34
const PER_LOAD = 10
const STRIPE_MIN_ORDER_AMOUNT = 0.5
const PAYMENT_BUTTONS_LIMIT = 7

const ModalPaymentType = ({ title, totalPrice, totalDiscount, extraDiscountOrderItem = null, handlePaymentType, updatePaymentMode = false, resetTable }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const showPaymentTypeModal = useSelector(state => state.restaurant.showPaymentTypeModal)
	const modalChangeCalculatorIsNeeded = useSelector(store => store.restaurant.modalChangeCalculatorIsNeeded)
	const payments = useSelector(store => store.cartPayment.payments)
	const terminals = useSelector(store => store.cartPayment.terminals)
	const terminalsLength = useSelector(store => store.cartPayment.terminalsLength)
	const giftCoupon = useSelector(state => state.coupon?.giftCoupon)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasModalLogin = !!waiterAccessToken
	const hasAdminRole = useSelector(selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN']))
	const hasRoleSettingsReadOrWrite = useSelector(selectContainsAnyRole(['PROGRAM_SETTINGS_READ', 'PROGRAM_SETTINGS_WRITE']))
	const isExistInvoicePaymentConfig = !!useSelector(state => state.cartPayment.invoiceConfigs)
	const formattedEcTerminalLimitSum = useSelector(selectFormattedCurrency(STRIPE_MIN_ORDER_AMOUNT))
	const giftCoupons = useSelector(state => state.cartPayment.giftCoupons)
	const deposits = useSelector(state => state.cartPayment.deposits)

	const [ecTerminalTips, setEcTerminalTips] = useState(0)
	const [customerDiscount, setCustomerDiscount] = useState(0)
	const [customerDiscountType, setCustomerDiscountType] = useState('CURRENCY')
	const [customerDiscountHasError, setCustomerDiscountHasError] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState('')
	const [presetDiscountId, setPresetDiscountId] = useState(null)
	const [depositPayment, setDepositPayment] = useState(null)
	const isExistPresetDiscountOrderItem = !!useSelector(selectSelectedPresetDiscount(extraDiscountOrderItem?.itemId))
	const extraDiscountItem = useSelector(selectExtraDiscountItemOnTable(presetDiscountId, totalPrice, customerDiscount, customerDiscountType))
	const extraDiscount = extraDiscountItem.value
	const marketingCouponItem = useSelector(selectMarketingCouponItemOnTable(totalPrice, t))
	const marketingCouponDiscount = marketingCouponItem?.value || 0

	const hasNotGiftCouponInTable = !useSelector(selectGiftCouponOnTable)
	const giftCouponSum = getGiftCouponsSum(giftCoupons)
	const depositsSum = getDepositsSum(deposits)
	const resultTotalPrice = totalPrice >= 0 ? roundPrice(totalPrice - extraDiscount - marketingCouponDiscount) : null
	const totalPriceWithAppliedPayments = totalPrice >= 0 ? roundPrice(resultTotalPrice - depositsSum - giftCouponSum) : null
	const disabledRestaurantDiscount = giftCoupons?.length && extraDiscount > 0
	const isTestCompany = useSelector(selectIsTestCompany)
	const disableInvoicePayment = !hasAdminRole && !isExistInvoicePaymentConfig
	const isStripePaymentBelowLimit = totalPriceWithAppliedPayments < STRIPE_MIN_ORDER_AMOUNT
	const disableTerminalPayment = !terminalsLength || isStripePaymentBelowLimit
	const isExistsAppliedPayments = giftCoupons?.length > 0 || deposits?.length > 0

	const isMobileApp = isMobileAppMode()
	const tap2PayTips = useSelector(store => store.tap2Pay.tap2PayTips)

	const handleResetAfterOpen = useCallback(() => {
		setCustomerDiscountType('CURRENCY')
		setCustomerDiscount(0)
		setPresetDiscountId(null)
		setPaymentMethod(null)
		dispatch(setCartPaymentGiftCoupons([]))
		dispatch(setFlyerCouponIsInvalid(false))
		dispatch(setFlyerCouponIsUsed(false))
		dispatch(setValidatedFlyerCoupon(null))
		dispatch(setCartPaymentDeposits([]))
	}, [dispatch])

	useEffect(() => {
		if (showPaymentTypeModal) {
			dispatch(getCartPaymentThunk('RESTAURANT', hasModalLogin))
			dispatch(getCartPaymentTerminalsThunk(0, PER_LOAD, hasModalLogin))
		}
	}, [showPaymentTypeModal]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal && hasRoleSettingsReadOrWrite) {
			dispatch(getCartPaymentInvoiceConfigsThunk())
		}
	}, [showPaymentTypeModal, hasRoleSettingsReadOrWrite]) // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {
		if (showPaymentTypeModal) {
			handleResetAfterOpen()
		}
	}, [showPaymentTypeModal]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal && extraDiscountOrderItem) {
			if (isExistPresetDiscountOrderItem) {
				setPresetDiscountId(extraDiscountOrderItem.itemId)
			} else {
				const extraDiscountPriceWithoutMinus = Math.abs(extraDiscountOrderItem.price)
				setCustomerDiscount(extraDiscountPriceWithoutMinus)
				setCustomerDiscountType('CURRENCY')
			}
		}
	}, [showPaymentTypeModal, extraDiscountOrderItem]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const isExistGiftCoupon = giftCoupons?.findIndex(item => item?.id === giftCoupon?.id)
		if (giftCoupon && isExistGiftCoupon === -1) {
			let allGiftCoupons = [...giftCoupons, { ...giftCoupon, cartBalance: 0 }]
			const giftCouponsWithBalance = calculateGiftCouponsBalance(allGiftCoupons, totalPrice - extraDiscount - marketingCouponDiscount)
			dispatch(setCartPaymentGiftCoupons(giftCouponsWithBalance))
			dispatch(setGiftCoupon(null))
		}
	}, [giftCoupon]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal && totalPriceWithAppliedPayments <= 0 && isExistsAppliedPayments) {
			handlePaymentType({ totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: resultTotalPrice, giftCoupons, deposits })
			handleClose()
		}
	}, [isExistsAppliedPayments, showPaymentTypeModal, totalPriceWithAppliedPayments, marketingCouponItem]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setRestaurantPaymentTypeModal(false))
	}, [dispatch])

	const openActivationModal = useCallback(() => {
		dispatch(setRestaurantCouponActivateModal(true))
	}, [dispatch])

	const handleInvoicePaymentClick = useCallback(() => {
		if (!isExistInvoicePaymentConfig) {
			dispatch(setRestaurantModalInvoiceConfigsIsOpen(true))
		} else {
			dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(true))
		}
	}, [isExistInvoicePaymentConfig, dispatch])

	const handleTap2PayPaymentClick = useCallback(() => {
		dispatch(setShowTap2PayAvailabilityCheckerModal(true))
	}, [dispatch])

	const handleDepositPaymentClick = useCallback((payment) => {
		setDepositPayment(convert2DepositPayment(payment))
		dispatch(setRestaurantShowDepositPaymentModal(true))
	}, [dispatch])

	const handlePaymentClick = useCallback((payment) => {
		if (payment?.paymentName) {
			handleDepositPaymentClick(payment)
		} else {
			const paymentMethod = payment.paymentMethod
			setPaymentMethod(paymentMethod)

			if (paymentMethod === 'EC_TERMINAL') {
				if (terminalsLength > 1) {
					dispatch(setRestaurantModalTerminalButtonsIsOpen(true))
				} else {
					const disabledTips = isDisabledTerminalTips(terminals[0], 'RESTAURANT')
					dispatch(setCartPaymentSelectedTerminal(terminals[0]))
					if (disabledTips) {
						dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(true))
					} else {
						dispatch(setRestaurantModalChangeCalculatorIsOpen(true))
					}
				}
			} else if (paymentMethod === 'CASH' && modalChangeCalculatorIsNeeded) {
				dispatch(setRestaurantModalChangeTipsCalculatorIsOpen(true))
			} else if (paymentMethod === 'INVOICE') {
				handleInvoicePaymentClick()
			} else if (paymentMethod === 'TAP_TO_PAY') {
				handleTap2PayPaymentClick()
			} else {
				handlePaymentType({ paymentMethod, totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: resultTotalPrice, giftCoupons, deposits })
			}
			handleClose()
		}
	}, [terminalsLength, dispatch, handleClose, giftCoupons, handlePaymentType, modalChangeCalculatorIsNeeded, resultTotalPrice, terminals, totalDiscount, extraDiscountItem, marketingCouponItem, handleInvoicePaymentClick, handleTap2PayPaymentClick, deposits, handleDepositPaymentClick])

	const handleModalChangePayment = useCallback(({ differenceSum }) => {
		dispatch(setRestaurantModalChangeCalculatorIsOpen(false))
		if (paymentMethod === 'EC_TERMINAL') {
			setEcTerminalTips(differenceSum)
			dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(true))
		} else {
			handlePaymentType({ paymentMethod: 'CASH', totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: resultTotalPrice, giftCoupons, deposits })
		}
	}, [paymentMethod, handlePaymentType, totalDiscount, resultTotalPrice, giftCoupons, dispatch, extraDiscountItem, marketingCouponItem, deposits])

	const handleModalChangeTipsPayment = useCallback(({ tips }) => {
		const totalPriceWithTips = roundPrice(resultTotalPrice + tips)
		dispatch(setRestaurantModalChangeTipsCalculatorIsOpen(false))
		handlePaymentType({ paymentMethod: 'CASH', totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: totalPriceWithTips, giftCoupons, tips, deposits })
	}, [handlePaymentType, dispatch, extraDiscountItem, marketingCouponItem, giftCoupons, resultTotalPrice, totalDiscount, deposits])

	const handleDiscountChange = useCallback((e) => {
		const value = e.target.value
		setCustomerDiscount(value.replace('.', ','))
	}, [])

	const handleDiscountTypeChange = useCallback((e) => {
		const value = e.target.value
		setCustomerDiscountType(value)
	}, [])

	const couponPayment = useCallback(() => {
		setPaymentMethod('GIFT_COUPON')
		openActivationModal()
	}, [openActivationModal])

	const disablePaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'EC_TERMINAL':
				return disableTerminalPayment
			case 'INVOICE':
				return disableInvoicePayment
			case 'QR_CODE':
				return true
			case 'TAP_TO_PAY':
				return isStripePaymentBelowLimit
			default:
				return false
		}
	}, [disableInvoicePayment, disableTerminalPayment, isStripePaymentBelowLimit])

	const showPaymentButton = useCallback((payment) => {
		switch (payment.paymentMethod) {
			case 'INVOICE':
				return !updatePaymentMode
			case 'QR_CODE':
				return isTestCompany && !updatePaymentMode
			case 'TAP_TO_PAY':
				return isMobileApp && payment.enabled && (updatePaymentMode ? isTestCompany : true)
			default:
				return true
		}
	}, [updatePaymentMode, isMobileApp, isTestCompany])

	const paymentButtonMessage = useCallback((paymentMethod) => {
		const isEcTerminal = paymentMethod === 'EC_TERMINAL'
		const isTap2Pay = paymentMethod === 'TAP_TO_PAY'
		const isInvoice = paymentMethod === 'INVOICE'
		if (isInvoice && disableInvoicePayment) {
			return { text: t('Modal.payment.invoice_disable_message') }
		} else if ((isEcTerminal || isTap2Pay) && isStripePaymentBelowLimit) {
			return { text: t('Modal.payment.terminal_disable_message', { sum: formattedEcTerminalLimitSum }) }
		} else {
			return null
		}
	}, [disableInvoicePayment, isStripePaymentBelowLimit, t, formattedEcTerminalLimitSum])

	const getPaymentButtonText = useCallback((payment) => {
		const paymentMethod = payment?.paymentMethod
		const isQrCode = paymentMethod === 'QR_CODE'

		if (isQrCode) {
			return <QrCodeButtonText text={t(`Payments.payments.${paymentMethod}`)} />
		} else if (payment?.paymentName) {
			return payment.paymentName
		} else {
			return t(`Payments.payments.${paymentMethod}`)
		}
	}, [t])

	const buttons = useMemo(() => {
		const paymentButtons = payments.map((payment) => {
				return ({
					icon: payment?.paymentName ? 'other-payment' : payment?.paymentMethod,
					text: getPaymentButtonText(payment),
					onClick: !customerDiscountHasError ? () => handlePaymentClick(payment) : () => {
					},
					show: showPaymentButton(payment),
					disabled: payment?.disabledForPay || disablePaymentButton(payment.paymentMethod),
					message: paymentButtonMessage(payment.paymentMethod),
				})
			},
		)
		const tapToPayButton = paymentButtons.find(button => button.icon === 'TAP_TO_PAY')
		const giftCouponButton = {
			icon: 'GIFT_COUPON', text: t('restaurant.tables.modal.coupon'),
			onClick: !customerDiscountHasError ? () => couponPayment() : () => {
			}, show: true, disabled: false,
		}
		const filteredPaymentButtons = paymentButtons.filter(button => button.icon !== 'TAP_TO_PAY')

		if (tapToPayButton) {
			return hasNotGiftCouponInTable ? [tapToPayButton, giftCouponButton, ...filteredPaymentButtons] : [tapToPayButton, ...filteredPaymentButtons]
		} else {
			return hasNotGiftCouponInTable ? [giftCouponButton, ...filteredPaymentButtons] : [...filteredPaymentButtons]
		}

	}, [payments, handlePaymentClick, customerDiscountHasError, couponPayment, hasNotGiftCouponInTable, t, disablePaymentButton, showPaymentButton, paymentButtonMessage, getPaymentButtonText])

	const handleTerminalPayment = useCallback((paymentId, tipsFromTerminal) => {
		const totalPriceWithTips = roundPrice(resultTotalPrice + ecTerminalTips + tipsFromTerminal)
		handlePaymentType({ paymentId, paymentMethod: 'EC_TERMINAL', tips: ecTerminalTips + tipsFromTerminal, totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: totalPriceWithTips, giftCoupons, deposits })
	}, [handlePaymentType, ecTerminalTips, resultTotalPrice, totalDiscount, giftCoupons, extraDiscountItem, marketingCouponItem, deposits])

	const handleInvoicePayment = useCallback((customer) => {
		dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(false))
		handlePaymentType({ paymentMethod: 'INVOICE', totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: resultTotalPrice, giftCoupons, customer, deposits })
	}, [handlePaymentType, resultTotalPrice, totalDiscount, giftCoupons, extraDiscountItem, marketingCouponItem, dispatch, deposits])

	const handleTap2PayPayment = useCallback((paymentId) => {
		const totalPriceWithTips = roundPrice(resultTotalPrice + tap2PayTips)
		handlePaymentType({ paymentId, paymentMethod: 'TAP_TO_PAY', tips: tap2PayTips, totalDiscount, extraDiscountItem, marketingCouponItem, totalPrice: totalPriceWithTips, giftCoupons, deposits })
	}, [tap2PayTips, resultTotalPrice, totalDiscount, extraDiscountItem, marketingCouponItem, giftCoupons, handlePaymentType, deposits])

	const handleDepositPayment = useCallback((amount) => {
		const depositsToUpdate = deposits.some(deposit => deposit.id === depositPayment.id) ?
			deposits.map(item => item.id === depositPayment.id ? ({ ...item, total: roundPrice(item.total + amount) }) : item) :
			[...deposits, { ...depositPayment, total: amount }]

		dispatch(setCartPaymentDeposits(depositsToUpdate))
		setDepositPayment(null)
	}, [dispatch, deposits, depositPayment])

	const isButtonsLimit = buttons.length > PAYMENT_BUTTONS_LIMIT
	const modalLayout = isButtonsLimit ? 'desk-cols-2' : ''

	return (
		<>
			<ButtonsModal isOpen={showPaymentTypeModal}
										close={handleClose}
										title={title}
										id="restaurant-payment-modal"
										size={isButtonsLimit ? 'medium' : 'small'}
										autoFocus={false}
										zIndex={EVENT_Z_INDEX}
										buttons={buttons}
										mobileIndentIsNeeded={true}
										className={modalLayout}>
				<PaymentHeader className={modalLayout}>
					{isExistsAppliedPayments ?
						<PaymentTitleWrapper className="with-gift-coupon">
							<PaymentTitle>{t('Cart.leftToPay')}: </PaymentTitle>
							<PaymentCost><Currency>{totalPriceWithAppliedPayments}</Currency></PaymentCost>
							<PaymentTitle>({t('Cart.total')}: <Currency>{resultTotalPrice}</Currency>)</PaymentTitle>
						</PaymentTitleWrapper> :
						<PaymentTitleWrapper>
							<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
							<PaymentCost><Currency>{totalPriceWithAppliedPayments}</Currency></PaymentCost>
						</PaymentTitleWrapper>}
				</PaymentHeader>
				{hasNotGiftCouponInTable &&
					<RestaurantDiscount {...{
						titlePrice: totalPrice, customerDiscount, setCustomerDiscount, customerDiscountType, handleDiscountChange, handleDiscountTypeChange, setCustomerDiscountHasError,
						presetDiscountId, setPresetDiscountId, extraDiscountOrderItem, showPaymentTypeModal, disabled: disabledRestaurantDiscount, updatePaymentMode, modalLayout,
					}} />}
			</ButtonsModal>

			<PaymentModals {...{ totalPrice: totalPriceWithAppliedPayments, ecTerminalTips, handleTerminalPayment, handleModalChangePayment, handleModalChangeTipsPayment, handleInvoicePayment, paymentMethod, resetTable, updatePaymentMode, handleTap2PayPayment, handleDepositPayment }} />
		</>
	)
}

ModalPaymentType.displayName = 'ModalPaymentType'
export default ModalPaymentType
