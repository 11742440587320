import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const TooltipWrapper = styled.div`
	position: relative;
	border: none;
	background: inherit;
	font-size: inherit;
	color: inherit;
	cursor: inherit;
	display: flex;

	${({ showOnFocus }) =>
		!showOnFocus &&
		css`
			outline: none;
		`};

`
TooltipWrapper.displayName = 'TooltipWrapper'

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

export const CenterContainer = styled.div`
	position: absolute;
	width: ${props => props.width ? props.width : '100%'};
	display: flex;
	justify-content: center;
	align-items: center;
	right: 50%;
	transform: translateX(50%);
	bottom: calc(100% + ${props => props.margin || '10px'});
	animation: ${fadeIn} 0.2s linear;
	z-index: 10;

	pointer-events: none;
	${({ position }) => {
		switch (position) {
			case 'bottom':
				return css`
					bottom: unset !important;
					top: calc(100% + ${props => +props.margin || '10px'});
				`
			case 'left':
				return css`
					margin-right: 0;
					left: unset;
					top: 50%;
					right: calc(100% + ${props => props.margin || '10px'});
				`
			case 'right':
				return css`
					margin-left: 0;
					top: 50%;
					left: calc(100% + ${props => props.margin || '10px'});
					transform: translateX(0);
				`
			default:
				return css`
				`
		}
	}}

	@media screen and (max-width: ${theme.point820}) {
		width: ${props => props.mobWidth ? props.mobWidth : '100%'};
	}
`

CenterContainer.displayName = 'CenterContainer'


export const TooltipBox = styled.span`
	position: relative;
	background-color: ${colorTheme.tooltip.background};
	border: 0.5px solid ${colorTheme.tooltip.border};
	opacity: 0.9;
	font-size: 1rem;
	padding: 0.5em;
	border-radius: 0.5em;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.tooltip.text};
	text-align: center;

	&.outline {
		border: 1px solid ${colorTheme.tooltip.outlineBorder};
	}

	${({ position }) => {
		switch (position) {
			case 'right':
				return css`
					color: ${colorTheme.tooltip.text};
				`
			default:
				return css``
		}
	}}

	&:after {
		content: "";
		position: absolute;
		width: 1px;
		height: 1px;
		border-width: 8px;
		border-style: solid;
		border-color: ${colorTheme.tooltip.border} transparent transparent transparent;
		left: calc(50% - 8px);
		top: 100%;

		&.outline {
			border-color: ${colorTheme.tooltip.outlineBorder} transparent transparent transparent;
		}
	}

	${({ position }) => {
		switch (position) {
			case 'bottom':
				return css`
					&:after {
						border-color: transparent transparent ${colorTheme.tooltip.border} transparent;
						top: unset;
						width: 1px;
						bottom: 100%;
						left: calc(50% - 8px);
					}
				`
			case 'left':
				return css`
					&:after {
						border-color: transparent transparent transparent ${colorTheme.tooltip.border};
						left: 100%;
						top: calc(50% - 8px);
					}
				`
			case 'right':
				return css`
					&:after {
						border-color: transparent ${colorTheme.tooltip.border} transparent transparent;
						right: 100%;
						left: unset;
						top: calc(50% - 8px);
					}
				`
			default:
				return css``
		}
	}}
`
TooltipBox.displayName = 'TooltipBox'
