import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import { setTseConfig, setTseFonData, setTseFonError, setTseNotification, setTseNullOrders, updateTseDisableNotification } from '../../action/tse'
import getTseFonData from '../../axios/tse/fon/get'
import putTseFonData from '../../axios/tse/fon/put'
import getTseConfig from '../../axios/tse/get'
import getTseNotification from '../../axios/tse/notification/get'
import updateTseNotification from '../../axios/tse/notification/put'
import getTseNullOrders from '../../axios/tse/nullOrders/get'
import createTseNullOrder from '../../axios/tse/nullOrders/post'
import printYearOrder from '../../axios/tse/nullOrders/yearsOrderPrint/post'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'

export const getTseConfigThunk = () => dispatch => {
	return getTseConfig()
		.then((res) => {
			let data = res.data
			dispatch(setTseConfig(Array.isArray(data) ? data[0] : data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getTseNotificationThunk = () => dispatch => {
	return getTseNotification()
		.then((res) => {
			dispatch(setTseNotification(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateTseDisableNotificationThunk = (data) => dispatch => {
	return updateTseNotification(data)
		.then(() => {
			dispatch(updateTseDisableNotification(data.disableNotification))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getTseFonDataThunk = () => dispatch => {
	return getTseFonData()
		.then(res => {
			dispatch(setTseFonData(res.data))
		}).catch(() => {
			dispatch(setTseFonData(null))
		})
}

export const updateTseFonDataThunk = (data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	dispatch(setTseFonError({ invalidFonData: false, invalidVatId: false }))
	return putTseFonData(data)
		.then(res => {
			dispatch(succeedSystemFormButtonSaveThunk())
			dispatch(setTseFonData(res.data))
			return dispatch(getTseConfigThunk())
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			const errorStatusCode = error.response.data?.apierror.errorStatusCode
			if (errorStatusCode === 'invalid_fon_data') {
				dispatch(setTseFonError({ invalidFonData: true }))
			} else if (errorStatusCode === 'invalid_vat_id') {
				dispatch(setTseFonError({ invalidVatId: true }))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const getTseNullOrdersThunk = () => dispatch => {
	return getTseNullOrders()
		.then(res => {
			dispatch(setTseNullOrders(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createTseNullOrderThunk = (date) => dispatch => {
	return createTseNullOrder(date)
		.then(() => {
			return dispatch(getTseNullOrdersThunk())
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const printYearOrderThunk = (year) => dispatch => {
	return printYearOrder(year)
		.then(() => {
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
