export const getRestTableData = ({
	isSettingsArea, isSettingsForm, isReadyFood, isQRCode,
	isExistPaidBeforeOrders, paidBeforeOrdersMoreThanOne, paidBeforeOrdersAndOrders,
	totalPrice, processedBy, t,
}) => {
	const waiterClass = processedBy ? 'waiter' : ''

	if (isSettingsArea) {
		return { text: null, price: null, icons: ['restaurant_settings'], waiterName: null, className: 'restaurant_settings' }
	} else if (isSettingsForm) {
		return { text: '0.00€', price: null, icons: [], waiterName: 'I. Wilhelm', className: '' }
	} else if (isReadyFood) {
		return { text: t('restaurant.readyFood'), price: null, icons: [], waiterName: processedBy, className: 'readyFood' }
	} else if (isQRCode) {
		return { text: null, price: null, icons: ['qr-code'], waiterName: null, className: 'qr-code' }
	} else if (isExistPaidBeforeOrders) {
		const icons = (paidBeforeOrdersAndOrders || paidBeforeOrdersMoreThanOne) ? ['cut-check', 'cut-check'] : []
		const text = paidBeforeOrdersAndOrders ? null : t('restaurant.paid')
		const price = paidBeforeOrdersAndOrders ? totalPrice : null
		const baseClass = `paid-before paid-before-with-icon-and-${waiterClass}`
		const className = icons.length ? baseClass : 'paid-before'
		return { text, price, icons, waiterName: processedBy, className }
	} else {
		return { text: null, price: totalPrice, icons: [], waiterName: processedBy, className: '' }
	}
}
