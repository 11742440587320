import React from 'react'
import { ReactComponent as SplitSvg } from './assets/split.svg'
import { ReactComponent as TableMobileSvg } from './assets/table-mobile.svg'

const RestaurantIcon = React.memo(({ icon, height = 20, width = 20, fill, stroke }) => {
	const svgProps = { height, width, fill, stroke, className: icon }

	return (
		<>
			{icon === 'split' && <SplitSvg {...svgProps} />}
			{icon === 'table-mobile' && <TableMobileSvg {...svgProps} />}
		</>
	)
})

RestaurantIcon.displayName = 'RestaurantIcon'
export default RestaurantIcon
