import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const InputWrapper = styled.div`
	font-size: 1rem;
	position: relative;
	height: max-content;
	width: 100%;

	&.error, &.error-big {
		background-color: ${colorTheme.input.error.background};
		border-radius: 0.75em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.error, &.error-big {
			border-radius: 0.86em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.error, &.error-big {
			border-radius: 0.75em;
		}
	}
`
InputWrapper.displayName = 'InputWrapper'

export const InputLabel = styled.label`
	display: grid;
	height: 4.19em;
	padding: .93em 1.31em;
	background: ${colorTheme.input.background};
	border-radius: 0.75em;
	cursor: pointer;
	border: 1px solid transparent;
	box-sizing: border-box;

	&.numberWithoutArrow {
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type=number] {
			-moz-appearance: textfield;
		}
	}

	&.small {
		height: 3.44em;
		padding: 0.56em 1.25em;
		border: 1px solid ${colorTheme.input.small.border};
	}

	&.purple {
		border: 2px solid ${colorTheme.input.purple.border};
	}

	&.gray {
		border: 1px solid ${colorTheme.input.gray.border};
	}

	&.blue {
		border: 1px solid ${colorTheme.input.blue.border};
	}

	&.focus {
		border-color: ${colorTheme.input.focus.border};
		box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
	}

	&.error,&.error-big {
		border: 1px solid ${colorTheme.input.error.border};
	}

	&.disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}

	&.x-big {
		height: 5.13em;
	}

	@media screen and (max-width: ${theme.point820}) {
		height: 3.93em;
		padding: .65em 1.5em;
		border-radius: 0.86em;

		&.small {
			height: 3.93em;
			padding: .65em 1.5em;
		}

		&.big {
			height: 4.78em;
			padding: 1.07em 1.5em;
		}

		&.x-big {
			height: 3.93em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 3.44em;
		padding: .56em 1.31em;
		border-radius: 0.75em;

		&.small {
			height: 3.44em;
			padding: .56em 1.31em;
		}

		&.big {
			height: 4.19em;
			padding: .94em 1.31em;
		}

		&.x-big {
			height: 3.44em;
			padding: 0.38em 1.31em;
		}
	}
`
InputLabel.displayName = 'InputLabel'

export const InputLabelText = styled.div`
	white-space: nowrap;
	font-size: 0.88em;
	color: ${colorTheme.input.text};
	text-transform: uppercase;
	line-height: 1.14em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	transition: all 0.2s;
	text-align: left;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	&.toggled {
		transform: translateY(0.45em);
		font-size: 1.13em;
		line-height: 1.16em;
	}

	&.focus {
		color: ${colorTheme.input.focus.text};
	}

	&.error, &.error-big {
		color: ${colorTheme.input.error.text};
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1em;

		&.toggled {
			font-size: 1.28em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.88em;
		line-height: 1.14em;

		&.toggled {
			font-size: 1.13em;
		}
	}
`
InputLabelText.displayName = 'InputLabelText'

export const InputField = styled.input`
	font-size: 1.13em;
	background: transparent;
	width: 100%;
	height: 1.17em;
	border: none;
	border-radius: 0;
	outline: none;
	font-weight: 700;
	font-family: ${theme.fontRobotoBold};
	color: ${colorTheme.input.field};
	text-align: left !important;

	&.isIcon {
		padding-right: 2.2em;
	}

	&.small {
		font-family: ${theme.fontRobotoMedium};
		font-weight: 500;
	}

	&.x-big {
		font-size: 2.25em;
		font-weight: 600;
	}

	&.text-right {
		text-align: right !important;
	}

	&.text-bold {
		font-family: ${theme.fontRobotoBold};
		font-weight: 700;
	}

	&.text-size-28 {
		font-size: 1.75em;
	}

	&.password {
		-webkit-text-security: disc; // convert text to dot
	}

	&.disabled {
		color: ${colorTheme.input.fieldDisabled};
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.28em;

		&.x-big {
			font-size: 2.57em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;

		&.x-big {
			font-size: 2.25em;
		}
	}
`
InputField.displayName = 'InputField'

export const InputError = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88em;
	right: 0;
	top: 0;
	padding: 0 1.43em;
	width: 100%;
	color: ${colorTheme.input.error.title};
	height: 0;
	max-height: 0;
	transition: all .2s;
	text-align: left;

	&.error {
		height: 2em;
		max-height: 2em;
		padding-top: 0.5em;
	}

	&.error-big {
		height: 100%;
		max-height: 4em;
		padding-top: 0.5em;
	}

	&.error-big-text {
		height: max-content;
		max-height: max-content;
		padding-bottom: 0.4em;
		line-height: 100%;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1em;
		padding: 0 1.61em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.88em;
		padding: 0 1.43em;
	}
`
InputError.displayName = 'InputError'

export const InputImg = styled.div`
	font-size: 1em;
	position: absolute;
	top: calc(50% - 0.6em);
	right: 1.25em;
	width: 1.38em;
	height: 1.19em;
	transition: top 0.2s;

	img {
		width: 100%;
		height: 100%;
	}

	&.error, &.error-big {
		top: calc(50% + 0.2em);
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 1.58em;
		height: 1.36em;
	}

	@media screen and (max-width: ${theme.point720}) {
		top: calc(50% - 0.6em);
		width: 1.38em;
		height: 1.19em;
	}
`
InputImg.displayName = 'InputImg'

export const ChildrenWrapper = styled.div`
	position: absolute;
	right: 1.31em;
	top: 50%;
	z-index: 1;
	transform: translate(0, -50%);

	&.small {
		right: 0.63em;
	}
`
