import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import { selectContainsAnyRole, selectIsEnd2EndTestCompany, selectIsNeededZIndex, selectUsername } from '../../../redux/selector/system'
import { useNavigate } from 'react-router-dom'
import { NotificationModalBody, NotificationModalBtnWrap, NotificationModalCloseBlock, NotificationModalCloseKey, NotificationModalContent, NotificationModalHeaderLeft, NotificationModalHeaderLeftText, NotificationModalInfo, NotificationModalMain, NotificationModalPhoneImg, NotificationModalText, NotificationModalTitle, NotificationModalWrapper } from './index.styled'
import { setAppEventZIndex } from '../../../redux/action/system'
import KeyBindings from '../../Elements/keybindings'
import phone_tap2pay from '../../../img/svg/header/phone_tap2pay.webp'
import NotificationModalBtn from './NotificationModalBtn'
import NotificationModalInfoMessage from './NotificationModalInfo'
import { setTap2PayNotificationModalShow } from '../../../redux/action/payment'

const EVENT_Z_INDEX = 64

const TapToPayNotificationModal = React.memo(() => {
	const { t } = useTranslation()
	const tap2PayNotificationModalShow = useSelector(state => state.payment.tap2PayNotificationModalShow)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const username = useSelector(selectUsername)
	const hasRoleServiceWrite = useSelector(selectContainsAnyRole(['PROGRAM_SETTINGS_WRITE']))
	const [openModal, setOpenModal] = useState(false)

	const localStorageModalDisabled = JSON.parse(window.localStorage.getItem('tapToPayInfoModalDisable'))
	const localStorageModalEnabled = !localStorageModalDisabled?.[username]
	const shouldShowModal = tap2PayNotificationModalShow && localStorageModalEnabled
	const isTablet = useWindowBreakPoint(theme.point820)
	const isEnd2EndTestCompany = useSelector(selectIsEnd2EndTestCompany)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		if (shouldShowModal && !isEnd2EndTestCompany) {
			setOpenModal(true)
		} else {
			setOpenModal(false)
		}
	}, [shouldShowModal]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (openModal) {
			dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		}
		return () => dispatch(setAppEventZIndex(0))
	}, [openModal]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleCloseModal = useCallback(() => {
		setOpenModal(false)
		window.localStorage.setItem('tapToPayInfoModalDisable', JSON.stringify({ ...localStorageModalDisabled, [username]: true }))
		dispatch(setTap2PayNotificationModalShow(false))
	}, [dispatch, localStorageModalDisabled, username])

	const handleModalWrapperMouseDown = useCallback(() => {
		handleCloseModal()
	}, [handleCloseModal])

	const handleModalMainMouseDown = useCallback(e => {
		e.stopPropagation()
	}, [])

	const navigateToSettings = useCallback(() => {
		navigate('/settings/payments')
		handleCloseModal()
	}, [navigate, handleCloseModal])

	return (
		<>
			<NotificationModalWrapper onMouseDown={handleModalWrapperMouseDown}
																className={openModal ? 'active' : ''}>
				<NotificationModalMain onMouseDown={handleModalMainMouseDown}>
					<NotificationModalHeaderLeft>
						<NotificationModalHeaderLeftText>{t('tap2PayNotificationModal.new')}</NotificationModalHeaderLeftText>
						{isTablet &&
							<NotificationModalCloseBlock onClick={handleCloseModal} className="btn-close-modal mobile" data-testid="close">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.6 0.599219C1.3 -0.100781 2.5 -0.100781 3.3 0.599219L9.6 6.89922L15.9 0.599219C16.6 -0.100781 17.8 -0.100781 18.6 0.599219C19.3 1.29922 19.3 2.49922 18.6 3.29922L12.3 9.59922L18.6 15.8992C19.3 16.5992 19.3 17.7992 18.6 18.5992C18.2 18.8992 17.8 19.0992 17.3 19.0992C16.8 19.0992 16.3 18.8992 16 18.4992L9.7 12.1992L3.4 18.4992C2.9 18.7992 2.4 18.9992 1.9 18.9992C1.4 18.9992 0.9 18.7992 0.6 18.3992C-0.1 17.6992 -0.1 16.4992 0.6 15.6992L6.9 9.39922L0.6 3.19922C-0.2 2.49922 -0.2 1.29922 0.6 0.599219Z" fill="#FFFFFF" />
								</svg>
							</NotificationModalCloseBlock>}
					</NotificationModalHeaderLeft>
					<NotificationModalContent>
						<NotificationModalCloseBlock onClick={handleCloseModal}>
							<NotificationModalCloseKey>ESC</NotificationModalCloseKey>
							<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
								<path d="M19.7301 17.4001L33.1401 3.93006C33.8101 3.26006 33.8101 2.17006 33.1401 1.50006C32.4701 0.830059 31.3901 0.830059 30.7301 1.50006L17.3201 14.9801L3.91006 1.50006C3.24006 0.830059 2.16006 0.830059 1.50006 1.50006C0.830059 2.17006 0.830059 3.26006 1.50006 3.93006L14.9001 17.4001L1.50006 30.8801C0.830059 31.5501 0.830059 32.6401 1.50006 33.3101C1.83006 33.6401 2.27006 33.8101 2.71006 33.8101C3.15006 33.8101 3.58006 33.6401 3.92006 33.3101L17.3201 19.8401L30.7201 33.3001C31.0501 33.6301 31.4901 33.8001 31.9301 33.8001C32.3701 33.8001 32.8001 33.6301 33.1401 33.3001C33.8101 32.6301 33.8101 31.5401 33.1401 30.8701L19.7301 17.4001Z" />
							</svg>
						</NotificationModalCloseBlock>
						<NotificationModalBody>
							<NotificationModalInfo>
								<NotificationModalTitle>{t('tap2PayNotificationModal.title')}</NotificationModalTitle>
								<NotificationModalText>
									<div>{t('tap2PayNotificationModal.text1')}</div>
									<div>{t('tap2PayNotificationModal.text2')}</div>
								</NotificationModalText>
								{hasRoleServiceWrite && !isTablet && <NotificationModalBtnWrap>
									<NotificationModalBtn text={t('tap2PayNotificationModal.button')} onClick={navigateToSettings} />
								</NotificationModalBtnWrap>}
								{!hasRoleServiceWrite && <NotificationModalInfoMessage text={t('tap2PayNotificationModal.message')} />}
							</NotificationModalInfo>
							<NotificationModalPhoneImg>
								<img src={phone_tap2pay} alt="phone" />
							</NotificationModalPhoneImg>
							{hasRoleServiceWrite && isTablet && <NotificationModalBtnWrap>
								<NotificationModalBtn text={t('tap2PayNotificationModal.button')} onClick={navigateToSettings} />
							</NotificationModalBtnWrap>}
						</NotificationModalBody>
					</NotificationModalContent>
				</NotificationModalMain>
			</NotificationModalWrapper>

			{isNeededZIndex &&
				<KeyBindings escape={handleCloseModal} />}
		</>
	)
})

TapToPayNotificationModal.displayName = 'TapToPayNotificationModal'
export default TapToPayNotificationModal
