import React from 'react'
import { UnpaidInvoice, UnpaidInvoiceSkeletonBlock, UnpaidInvoiceTextWrap } from '../index.styled'

const UnpaidInvoiceSkeleton = React.memo(() => {

	return (
		<UnpaidInvoice data-testid="invoice-skeleton">
			<UnpaidInvoiceTextWrap>
				<UnpaidInvoiceSkeletonBlock className="small" />
				<UnpaidInvoiceSkeletonBlock className="middle" />
			</UnpaidInvoiceTextWrap>
			<UnpaidInvoiceSkeletonBlock />
		</UnpaidInvoice>
	)
})

UnpaidInvoiceSkeleton.displayName = 'UnpaidInvoiceSkeleton'
export default UnpaidInvoiceSkeleton
