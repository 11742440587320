import { createSelector } from 'reselect'

export const selectCustomerGeoData = store => store.customerStore.geoData

export const selectCustomerDistance = createSelector(
	selectCustomerGeoData,
	(geoData) => {
		return (geoData?.distance || 0) / 1000.0
	},
)
