export const SET_KITCHEN_ORDERS = 'SET_KITCHEN_ORDERS'
export const SET_KITCHEN_ORDERS_TOTAL_LENGTH = 'SET_KITCHEN_ORDERS_TOTAL_LENGTH'
export const SET_KITCHEN_SELECTED_ORDER = 'SET_KITCHEN_SELECTED_ORDER'
export const ADD_NEW_KITCHEN_ORDER = 'ADD_NEW_KITCHEN_ORDER'
export const UPDATE_KITCHEN_ORDER = 'UPDATE_KITCHEN_ORDER'
export const REMOVE_KITCHEN_ORDER = 'REMOVE_KITCHEN_ORDER'
export const RE_INITIALIZE_KITCHEN_ORDERS = 'RE_INITIALIZE_KITCHEN_ORDERS'
export const SET_KITCHEN_MONITOR_CONFIG = 'SET_KITCHEN_MONITOR_CONFIG'
export const SET_KITCHEN_MONITOR_CONFIG_IS_LOADING = 'SET_KITCHEN_MONITOR_CONFIG_IS_LOADING'
export const SET_KITCHEN_MONITOR_CONFIGS = 'SET_KITCHEN_MONITOR_CONFIGS'
export const SET_KITCHEN_CONFIG = 'SET_KITCHEN_CONFIG'
export const SET_KITCHEN_ORDERS_LOADING = 'SET_KITCHEN_ORDERS_LOADING'
export const SET_KITCHEN_MONITOR_CONFIGS_UPDATE = 'SET_KITCHEN_MONITOR_CONFIGS_UPDATE'
export const SET_KITCHEN_ORDERS_IS_LOADING = 'SET_KITCHEN_ORDERS_IS_LOADING'
export const SET_KITCHEN_MONITOR_CONFIG_IS_SKELETON = 'SET_KITCHEN_MONITOR_CONFIG_IS_SKELETON'
export const SET_SHOW_KITCHEN_MONITOR_SKELETON = 'SET_SHOW_KITCHEN_MONITOR_SKELETON'
