import { APPEND_TERMINALS, SET_PAYMENT_ACTIVE_TAB, SET_PAYMENT_CONFIGS, SET_ADDITIONAL_PAYMENT_CONFIGS, SET_PAYMENT_INVOICE_CONFIGS, SET_PAYMENT_IS_LOADING, SET_PAYMENT_PAYPAL_ACCOUNT, SET_PAYMENT_PAYPAL_ACCOUNT_NOT_FOUND, SET_PAYMENT_PAYPAL_IS_LOADING, SET_PAYMENT_STRIPE_ACCOUNT, SET_PAYMENT_STRIPE_ACCOUNT_NOT_FOUND, SET_PAYMENT_STRIPE_IS_LOADING, SET_PAYMENT_TEST_STATUS, SET_PAYPAL_NOTIFICATION, SET_STRIPE_NOTIFICATION, SET_TAP2PAY_NOTIFICATION_MODAL_SHOW, SET_TERMINAL, SET_TERMINAL_ERROR_IS_INVALID, SET_TERMINAL_ID, SET_TERMINALS, SET_TERMINALS_LENGTH } from '../../actionTypes/payment'

export const setPaymentConfigs = value => ({ type: SET_PAYMENT_CONFIGS, value })

export const setPaymentIsLoading = value => ({ type: SET_PAYMENT_IS_LOADING, value })

export const setPaymentTestStatus = value => ({ type: SET_PAYMENT_TEST_STATUS, value })

export const setPaymentStripeAccount = value => ({ type: SET_PAYMENT_STRIPE_ACCOUNT, value })

export const setPaymentStripeAccountNotFound = value => ({ type: SET_PAYMENT_STRIPE_ACCOUNT_NOT_FOUND, value })

export const setPaymentStripeIsLoading = value => ({ type: SET_PAYMENT_STRIPE_IS_LOADING, value })

export const setPaymentActiveTab = value => ({ type: SET_PAYMENT_ACTIVE_TAB, value })

export const setTerminal = value => ({ type: SET_TERMINAL, value })

export const setTerminals = value => ({ type: SET_TERMINALS, value })

export const appendTerminals = value => ({ type: APPEND_TERMINALS, value })

export const setTerminalsLength = value => ({ type: SET_TERMINALS_LENGTH, value })

export const setTerminalId = value => ({ type: SET_TERMINAL_ID, value })

export const setTerminalErrorIsInvalid = value => ({ type: SET_TERMINAL_ERROR_IS_INVALID, value })

export const setPayPalNotification = value => ({ type: SET_PAYPAL_NOTIFICATION, value })

export const setPaymentPaypalAccount = value => ({ type: SET_PAYMENT_PAYPAL_ACCOUNT, value })

export const setPaymentPaypalAccountNotFound = value => ({ type: SET_PAYMENT_PAYPAL_ACCOUNT_NOT_FOUND, value })

export const setPaymentPaypalIsLoading = value => ({ type: SET_PAYMENT_PAYPAL_IS_LOADING, value })

export const setStripeNotification = value => ({ type: SET_STRIPE_NOTIFICATION, value })

export const setPaymentInvoiceConfigs = value => ({ type: SET_PAYMENT_INVOICE_CONFIGS, value })

export const setTap2PayNotificationModalShow = value => ({ type: SET_TAP2PAY_NOTIFICATION_MODAL_SHOW, value })

export const setAdditionalPaymentConfigs = value => ({ type: SET_ADDITIONAL_PAYMENT_CONFIGS, value })

