import { getDateParameterString, setWorkingHoursHelper } from './index'
import { fromLocalStorageValue } from './localStorage/fromLocalStorageValue'

export const orderFilter = (order, orderfilter) => {
	const orders = ordersFilter([order], orderfilter)
	return (orders && orders.length > 0) ? orders[0] : null
}

export const preOrderFilter = (order, orderfilter, type) => {
	const orders = preOrdersFilter([order], orderfilter, type)
	return (orders && orders.length > 0) ? orders[0] : null
}

export const kitchenOrderFilter = (kitchenMonitorConfigId, order, orderfilter, type) => {
	const currentKitchenMonitorConfigId = fromLocalStorageValue(window.localStorage.kitchenMonitorConfigId)
	const kitchenMonitorConfigIdFilterTrue = kitchenMonitorConfigId ? currentKitchenMonitorConfigId === kitchenMonitorConfigId : true
	const orders = kitchenOrdersFilter([order], orderfilter, type)
	return (kitchenMonitorConfigIdFilterTrue && orders && orders.length > 0) ? orders[0] : null
}

export const ordersFilter = (orders, orderfilter) => {
	const { date, paymentMethod, status, excludedStatus, orderType, processedByUsername, processedByUsernameWithEmpty, closed, withOnlyRegisteredPreOrders, isPaid } = orderfilter

	return orders
		.filter(order => dateFilter(order, date, date))
		.filter(order => paymentMethodFilter(order, paymentMethod))
		.filter(order => statusFilter(order, status))
		.filter(order => excludedStatusFilter(order, excludedStatus))
		.filter(order => orderTypeFilter(order, orderType))
		.filter(order => processedByUsernameFilter(order, processedByUsername))
		.filter(order => processedByUsernameWithEmptyFilter(order, processedByUsernameWithEmpty))
		.filter(order => closedFilter(order, closed))
		.filter(order => registeredPreordersFilter(order, withOnlyRegisteredPreOrders))
		.filter(order => unpaidOrdersFilter(order, isPaid))
}

export const preOrdersFilter = (orders, orderfilter, type) => {
	const { datePreOrder, datePreOrderDaysFrom, datePreOrderDaysTo, orderType, closed } = orderfilter
	const datePreOrderFrom = type === 'TODAY' ? datePreOrder : datePreOrderDaysFrom
	const datePreOrderTo = type === 'TODAY' ? datePreOrder : datePreOrderDaysTo
	return orders
		.filter(order => dateFilter(order, datePreOrderFrom, datePreOrderTo, true))
		.filter(order => orderTypeFilter(order, orderType))
		.filter(order => closedFilter(order, closed))
		.filter(order => isPreOrderFilter(order, true))
}

export const kitchenOrdersFilter = (orders, { date }) => {
	return orders
		.filter(order => dateFilter(order, date, date))
}

const dateFilter = (order, orderDateFrom, orderDateTo, isPreOrder) => {
	const date = isPreOrder ? order.preOrder : order.orderDate
	if (orderDateFrom && orderDateTo) {
		const dateFrom = new Date(orderDateFrom)
		dateFrom.setHours(5, 30, 0, 0)
		const dateTo = new Date(orderDateTo)
		dateTo.setDate(dateTo.getDate() + 1)
		dateTo.setHours(5, 29, 59, 0)
		const orderDate = new Date(date)
		return (dateFrom < orderDate) && (orderDate < dateTo)
	} else {
		return true
	}
}

const paymentMethodFilter = (order, paymentMethod) => {
	return !paymentMethod || (order.payments ?
		order.payments.find(payment => payment.method === paymentMethod) :
		order.paymentMethod === paymentMethod)
}

const statusFilter = (order, status) => {
	return !status || (order.status?.status === status)
		|| (Array.isArray(status) && status.includes(order.status?.status))
}

const excludedStatusFilter = (order, excludedStatus) => {
	return !excludedStatus || (!Array.isArray(excludedStatus) && order.status?.status !== excludedStatus)
		|| (Array.isArray(excludedStatus) && !excludedStatus.includes(order.status?.status))
}

const orderTypeFilter = (order, orderType) => {
	return !orderType || (order.orderType === orderType) || (orderType?.includes(order.orderType))
}

const processedByUsernameFilter = (order, processedByUsername) => {
	return !processedByUsername || (order.processedBy === processedByUsername)
}

const processedByUsernameWithEmptyFilter = (order, processedByUsernameWithEmpty) => {
	return !processedByUsernameWithEmpty || !order.processedBy
		|| (order.processedBy === processedByUsernameWithEmpty)
}

const closedFilter = (order, closed) => {
	return (typeof closed !== 'boolean') || (!closed && !order.zNumber) || (closed && order.zNumber)
}

const registeredPreordersFilter = (order, withOnlyRegisteredPreOrders) => {
	return (typeof withOnlyRegisteredPreOrders !== 'boolean') || (withOnlyRegisteredPreOrders && !order.preOrder) ||
		(withOnlyRegisteredPreOrders && order.preOrder && (order.status?.status !== 'NOT_REGISTERED' && order.status?.status !== 'NOT_ACCEPTED'))
}

const isPreOrderFilter = (order, isPreOrder) => {
	return isPreOrder && order.preOrder
}

const unpaidOrdersFilter = (order, isPaid) => {
	return (typeof isPaid !== 'boolean') || (!order?.payments?.filter(item => item?.method === order?.paymentMethod)[0]?.payed)
}

export const restaurantOrderFilter = (order) => {
	const date = getDateParameterString(setWorkingHoursHelper(new Date()))
	const isTodayOrder = dateFilter(order, date, date)
	const isRestaurantOrder = orderTypeFilter(order, 'RESTAURANT')
	const isNotClosed = !closedFilter(order, true)
	return isTodayOrder && isRestaurantOrder && isNotClosed
}
