import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const TableTd = styled.td`
	padding: 0.3em;
	text-align: left;
	border-top: 1px solid ${colorTheme.table.tableTd.border};
	border-bottom: 1px solid transparent;
	font-family: ${theme.fontRobotoRegular};
	font-size: 1em;
	color: ${colorTheme.table.tableTd.color};

	.street {
		display: flex;
		align-items: end;
		flex-wrap: wrap;
		font-size: 0.8em;
	}

	&:first-child {
		padding-left: 1.75em;
	}

	&:last-child {
		padding-right: 1em;
	}

	&.cart-setting {
		&:first-child {
			padding-left: 1em;
		}

		&:nth-child(2) {
			padding-right: 0.75em;
		}

		&:last-child {
			padding-left: 0.75em;
			padding-right: 0;
		}

	}

	&.disabled {
		.order-type, .delivery {
			background: ${colorTheme.table.tableTd.disabled.background};

			svg {
				fill: ${colorTheme.table.tableTd.disabled.icon} !important;
			}
		}

		.order-type-text, .delivery-text, .driver-time-text {
			color: ${colorTheme.table.tableTr.disabledNewTable.text};
		}
	}


	@media screen and (max-width: ${theme.point820}) {
		display: flex;
		grid-column: ${props => props.column || 'inherit'};
		grid-row: ${props => props.row};
		min-height: max-content;
		border: none !important;
		background-color: ${colorTheme.table.tableTd.background};
		border-radius: 0 !important;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}

		&:not(.const-color) {
			color: ${colorTheme.table.tableTd.color} !important;
		}

		.street {
			color: ${colorTheme.table.tableTd.color} !important;
		}

		&.number {
			grid-column: 3;
			grid-row: 1;
			width: 1.78em;
			height: 1.78em;
			min-height: 1.78em;
			border-radius: 50% !important;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid ${colorTheme.table.tableTd.border} !important;
			padding: 0 !important;
			position: absolute;
			top: 1.06em;
			right: 1.06em;
		}

		&.mobile-hidden {
			display: none;
		}

		&.category {
			font-size: 1.28em;
			grid-column: 1 / 5;
			display: flex;
			flex-direction: column;
			gap: 0.28em;
			padding: 0 0 0.78em !important;
			font-weight: 700;
			border-bottom: 1px dashed ${colorTheme.table.tableTr.mobileBorder} !important;
			margin-top: 0.78em;
		}

		&.cart-setting {
			&:first-child {
				padding-left: 0;
			}

			&:nth-child(2) {
				padding-right: 0.14em;
			}

			&:last-child {
				padding-left: 0.14em;
				padding-right: 0;
			}
		}

		&.tablet-hidden {
			display: none;
		}

		&.center {
			align-items: center;
			text-align: center;
		}

		&.start {
			align-items: start;
			text-align: center;
		}

		&.row-tablet-1 {
			grid-row: 1;
		}

		&.row-tablet-2 {
			grid-row: 2;
		}

		&.col-tablet-1-2 {
			grid-column: 1 / 2;
		}

		&.col-tablet-1-3 {
			grid-column: 1 / 3;
		}

		&.col-tablet-1-4 {
			grid-column: 1 / 4;
		}

		&.col-tablet-1-5 {
			grid-column: 1 / 5;
		}

		&.col-tablet-1-7 {
			grid-column: 1 / 7;
		}

		&.col-tablet-2-3 {
			grid-column: 2 / 3;
		}

		&.col-tablet-2-4 {
			grid-column: 2 / 4;
		}

		&.col-tablet-2-5 {
			grid-column: 2 / 5;
		}

		&.col-tablet-2-6 {
			grid-column: 2 / 6;
		}

		&.col-tablet-3-4 {
			grid-column: 3 / 4;
		}

		&.col-tablet-3-5 {
			grid-column: 3 / 5;
		}

		&.col-tablet-4-5 {
			grid-column: 4 / 5;
		}

		&.col-tablet-4-6 {
			grid-column: 4 / 6;
		}

		&.col-tablet-4-7 {
			grid-column: 4 / 7;
		}

		&.col-tablet-5-6 {
			grid-column: 5 / 7;
		}

		&.col-tablet-5-7 {
			grid-column: 5 / 7;
		}

		&.col-tablet-5-8 {
			grid-column: 5 / 8;
		}

		&.col-tablet-6 {
			grid-column: 6;
		}

		&.col-tablet-7 {
			grid-column: 7;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;

		&.category {
			font-size: 1.13em;
		}

		&.row-mob-1 {
			grid-row: 1;
		}

		&.row-mob-2 {
			grid-row: 2;
		}

		&.row-mob-3 {
			grid-row: 3;
		}

		&.row-mob-4 {
			grid-row: 4;
		}

		&.col-mob-1-2 {
			grid-column: 1 / 2;
		}

		&.col-mob-1-3 {
			grid-column: 1 / 3;
		}

		&.col-mob-2-3 {
			grid-column: 2 / 3;
		}

		&.col-mob-2-4 {
			grid-column: 2 / 4;
		}

		&.col-mob-2-5 {
			grid-column: 2 / 5;
		}

		&.col-mob-1-4 {
			grid-column: 1 / 4;
		}

		&.col-mob-1-5 {
			grid-column: 1 / 5;
		}

		&.col-mob-1-7 {
			grid-column: 1 / 7;
		}

		&.col-mob-3-4 {
			grid-column: 3 / 4;
		}

		&.col-mob-3-5 {
			grid-column: 3 / 5;
		}

		&.col-mob-4-5 {
			grid-column: 4 / 5;
		}

		&.col-mob-5-6 {
			grid-column: 5 / 6;
		}

		&.col-mob-5-7 {
			grid-column: 5 / 7;
		}

		&.col-mob-6-7 {
			grid-column: 6 / 7;
		}

		&.col-mob-2 {
			grid-column: 1 / 3;
		}

		&.col-mob-4-7 {
			grid-column: 4 / 7;
		}
	}
`
TableTd.displayName = 'TableTd'

export const TableTdGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: start;
	align-items: center;
	gap: 0.35em;
	margin-top: 0.2em;

	svg {
		width: 1.55em;
		height: 1.55em;
		fill: ${colorTheme.table.tableTd.icon};
	}

	&.driver-monitor {
		margin-top: 0;
		gap: 0.5em;

		svg {
			width: 1.1em;
			height: 1.1em;
		}
	}

	&.orange {
		color: ${colorTheme.invoices.table.orange};

		svg {
			fill: ${colorTheme.invoices.table.orange};
		}
	}

	&.green {
		color: ${colorTheme.invoices.table.green};

		svg {
			fill: ${colorTheme.invoices.table.green};
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		column-gap: 0.5em;
		row-gap: 0;

		svg {
			width: 1.65em;
			height: 1.65em;
			grid-column: 1;
		}
	}
`
TableTdGrid.displayName = 'TableTdGrid'

export const TableTdStatus = styled(TableTd)`
	&.BEING_PREPARED, &.PREPARED {
		color: ${colorTheme.orders.table.status.color.beingPrepared};

		svg {
			fill: ${colorTheme.orders.table.status.color.beingPrepared};
		}
	}

	&.ON_THE_WAY {
		color: ${colorTheme.orders.table.status.color.onTheWay};

		svg {
			fill: ${colorTheme.orders.table.status.color.onTheWay};
		}
	}

	&.COMPLETED {
		color: ${colorTheme.orders.table.status.color.completed};

		svg {
			fill: ${colorTheme.orders.table.status.color.completed};
		}
	}

	&.CANCELED {
		color: ${colorTheme.orders.table.status.color.canceled};

		svg {
			fill: ${colorTheme.orders.table.status.color.canceled};
		}
	}

	&.NOT_ACCEPTED {
		color: ${colorTheme.orders.table.status.color.notAccepted};

		svg {
			fill: ${colorTheme.orders.table.status.color.notAccepted};
		}
	}

	&.NOT_REGISTERED {
		color: ${colorTheme.orders.table.status.color.notRegistered};

		svg {
			fill: ${colorTheme.orders.table.status.color.notRegistered};
		}
	}

	&.DENIED {
		color: ${colorTheme.orders.table.status.color.denied};

		svg {
			fill: ${colorTheme.orders.table.status.color.denied};
		}
	}

	&.READY {
		color: ${colorTheme.orders.table.status.color.ready};

		svg {
			fill: ${colorTheme.orders.table.status.color.ready};
		}
	}
`
TableTdStatus.displayName = 'TableTdStatus'
