import createAdditionalPaymentConfigs from '../../../axios/payment/additionalPaymentConfigs/post'
import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import updateAdditionalPaymentConfigs from '../../../axios/payment/additionalPaymentConfigs/put'
import deleteAdditionalPaymentConfig from '../../../axios/payment/additionalPaymentConfigs/delete'
import getAdditionalPaymentConfigs from '../../../axios/payment/additionalPaymentConfigs/get'
import { setAdditionalPaymentConfigs } from '../../../action/payment'

export const getAdditionalPaymentConfigsThunk = () => dispatch => {
	return getAdditionalPaymentConfigs()
		.then((res) => {
			dispatch(setAdditionalPaymentConfigs(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
export const createAdditionalPaymentConfigsThunk = (data) => dispatch => {
	return createAdditionalPaymentConfigs(data)
		.then(() => {
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateAdditionalPaymentConfigsThunk = (data) => dispatch => {
	return updateAdditionalPaymentConfigs(data)
		.then(() => {
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteAdditionalPaymentConfigThunk = (configId) => dispatch => {
	return deleteAdditionalPaymentConfig(configId)
		.then(() => {
			dispatch(getAdditionalPaymentConfigsThunk())
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
