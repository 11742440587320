import styled from 'styled-components'
import { theme } from '../../../../../style/theme'

export const PaidBeforeOrdersTableWrapper = styled.div`
	font-size: 1rem;

	@media screen and (max-width: ${theme.point820}) {
		margin-bottom: 12em;
	}
`
PaidBeforeOrdersTableWrapper.displayName = 'PaidBeforeOrdersTableWrapper'
