import React, { useEffect } from 'react'
import IncomePhoneModal from './incomeCall'
import { useDispatch, useSelector } from 'react-redux'
import ProcessingTimeOrderModal from './processingTimeOrder'
import ConfirmPreOrderModal from './confirmPreOrder'
import PrintMessageModal from './printMessageModal'
import AutoConfirmationModal from './autoConfirmationModal'
import { setAppModal } from '../../redux/action/system'
import StatusModal from './statusModal'
import ContactsModal from './contactsModal'
import ExtraContactsOfSupportModal from './extraContactsOfSupport'
import TseNotificationModal from './TseNotificationModal'
import OrderClosedErrorModal from './OrderClosedErrorModal'
import PlatformDriversOrderModal from './PlatformDriversOrderModal'
import PayPalWarningModal from './PayPalWarningModal'
import PayPalDeadlineWarningModal from './PayPalDeadlineWarningModal'
import { selectIsTestCompany } from '../../redux/selector/system'
import OnlinePaymentsWarningModal from './OnlinePaymentsWarningModal'
import TodayPreOrderModal from './TodayPreOrderModal'
import ConfirmExpressOrder from './confirmExpressOrder'
import DenyPreOrderModal from './denyOrder/preOrder'
import DenyOrderModal from './denyOrder/order'
import DeactivationModals from './deactivationModals'
import TapToPayNotificationModal from './TapToPayNotificationModal'

const AppModal = React.memo(() => {
	const processingTimeOrderModalShow = useSelector(store => store.system.modal.processingTimeOrderModalShow)
	const confirmPreOrderModalShow = useSelector(store => store.system.modal.confirmPreOrderModalShow)
	const printMessageModalShow = useSelector(store => store.system.modal.printMessageModalShow)
	const autoConfirmationModalShow = useSelector(store => store.system.modal.autoConfirmationModalShow)
	const confirmExpressOrderModalShow = useSelector(store => store.system.modal.confirmExpressOrderModalShow)
	const processingTimeOrderModalDataLength = useSelector(store => store.system.modalData?.processingTimeOrderModalData?.length)
	const confirmPreOrderModalDataLength = useSelector(store => store.system.modalData?.confirmPreOrderModalData?.length)
	const contactsModalShow = useSelector(store => store.system.modal.contactsModalShow)
	const platformDriversOrderModalShow = useSelector(store => store.system.modal.platformDriversOrderModalShow)
	const platformDriversOrderModalDataLength = useSelector(store => store.system.modalData?.platformDriversOrderModalData?.length)
	const confirmExpressOrderModalDataLength = useSelector(store => store.system.modalData?.confirmExpressOrderModalData?.length)
	const denyOrderModalDataLength = useSelector(store => store.system.modalData?.denyOrderModalData?.length)
	const isTestCompany = useSelector(selectIsTestCompany)
	const dispatch = useDispatch()

	useEffect(() => {
		if (processingTimeOrderModalDataLength > 0) {
			setTimeout(() => {
				dispatch(setAppModal({ processingTimeOrderModalShow: true }))
			}, 500)
		} else {
			dispatch(setAppModal({ processingTimeOrderModalShow: false }))
		}
	}, [processingTimeOrderModalDataLength]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (confirmPreOrderModalDataLength > 0) {
			setTimeout(() => {
				dispatch(setAppModal({ confirmPreOrderModalShow: true }))
			}, 500)
		} else {
			dispatch(setAppModal({ confirmPreOrderModalShow: false }))
		}
	}, [confirmPreOrderModalDataLength]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (platformDriversOrderModalDataLength > 0) {
			setTimeout(() => {
				dispatch(setAppModal({ platformDriversOrderModalShow: true }))
			}, 500)
		} else {
			dispatch(setAppModal({ platformDriversOrderModalShow: false }))
		}
	}, [platformDriversOrderModalDataLength]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (confirmExpressOrderModalDataLength > 0) {
			setTimeout(() => {
				dispatch(setAppModal({ confirmExpressOrderModalShow: true }))
			}, 500)
		} else {
			dispatch(setAppModal({ confirmExpressOrderModalShow: false }))
		}
	}, [confirmExpressOrderModalDataLength]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (processingTimeOrderModalShow && processingTimeOrderModalDataLength === 0) {
			dispatch(setAppModal({ processingTimeOrderModalShow: false }))
		}
	}, [processingTimeOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (confirmPreOrderModalShow && confirmPreOrderModalDataLength === 0) {
			dispatch(setAppModal({ confirmPreOrderModalShow: false }))
		}
	}, [confirmPreOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (platformDriversOrderModalShow && platformDriversOrderModalDataLength === 0) {
			dispatch(setAppModal({ platformDriversOrderModalShow: false }))
		}
	}, [platformDriversOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (confirmExpressOrderModalShow && confirmExpressOrderModalDataLength === 0) {
			dispatch(setAppModal({ confirmExpressOrderModalShow: false }))
		}
	}, [confirmExpressOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<DeactivationModals />
			<ExtraContactsOfSupportModal />
			<IncomePhoneModal />
			{processingTimeOrderModalShow && processingTimeOrderModalDataLength > 0 && <ProcessingTimeOrderModal />}
			{confirmPreOrderModalShow && confirmPreOrderModalDataLength > 0 && <ConfirmPreOrderModal />}
			{platformDriversOrderModalShow && platformDriversOrderModalDataLength > 0 && <PlatformDriversOrderModal />}
			{confirmExpressOrderModalShow && confirmExpressOrderModalDataLength > 0 && <ConfirmExpressOrder />}
			{confirmPreOrderModalDataLength > 0 && <DenyPreOrderModal />}
			{denyOrderModalDataLength > 0 && <DenyOrderModal />}
			{printMessageModalShow && <PrintMessageModal />}
			{autoConfirmationModalShow && <AutoConfirmationModal />}
			<StatusModal />
			{contactsModalShow && <ContactsModal />}
			<TseNotificationModal />
			<OrderClosedErrorModal />
			{isTestCompany && <PayPalWarningModal />}
			{isTestCompany && <PayPalDeadlineWarningModal />}
			<OnlinePaymentsWarningModal />
			<TodayPreOrderModal />
			<TapToPayNotificationModal />
		</>
	)
})

AppModal.displayName = 'AppModal'
export default AppModal
