import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'
import colors from '../../../../style/colors'

export const TimeInputWrapper = styled.label`
  font-size: 1rem;
  display: flex;
  align-items: center;
	justify-content: center;
  width: 4.81em;
  min-height: 3.19em;
  background: ${colorTheme.input.time.background};
  border-radius: 0.75em;
	border: 1px solid ${colorTheme.input.time.border};
  padding: 0 0.5em;

	&.disabled {
		-webkit-text-fill-color: ${colorTheme.input.time.disabled.text};
		opacity: 1;
		border-color: ${colorTheme.input.time.disabled.border};
	}

	&.error {
		border-color: ${colorTheme.input.time.error.border};
	}

	&.success {
		border-color: ${colorTheme.input.time.success.border};
	}

	&.focus {
		border-color: ${colorTheme.input.time.focus.border};
	}

	&.onlyHours, &.onlyMinutes {
		width: 3.19em;
	}

	&.big {
		min-height: 4.19em;
		width: 8.13em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.big {
			min-height: 3.93em;
			width: 6.29em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.big {
			min-height: 3.44em;
			width: 5.5em;
		}
	}
`

export const TimeInputField = styled.input`
  width: 100%;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoBold};
	font-weight: 700;
  background: transparent;
  border: none;
  color: ${colorTheme.input.time.text};
	cursor: pointer;
	text-align: center;

	&.disabled {
		cursor: not-allowed;
	}

	&.error {
		color: ${colorTheme.input.time.error.text};
	}

	&.focus {
		color: ${colorTheme.input.time.focus.text};
	}
`

export const TimeError = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88em;
	right: 0;
	top: 0;
	padding: 0 1.43em;
	width: 100%;
	color: ${colors.white};
	height: 0;
	max-height: 0;
	transition: all .2s;
	text-align: left;
	border-radius: 0.75em;

	&.error {
		height: 2em;
		max-height: 2em;
		padding-top: 0.5em;
	}

	&.red {
		color: ${colors.red};
	}
`
TimeError.displayName = 'TimeError'

export const TimePeriodsWrapper = styled.div`
	font-size: 1rem;
	border-radius: 0.75em;

	&.disabled {
		color: ${colors.gray_more_lighter};
	}

	&.margin {
		margin-left: -1.25em;
	}

	&.error {
		background-color: ${colors.red};
		width: max-content;
		margin: 0 -0.49em;

		&.margin {
			margin-left: -1.56em;
		}
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.margin {
			margin-left: 0;
		}

		&.error {
			margin: 0;

			&.margin {
				margin-left: 0;
			}
		}
	}
`
TimePeriodsWrapper.displayName = 'TimePeriodsWrapper'

export const PeriodWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, max-content);
	align-items: center;
	column-gap: 0.31em;
	background-color: ${colors.white};

	&.error {
		border: 1px dashed ${colors.red};
		border-radius: 0.75em;
		padding: 0.31em;
	}
`
PeriodWrapper.displayName = 'PeriodWrapper'
