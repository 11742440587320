/* eslint-disable max-lines */
import { toast } from 'react-toastify'
import { format, subDays } from 'date-fns'
import { filterError } from './errorLogFilter'
import { PERCENTS } from '../redux/reducer/businessScopeDiscount/constants'
import colors from '../style/colors'
import { currencyList } from './currencyList'
import { v4 as uuidv4 } from 'uuid'

export const getAppEnvironment = () => {
	return window.REACT_APP_ENVIRONMENT || 'dev'
}

export const isDevEnvironment = () => {
	return getAppEnvironment() === 'dev'
}

export const isLiveEnvironment = () => {
	return getAppEnvironment() === 'live'
}

export const clone = (obj) => JSON.parse(JSON.stringify(obj))

export const retrieveErrorFromApi = (error) => {
	const response = error?.response
	const responseStatus = response?.status
	const responseData = response?.data
	const request = response?.config
	let requestDataJson = {}
	try {
		requestDataJson = JSON.parse(request?.data)
		//eslint-disable-next-line
	} catch (e) {
	}
	const requestData = removePasswordFromObject(requestDataJson)
	if (request?.url && filterError(error)) {
		const responseText = responseData?.message || responseData?.error
		// eslint-disable-next-line
		console.error(
			'Server response error: ',
			window.location.pathname,
			request?.url,
			request?.method,
			responseStatus,
			JSON.stringify(requestData),
			responseText
		)
	}
	let errorMessage = []

	if (responseData?.apierror?.subErrors) {
		errorMessage = responseData.apierror.subErrors
	} else {
		if (responseData?.apierror?.message) {
			errorMessage.push({
				field: 'Common Error',
				message: responseData.apierror.message,
			})
		} else if (responseData?.error && responseData?.error_description) {
			errorMessage.push({
				field: responseData.error,
				message: responseData.error_description,
			})
		} else if (response && responseStatus === 403) {
			errorMessage.push({
				field: 'Access Error',
				message: 'You don\'t have enough rights',
			})
		} else if (response && responseStatus === 401 && !responseData?.message) {
			errorMessage = null
		} else if (response && responseStatus === 400 && responseData?.message === 'The refresh token was not found or has expired.') {
			errorMessage.push({
				message: null,
			})
		} else if (responseData?.message) {
			errorMessage.push({
				message: responseData.message,
			})
		} else {
			errorMessage.push({
				field: 'Common Error',
				message: 'Something went wrong, please contact admin',
			})
		}
	}
	return errorMessage
}

export const removePasswordFromObject = (object) => {
	if (!object) return {}
	let { password, ...res } = object
	delete res?.password
	delete res?.passwordConfirm
	return res
}

export const notifyToast = (toasts = [], type = 'success') => {
	if (Array.isArray(toasts)) {
		toasts.map(item => (type === 'success' ? toast.success(item.field ? (item.field + ' - ' + item.message) : item.message) : toast.error(item.field ? (item.field + ' - ' + item.message) : item.message)))
	} else {
		//or you want single toast?
		if (toasts && toasts.message && type === 'success') toast.success(toasts.message ?? 'Success!')
		if (toasts && toasts.message && type === 'error') toast.error(toasts.message ?? 'Error!')
		if (toasts && toasts.message && type === 'warning') toast.warning(toasts.message ?? 'Warning!')
		if (toasts && toasts.message && type === 'info') toast.info(toasts.message ?? 'Info!')
	}
}

export const getDateParameterString = (date) => {
	if (!date) return null
	return date.getFullYear() + '-'
		+ ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '-'
		+ (date.getDate() > 9 ? '' : '0') + date.getDate()
}

export const getDateParameterStringMonth = (date) => {
	return date?.getFullYear() + '-'
		+ ((date?.getMonth() + 1) > 9 ? '' : '0') + (date?.getMonth() + 1)
}

export const getHourString = (hours) => {
	return (hours > 9 ? '' : '0') + hours
}

export const getMinuteString = (minutes) => {
	return (minutes > 9 ? '' : '0') + minutes
}

export const getFormattedCurrency = (number, currencyLocale = 'de-DE', currencyCode = 'EUR') => {
	return new Intl.NumberFormat(currencyLocale, { style: 'currency', currency: currencyCode }).format(number)
}

export const getNeededDateFormat = (date, formatDate = 'dd.MM.yyyy') => {
	if (!date) return
	return format(
		new Date(date),
		formatDate,
	)
}

/**
 * This method takes current date, if date is between 00:00 and 7:00am - give date 1 day substracted (Europe/Berlin timezone)
 * @return {Date}
 */
export const setWorkingHoursHelper = (date = new Date()) => {
	const germanDate = convertTZ(date, 'Europe/Berlin')
	return germanDate.getHours() < 7 ? subDays(germanDate, 1) : germanDate
}

export const convertTZ = (date, tzString) => {
	return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }))
}


export const getTimeDifferenceMinutes = (datetime) => {
	datetime = typeof datetime !== 'undefined' ? datetime : '2014-01-01 01:02:03.123456'
	datetime = new Date(datetime).getTime()
	const now = new Date().getTime()
	if (isNaN(datetime)) {
		return ''
	}
	let milisec_diff = ''
	if (datetime < now) {
		milisec_diff = now - datetime
	} else {
		milisec_diff = datetime - now
	}
	const date_diff = new Date(milisec_diff)
	return date_diff.getMinutes()
}

/**
 * This method removes empty string in form data
 */
export const removeEmptyFields = (data) => {
	const dataCopy = { ...data }
	Object.keys(dataCopy).forEach(key => {
		if ([null, ''].includes(dataCopy[key])) {
			delete dataCopy[key]
		}
	})
	return dataCopy
}

export const getFloat = (value) => {
	switch (typeof value) {
		case 'string':
			return value ? parseFloat(value.replace(',', '.')) : 0
		case 'number':
			return value
		default:
			return 0
	}
}

export const uuidGenerator = () => {
	return uuidv4()
}

export const getDistanceInKm = (distance, fractionDigits = 2) => {
	return (distance / 1000).toFixed(fractionDigits)
}

export const capitalize = (string) => {
	const separateWord = string.toLowerCase().split(' ')
	for (let i = 0; i < separateWord.length; i++) {
		separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
			separateWord[i].substring(1)
	}
	return separateWord.join(' ')
}

export const formatAddress = (street, streetNumber, postalCode, city) => {
	if (!street && !streetNumber && !postalCode && !city) return null
	return `${street ?? ''} ${postalCode ?? ''} ${city ?? ''}`
}

export const calcSubItemsTotal = (item) => {
	let subItemsTotal = 0
	if (item.items) {
		subItemsTotal = item.items.reduce((it, i) => it + i.initialPrice * i.quantity, 0)
	}
	return subItemsTotal
}

export const recalculateSubItems = (item) => {
	if (item?.type !== 'MENU' && calcSubItemsTotal(item) < 0) {
		item.items?.forEach(i => i.price = 0)
	} else {
		item.items?.forEach(i => {
			i.price = (item?.type === 'MENU') ? i.price : i.initialPrice
		})
		if (item?.type === 'MENU') {
			item.items?.forEach(menuItem => {
				recalculateMainDishSubItems(menuItem)
			})
		} else {
			recalculateMainDishSubItems(item)
		}
	}
	return item
}

const recalculateMainDishSubItems = (mainDishItem) => {
	let freeExtrasNumber = mainDishItem.freeExtrasNumber
	if (freeExtrasNumber > 0) {
		const itemsCopy = []
		mainDishItem.items?.forEach(item => {
			if (freeExtrasNumber - item.quantity >= 0) {
				freeExtrasNumber = freeExtrasNumber - item.quantity
				itemsCopy.push({ ...item, quantity: item.quantity, price: 0 })
			} else if (freeExtrasNumber > 0) {
				const itemWithoutPrice = { ...item, quantity: freeExtrasNumber, price: 0 }
				const itemWithPrice = { ...item, quantity: item.quantity - freeExtrasNumber }
				freeExtrasNumber = 0
				itemsCopy.push(itemWithoutPrice)
				itemsCopy.push(itemWithPrice)
			} else {
				itemsCopy.push({ ...item })
			}
		})
		mainDishItem.items = itemsCopy
	}
}

export const getPosPriceByIndex = (item, orderType, priceIndex) => {
	const prices = getPosPricesByOrderType(item, orderType)
	return prices && (prices[priceIndex] ? prices?.[priceIndex] : prices[0])
}

export const getItemsByExistPriceId = (items, orderType, priceId) => {
	return items?.filter(item => {
		const price = getPosPriceById(item, orderType, priceId)
		return price && !price?.disabled
	})
}

export const getPosPricesByOrderType = (item, orderType) => {
	return item?.posPrices.find(pp => pp.name === orderType)?.prices?.filter(pr => !pr.disabled)
}

export const getPosPriceValue = (item, orderType, priceId) => {
	const price = getPosPriceById(item, orderType, priceId)
	return price && price.value
}

export const getPosPriceById = (item, orderType, priceId) => {
	const prices = getPosPricesByOrderType(item, orderType)
	return prices?.find(pr => pr.priceTitle?.id === priceId)
}

export const getMenuProductPriceIndex = (menu, menuProductIndex, product, orderType) => {
	const prices = getPosPricesByOrderType(product, orderType)
	const priceDescriptionId = menu.menuPositions[menuProductIndex].priceDescriptionId
	const price = getPosPriceById(product, orderType, priceDescriptionId) || prices[0]
	return prices.indexOf(price)
}

export const getMenuPositionProduct = (menuPosition, productId) => {
	return menuPosition.products?.find(menuProduct => menuProduct?.productId === productId) || null
}

export const getMenuPositionProductPrice = (menuPosition, product, orderType) => {
	const menuPositionProduct = getMenuPositionProduct(menuPosition, product.itemId)

	if (menuPositionProduct) {
		const menuPositionProductOriginalPrice = menuPositionProduct?.withOriginalPrice
		const menuPositionProductExtraPrice = menuPositionProduct?.extraPrice
		return menuPositionProductOriginalPrice ?
			getPosPriceValue(menuPositionProduct, orderType, menuPosition.priceDescriptionId) :
			menuPositionProductExtraPrice
	} else {
		const menuPositionOriginalPrice = menuPosition?.withOriginalPrice
		const menuPositionExtraPrice = menuPosition?.extraPrice
		return menuPositionOriginalPrice ?
			product.price :
			menuPositionExtraPrice
	}
}

export const convertProduct2Item = (product, quantity, price, priceIndex, items, priceTitle) => {
	return ({
		itemId: product.productId,
		type: product.productType,
		sku: product.nr,
		category: product.category?.title,
		categoryId: product.category?.id,
		quantity,
		name: product.title + (priceTitle ? ' ' + priceTitle : ''),
		price: price,
		initialPrice: price,
		remark: null,
		priceIndex,
		items,
		ingredients: product?.ingredients,
		numberOfMustExtras: product.numberOfMustExtras,
		freeExtrasNumber: product.freeExtrasNumber,
		mustExtras: product.mustExtras,
		inMinimumOrderValueConsidered: product.inMinimumOrderValueConsidered,
		disabledForMenu: product?.disabledForMenu,
		disableCartModal: product.disableCartModal,
		disabledDiscount: product.category?.disabledDiscount || product.disabledDiscount,
	})
}

export const convertMenu2Item = (menu, quantity, price) => {
	return ({
		itemId: menu.productId,
		type: menu.productType,
		sku: menu.nr,
		category: menu.category?.title,
		quantity,
		name: menu.title,
		price: price,
		initialPrice: price,
		remark: null,
		priceIndex: 0,
		items: menu.menuPositions.map(mp => mp.item),
		inMinimumOrderValueConsidered: menu.inMinimumOrderValueConsidered,
		disabledDiscount: menu.category?.disabledDiscount || menu.disabledDiscount,
	})
}

export const convert2CouponItem = (coupon, status) => {
	if (coupon) {
		const dataCoupon = {
			type: 'MARKETING_COUPON',
			quantity: 1,
			initialProduct: {
				id: coupon.id,
				name: coupon.name,
				discountData: coupon.discountData,
				minOrderAmount: coupon.minOrderAmount,
				code: coupon.code,
			},
		}
		return status ? { ...dataCoupon, status, initialProduct: { ...dataCoupon.initialProduct, status } } : dataCoupon
	} else {
		return null
	}
}

export const parseJwt = (token) => {
	if (!token) return null
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
	}).join(''))

	return JSON.parse(jsonPayload)
}

export const jwtExpired = (token) => {
	let jwt = parseJwt(token)
	let exp = jwt.exp
	return !exp || ((new Date(exp * 1000)) < new Date())
}

export const jwtContainsAnyRole = (token, roles) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return roles.some(r => jwt.roles.includes(r))
}

export const jwtContainsAllRoles = (token, roles) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return roles.every(r => jwt.roles.includes(r))
}

export const jwtContainsAnyGroup = (token, groups) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return groups.some(g => jwt.group === g)
}

export const jwtGetCompanyData = (token) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return jwt?.companyData
}

export const jwtGetUsername = (token) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return jwt?.preferred_username
}

export const jwtGetRoles = (token) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return jwt?.roles
}

export const jwtGetGroup = (token) => {
	const jwt = parseJwt(token)
	if (!jwt) return false
	return jwt?.group
}

export const roundPrice = (price = 0) => {
	return Number(`${Math.round(`${price}e2`)}e-2`)
}

export const getDiscountInCurrency = (discount, discountType, itemsTotal) => {
	if (typeof discount !== 'number' || isNaN(discount)) {
		return 0
	}
	return discountType === PERCENTS ? roundPrice(itemsTotal * discount / 100) : discount
}

export const getPercentDiscountInCurrency = (discount, itemsTotal) => {
	return getDiscountInCurrency(discount, PERCENTS, itemsTotal)
}

export const getDiscountSymbol = (discountType, currencySymbol) => {
	if (discountType === PERCENTS) return '%'
	return currencySymbol
}

export const ungroupItems = (items) => {
	const itemsCopy = items.filter(ti => ti.quantity !== 0)
	items.forEach(ti => {
		if (ti.quantity > 1 || ti.quantity < -1) {
			Array(Math.abs(ti.quantity) - 1).fill().forEach(() => {
				itemsCopy.push({ ...ti, quantity: Math.sign(ti.quantity) })
			})
			ti.quantity = Math.sign(ti.quantity)
		}
	})
	return itemsCopy
}

export const getTablePrice = (table) => {
	return table?.orders?.reduce((tp1, o) => tp1 + o.totalPrice, 0) + table?.splits?.reduce((tp1, s) => tp1 + s.totalPrice, 0)
}

export const getTableOrderPrice = (table) => {
	return table?.orders?.reduce((tp1, o) => tp1 + o.totalPrice, 0)
}

export const tableIsBusy = (table) => {
	return !!(table?.orders?.length || table?.splits?.length || table?.payBeforeOrders?.length)
}

export const stringToColor = (str) => {
	if (!str) return colors.gray_lighter
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = hash + str.charCodeAt(i)
	}
	const index = hash % 7
	const existColors = ['#2880ED', '#383A50', '#CAC7E9', '#8AA5CE', '#E6BDA9', '#F8B2B2', '#F1D3A3', '#CBEDE9']
	return existColors[index]
}

export const getBreakPoint = (point) => {
	return parseInt(point.replace('px', ''))
}

export const getFormatDateForDateInput = (date) => {
	const arr = date.split('.')
	return arr.reverse().join(', ')
}

export const changeQuantitySubItem = (existItem, subItemQuantity) => {
	if (existItem.quantity * subItemQuantity < 0) {
		return existItem.quantity < 0 ? -1 * (Math.abs(existItem.quantity) - 1) : existItem.quantity - 1
	} else {
		return existItem.quantity < 0 ? -1 * (Math.abs(existItem.quantity) + 1) : existItem.quantity + 1
	}
}

export const addSubItem = (item, subItem) => {
	if (!item.items) {
		item.items = []
	}
	const indexExistItem = item.items.findIndex(item => item.itemId === subItem.itemId)

	if (indexExistItem === -1) {
		item.items.push(subItem)
	} else {
		item.items[indexExistItem].quantity = changeQuantitySubItem(item.items[indexExistItem], subItem.quantity)
	}
	item.items = item.items.filter(item => item.quantity !== 0)

	return item
}

export const calculateSum = arr => arr.reduce((acc, cur) => acc + cur, 0)

export const calcCartItemTotalPrice = (item, itemsWithDiscount) => {
	if (item.quantity < 0) throw new Error('Invalid quantity')
	if (item.type === 'STAMP_VOUCHER') return 0
	if (itemsWithDiscount && item.disabledDiscount) return 0
	const itemTotal = (item.price != null && item.status !== 'CANCELED') ? item.price : 0

	let subItemsTotal = 0
	if (item.type === 'MENU') {
		subItemsTotal = item.items.filter(item => item.status !== 'CANCELED').reduce((it, i) => it + calcCartItemTotalPrice(i), 0)
	} else if (item.items) {
		subItemsTotal = item.items.filter(item => item.status !== 'CANCELED').reduce((it, i) => it + ((i.price && i.quantity) ? i.price * i.quantity : 0), 0)
	}

	return item.quantity != null ? Math.round((((itemTotal + (subItemsTotal < 0 ? 0 : subItemsTotal)) * item.quantity) + Number.EPSILON) * 100) / 100
		: itemTotal + (subItemsTotal < 0 ? 0 : subItemsTotal)
}

export const flatMapCategories = (categories) => {
	return categories?.flatMap(cat => [cat, ...flatMapCategories(cat.children)]) || []
}

export const deleteItem = (items, itemIndex) => {
	if (items[itemIndex].quantity > 1) {
		return items.map((item, index) => index === itemIndex ? { ...item, quantity: item.quantity - 1 } : item)
	} else {
		return items.filter(item => item !== items[itemIndex])
	}
}

export const getCurrencyCodeByCountryCode = (countryCode) => {
	const dataCountry = currencyList.find(el => el.countryCode === countryCode)
	return dataCountry ? dataCountry.code : 'EUR'
}

export const getCurrencyLocaleByCountryCode = (countryCode) => {
	const dataCountry = currencyList.find(el => el.countryCode === countryCode)
	return dataCountry ? dataCountry.locale : 'de-DE'
}

export const getCurrencySymbol = (currencyCode) => {
	const currencyData = currencyList.find(el => el.code === currencyCode)
	return currencyData ? currencyData.symbol : '€'
}

export const formatCurrencyOfTextInput = (value, setValue) => {
	setValue(isNaN(getFloat(value)) ? value : getFloat(value).toFixed(2).replace('.', ','))
}

export const formatCurrency = (value) => {
	return (value >= 0 && value !== null) ? getFloat(value).toFixed(2).replace('.', ',') : null
}

export const getTimeDifferenceMinutesTillEnd = (datetime, endtime, duration) => {
	datetime = new Date(datetime).getTime()
	const now = new Date().getTime()

	let end = ''
	if (endtime) {
		end = new Date(endtime).getTime()
	} else {
		end = new Date(datetime + duration * 60000).getTime()
	}

	if (now <= end) {
		return new Date(now - datetime).getMinutes()
	} else {
		return duration
	}
}

export const getTimeDifferenceOrder = (datetime, endtime) => {
	datetime = new Date(datetime).getTime()
	endtime = new Date(endtime).getTime()
	return new Date(endtime - datetime).getMinutes()
}

export const milisecToHoursMinutes = (milliseconds, hoursSign, minutesSign) => {
	const minutes = parseInt((milliseconds / (1000 * 60)) % 60)
	const hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24)

	if (hours < 1) return minutes + minutesSign
	return hours + hoursSign + ' ' + minutes + minutesSign
}

export const getOrderTimeDifferenceTillNow = (datetime, hoursSign, minutesSign) => {
	datetime = new Date(datetime).getTime()
	const now = new Date().getTime()
	const milisec = new Date(now - datetime).getTime()

	if (milisec) return milisecToHoursMinutes(milisec, hoursSign, minutesSign)
}

export const convertTimeWithSecondsToMinutes = (time) => {
	return time.split(':').slice(0, 2).join(':')
}

export const convertTimeToSeconds = (time) => {
	return time.split(':').length < 3 ? time + ':00' : time
}

export const convertTimeToMinutes = (time) => {
	const [hours, minutes] = time.split(':')
	return hours * 60 + minutes
}

export const convertTimeToMilliseconds = (time) => {
	if (!time) return 0
	const [hours, minutes, seconds] = time.split(':')
	return (hours * 3600 + minutes * 60 + +seconds) * 1000
}

export const splitTime = (time) => {
	const [hours, minutes, seconds] = time.split(':')
	return { hours, minutes, seconds }
}

export const addItemAndCouponPrice = (resultItems, extraDiscount, couponPrice) => {
	const extraDiscountItem = { name: 'Extra Rabatt', type: 'EXTRA_DISCOUNT', quantity: 1, price: -extraDiscount }
	return [...resultItems, extraDiscountItem].map(el => el.type === 'MARKETING_COUPON' ? { ...el, price: -couponPrice } : el)
}

export const filterNumber = v => {
	let filteredValue = v.replace(/[^0-9,.]/g, '')
	filteredValue = filteredValue.replace(/^(\D*)[.,]/g, '$1')// Remove all periods that precede numbers
	filteredValue = filteredValue.replace(/(\.\d*)\./g, '$1')  // Remove all extra commas that come after a number
	filteredValue = filteredValue.replace(/^0+(\d)/, '$1')  // Prevent input like "00,8"
	filteredValue = filteredValue.replace(/,{2,}/g, ',')  // Prevent the input of a sequence of commas
	// Prevent input of more than one comma or period
	filteredValue = filteredValue.replace(/[,.]/g, (match, offset, input) => {
		const count = input.split(match).length - 1
		return count > 1 ? '' : match
	})
	filteredValue = filteredValue.replace(/(\d),(\D+)/g, '$1$2')  // Prevent input of two commas after a number
	return filteredValue
}

export const reorderButtonsForUnpaidOrder = (buttons, t) => {
	const orderPayButton = buttons.find(button => button.text === t('order.modal.order_pay') && button.show)
	const showOrderButton = buttons.find(button => button.text === t('order.modal.show_order') && button.show)
	const changeOrderButton = buttons.find(button => button.text === t('order.modal.change') && button.show)
	const printOrderButton = buttons.find(button => button.text === t('order.modal.print_order') && button.show)

	const otherButtons = buttons.filter(button => ![orderPayButton, showOrderButton, changeOrderButton, printOrderButton].includes(button))

	return [orderPayButton, showOrderButton, changeOrderButton, printOrderButton, ...otherButtons].filter(Boolean)
}
