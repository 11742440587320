import { SET_ORDER_CALCULATE_USER, SET_ORDER_PROCEED_USER, SET_ORDER_SHOW_PAYMENT_METHOD, SET_ORDER_USER_MODAL, SET_PROCESSED_BY_VALUES, SET_SELECTED_USER, SET_SHOW_CANCEL_ORDER, SET_SHOW_ORDER, SET_SHOW_ORDER_DATA, SET_SHOW_RECEIPT_PAYER_DATA } from '../../actionTypes/all-order'

export const setOrderModal = showSelectedUser => ({ type: SET_ORDER_USER_MODAL, showSelectedUser })

export const setOrderProceedUserModal = showSelectedProceedUser => ({ type: SET_ORDER_PROCEED_USER, showSelectedProceedUser })

export const setOrderShowCalculateUserModal = showCalculateUser => ({ type: SET_ORDER_CALCULATE_USER, showCalculateUser })

export const setSelectedUser = user => ({ type: SET_SELECTED_USER, user })

export const setShowCancelOrder = content => ({ type: SET_SHOW_CANCEL_ORDER, payload: { content } })

export const setShowPaymentMethodModal = showPaymentMethod => ({ type: SET_ORDER_SHOW_PAYMENT_METHOD, showPaymentMethod })

export const setShowOrder = content => ({ type: SET_SHOW_ORDER, payload: { content } })

export const setShowOrderData = content => ({ type: SET_SHOW_ORDER_DATA, payload: { content } })

export const setProcessedByValues = value => ({ type: SET_PROCESSED_BY_VALUES, value })

export const setShowReceiptPayerData = value => ({ type: SET_SHOW_RECEIPT_PAYER_DATA, value })
