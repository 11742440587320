import { getDateDifferenceInDays } from '../../../component/PartnerPortal/helper'
import { createSelector } from 'reselect'

export const selectCompanyActivationData = store => store.company?.activationData

export const selectActivationData = createSelector(
	selectCompanyActivationData,
	(activationData) => {
		if (!activationData) {
			return { isDeactivated: false }
		}
		const deactivationDate = activationData.deactivationDate
		const emergencyBlockingDate = activationData.emergencyBlockingDate
		const emergencyBlockingType = activationData.emergencyBlockingType
		const subscriptionStatus = activationData.subscriptionStatus
		const isTestMode = !subscriptionStatus
		const isUnpaid = subscriptionStatus && (subscriptionStatus === 'UNPAID' || subscriptionStatus === 'CANCELED')
		const dateDifferenceInDays = getDateDifferenceInDays(deactivationDate)
		const isDeactivated = !!deactivationDate && (dateDifferenceInDays <= 0)
		const willBeDeactivated = !!deactivationDate && (dateDifferenceInDays > 0)
		const emergencyDateDifferenceInDays = getDateDifferenceInDays(emergencyBlockingDate)
		const isEmergencyBlocked = !!emergencyBlockingDate && (emergencyDateDifferenceInDays <= 0)
		return { isTestMode, isDeactivated, willBeDeactivated, deactivationDate, dateDifferenceInDays, isUnpaid, isEmergencyBlocked, emergencyBlockingType, emergencyDateDifferenceInDays }
	},
)
