import { OrderCost, OrderDelivery, OrderRate, OrderSubTitle, OrderTitle, OrderTotalsWrap, OrderTotalWrapper } from '../index.styled'
import Currency from '../../../../currency'
import { getFormattedCurrency } from '../../../../../../helper'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrencySymbol } from '../../../../../../redux/selector/system'
import { useTranslation } from 'react-i18next'

const OrderTotals = React.memo(({ order }) => {
	const { t } = useTranslation()
	const currency = useSelector(selectCurrencySymbol)
	const orderExchangeRate = order?.exchangeRateMetaData?.rate
	const orderExchangeCurrency = order?.exchangeRateMetaData?.currency
	const exchangeRateCurrency = getFormattedCurrency(orderExchangeRate, 'de-DE', orderExchangeCurrency)
	const coupon = order?.items?.filter(item => item?.type === 'MARKETING_COUPON')?.[0]
	const stampVoucher = order?.items?.filter(item => item?.type === 'STAMP_VOUCHER')?.[0]
	const extraDiscount = order?.items?.filter(item => item?.type === 'EXTRA_DISCOUNT')?.[0]
	const platformFee = order?.items?.filter(item => item?.type === 'PLATFORM_FEE')?.[0]
	const platformName = order?.platformMetaData?.platformName
	const shouldDisplayOrderDelivery = order?.deliveryCost?.cost !== 0 || (platformFee?.price !== 0 && platformFee) ||
		order?.totalDiscount !== 0 || (extraDiscount && extraDiscount?.price !== 0) || (coupon && coupon?.price !== 0) || (stampVoucher && stampVoucher?.price !== 0)
	const orderTaxes = order?.taxTotal?.taxes
	const taxNetAmount = orderTaxes?.reduce((acc, tax) => acc + tax.totalNet, 0)

	return (
		<OrderTotalsWrap>
			{shouldDisplayOrderDelivery &&
				<OrderDelivery>
					{order?.deliveryCost?.cost !== 0 &&
						<OrderCost>
							<OrderSubTitle>{t('AddOrder.Client.form.Anfahrthosten')}: </OrderSubTitle>
							<OrderSubTitle><Currency>{order?.deliveryCost?.cost}</Currency></OrderSubTitle>
						</OrderCost>}
					{platformFee?.price !== 0 && platformFee &&
						<OrderCost data-testid="order-platform-fee">
							<OrderSubTitle>{platformName} {t('Modal.platform_fee')}: </OrderSubTitle>
							<OrderSubTitle><Currency>{platformFee?.price}</Currency></OrderSubTitle>
						</OrderCost>}
					{order?.totalDiscount !== 0 &&
						<OrderCost data-testid="order-discount">
							<OrderSubTitle>{t('Modal.discount')}: </OrderSubTitle>
							<OrderSubTitle><Currency>{order?.totalDiscount}</Currency></OrderSubTitle>
						</OrderCost>}
					{extraDiscount && extraDiscount?.price !== 0 && <OrderCost data-testid="order-extra-discount">
						<OrderSubTitle>{extraDiscount.name}: </OrderSubTitle>
						<OrderSubTitle><Currency>{Math.abs(extraDiscount?.price)}</Currency></OrderSubTitle>
					</OrderCost>}
					{coupon && coupon?.price !== 0 && <OrderCost data-testid="order-coupon">
						<OrderSubTitle>{t('Modal.coupon')} {coupon?.name}</OrderSubTitle>
						<OrderSubTitle><Currency>{coupon?.price}</Currency></OrderSubTitle>
					</OrderCost>}
					{stampVoucher && stampVoucher?.price !== 0 && <OrderCost data-testid="order-stamp-voucher">
						<OrderSubTitle>{t('Modal.stampVoucher')}</OrderSubTitle>
						<OrderSubTitle><Currency>{stampVoucher?.price}</Currency></OrderSubTitle>
					</OrderCost>}
				</OrderDelivery>}
			{order?.tips > 0 && (
				<OrderSubTitle data-testid="order-tips" className="right bold">{t('Modal.tips')}: <Currency>{order?.tips}</Currency></OrderSubTitle>
			)}
			{order?.paymentFee > 0 && (
				<OrderSubTitle className="right bold">{t('Modal.payment_fee')}: <Currency>{order?.paymentFee}</Currency></OrderSubTitle>
			)}
			<OrderCost className="cost">
				<OrderTitle className="bold">
					{order?.payments?.map((payment, i) =>
						<div key={i}>{payment?.paymentName ? payment.paymentName : t(`Filter.payment.${payment.method}`)}</div>)}
				</OrderTitle>
				<OrderTitle className="bold">
					{order?.payments?.map((payment, i) =>
						<div key={i}>{t('Modal.total')}: <Currency>{payment.total}</Currency></div>)}
				</OrderTitle>
			</OrderCost>
			{order?.exchangeRateMetaData && <OrderTotalWrapper className="top">
				<OrderRate>
					{order?.payments?.map((payment, i) => {
						const totalInCurrency = getFormattedCurrency(Math.floor((payment?.total * orderExchangeRate) * 100) / 100, 'de-DE', orderExchangeCurrency)
						return <div key={i}>
							{t('Modal.totalInCurrency')} {orderExchangeCurrency}: {totalInCurrency}
						</div>
					})}
				</OrderRate>
				<OrderRate>
					{t('Modal.exchangeRate')}: 1{currency} = {exchangeRateCurrency}
				</OrderRate></OrderTotalWrapper>}

			<OrderTotalWrapper>
				{orderTaxes &&
					<>
						{orderTaxes.map((tax, i) => (
							<OrderCost key={'vat_' + i}>
								<OrderSubTitle>{t('Modal.vat')} {tax.title}</OrderSubTitle>
								<OrderSubTitle><Currency>{tax.vat}</Currency></OrderSubTitle>
							</OrderCost>
						))}
						<OrderCost>
							<OrderSubTitle>{t('Modal.netAmount')}</OrderSubTitle>
							<OrderSubTitle><Currency>{taxNetAmount}</Currency></OrderSubTitle>
						</OrderCost>
					</>
				}
			</OrderTotalWrapper>
		</OrderTotalsWrap>
	)
})

OrderTotals.displayName = 'OrderTotals'
export default OrderTotals
