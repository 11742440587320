import React, { Suspense } from 'react'
import SettingsPage from '../page/SettingsPage'
import ProductPage from '../page/Product'
import { Route, Routes } from 'react-router-dom'
import LoginPage from '../page/Login'
import DiscountsPage from '../page/Discounts'
import PhonePage from '../page/Phone'
import PasswordLessPage from '../page/Login/PasswordLess'
import RecoveryPage from '../page/Recovery'
import RecoveryPasswordPage from '../page/Recovery/password'
import RegistrationCompanyPage from '../page/Registration/Company'
import UserPage from '../page/User'
import PrivatePage from '../component/Elements/PrivatePage'
import CompanyDataPage from '../page/CompanyData'
import ProgramSettingsPage from '../page/ProgramSettings'
import DriverMonitorPage from '../page/DriverMonitor'
import DesktopUserPage from '../page/DesktopUser'
import AllOrderPage from '../page/AllOrder'
import KitchenMonitorPage from '../page/KitchenMonitor'
import DeliveryConfigPage from '../page/DeliveryConfig'
import PermissionsPage from '../page/Permissions'
import PermissionsPageUsers from '../page/PermissionsUsers'
import DesktopClientPage from '../page/DesktopClient'
import ApiRestaurantPage from '../page/ApiRestaurant'
import TakeawayRestaurantPage from '../page/TakeawayRestaurant'
import RestaurantSettingsPage from '../page/RestaurantSettingsPage'
import IngredientsPage from '../page/Ingredients'
import LogOut from '../page/logout'
import ProcessDriverPage from '../page/ProcessDriver'
import OwnerRegistrationPage from '../page/Registration/Owner'
import CategoryPage from '../page/Category'
import PlatformsPage from '../page/Platforms'
import TaxSettingPage from '../page/TaxSettings'
import { lazyWithRetry } from '../helper/lazyWithRetry'
import OrdersmartRestaurantPage from '../page/OrdersmartRestaurant'
import CashDeskPage from '../page/CashDesk'
import BusinessScopeDiscountPage from '../page/BusinessScopeDiscount'
import HomePage from '../page/Home'
import ProductSettingsPage from '../page/ProductSettings'
import DeliverySettingsPage from '../page/DeliverySettings'
import DeliveryCartSettings from '../component/DeliveryCartSettings'
import CashDeskSettingsPage from '../page/CashDeskSettings'
import CashDeskCartSettings from '../component/CashDeskCartSettings'
import AreaPage from '../page/AreaPage'
import RestaurantCartSettingsPage from '../page/RestaurantCartSettingsPage'
import UberEatRestaurantPage from '../page/UberEatRestaurant'
import CashDrawerPage from '../page/CashDrawer'
import PrinterConfigPage from '../page/PrinterConfig'
import PrinterSettingsPage from '../page/PrinterSettings'
import Delivery from '../component/Delivery'
import DeliveryCart from '../component/Delivery/DeliveryCart'
import Restaurant from '../component/Restaurant'
import TableCart from '../component/Restaurant/TableCart'
import HelpPage from '../page/Help'
import RegistrationSuccessPage from '../page/RegistrationSuccessPage'
import CashBookSettingsPage from '../page/CashBookSetting'
import CategoriesOfCashBookPage from '../page/CategoriesOfCashBook'
import DateVSettingsPage from '../page/DateVSettings'
import WebShopSettingsPage from '../page/WebShopSettingPage'
import WebShopDesignPage from '../page/WebshopDesignPage'
import WebShopSchedulePage from '../page/WebShopSchedulePage'
import WoltRestaurantPage from '../page/WoltRestaurantPage'
import DailyStatistics from '../page/DailyStatistics'
import MonthlyStatistics from '../page/MonthlyStatistics'
import ReportPage from '../page/Report'
import ZReportPage from '../page/ZReport'
import DailyReportPage from '../page/DailyReport'
import MonthlyReportPage from '../page/MonthlyReport'
import DeliveryPage from '../page/Delivery'
import RestaurantPage from '../page/Restaurant'
import ShopSeoPage from '../page/ShopSeoPage'
import ToppingGroupsConfigPage from '../page/ToppingGroupsConfig'
import ToppingGroupsPage from '../page/ToppingGroups'
import ProductMenuPage from '../page/ProductMenu'
import MarketingSettingsPage from '../page/MarketingSettings'
import CouponPage from '../page/Coupon'
import CouponConfigPage from '../page/CouponConfig'
import ProductConfigPage from '../page/ProductConfig'
import CategoryConfigPage from '../page/CategoryConfig'
import TerminalWizardPage from '../page/TerminalWizardPage'
import PaymentsQrCodeSuccessPage from '../page/PaymentsQrCodeSuccess'
import PaymentsSettingsPage from '../page/PaymentsSettings'
import PrinterConfigFormPage from '../page/PrinterConfigForm'
import FoodoraRestaurantPage from '../page/FoodoraRestaurantPage'
import PartnerPortalPage from '../page/PartnerPortal'
import PlatformDriversPage from '../page/DriverIntegrationPage'
import DeliveryWoltPage from '../page/DeliveryWoltPage'
import ClientMonitorSettingsPage from '../page/ClientMonitorSettings'
import OrderAndPaySettingsPage from '../page/OrderAndPaySettingsPage'
import KitchenMonitorSettingsPage from '../page/KitchenMonitorSettings'
import KitchenMonitorConfigPage from '../page/KitchenMonitorConfig'
import StampsSettingsPage from '../page/Stamps'
import FlyerCouponConfigPage from '../page/FlyerCouponConfig'

const CashbookNew = lazyWithRetry(() => import(/* webpackChunkName: "cashbook-new" */'../component/Report/form/cashbookNew'))

const Main = React.memo(() => {
	return (
		<Routes>
			<Route path="/*" element={<LoginPage />} />

			<Route path="/registration/*" element={<OwnerRegistrationPage />} />

			<Route path="/home" element={<PrivatePage component={HomePage} />} />
			<Route path="/delivery/*" element={<PrivatePage component={DeliveryPage} />}>
				<Route path="" element={<Delivery />} />
				<Route path="cart" element={<DeliveryCart />} />
			</Route>

			<Route path="/cashdesk" element={<PrivatePage component={CashDeskPage} />} />

			<Route path="/driver-monitor/*" element={<PrivatePage component={DriverMonitorPage} />} />
			<Route path="/driver-monitor/driver/process/*" element={<PrivatePage component={ProcessDriverPage} />} />
			<Route path="/kitchen-monitor/*" element={<PrivatePage component={KitchenMonitorPage} />} />
			<Route path="/all-orders/*" element={<PrivatePage component={AllOrderPage} />} />
			<Route path="/settings/*" element={<PrivatePage component={SettingsPage} />} />
			<Route path="/restaurant/*" element={<PrivatePage component={RestaurantPage} />}>
				<Route path="" element={<Restaurant />} />
				<Route path="cart" element={<TableCart />} />
			</Route>
			<Route path="/help/*" element={<HelpPage />} />

			<Route path="/settings/product/ingredients/category/*" element={<PrivatePage component={CategoryPage} />} />
			<Route path="/settings/product/ingredients/category/edit/*" element={<PrivatePage component={CategoryConfigPage} />} />
			<Route path="/settings/product/category/printer/*" element={<PrivatePage component={CategoryPage} />} />
			<Route path="/settings/product/category/image/*" element={<PrivatePage component={CategoryPage} />} />

			<Route path="/settings/product/*" element={<PrivatePage component={ProductSettingsPage} />} />
			<Route path="/settings/product/ingredients/article/*" element={<PrivatePage component={ProductPage} />} />
			<Route path="/settings/product/ingredients/article/product/*" element={<PrivatePage component={ProductConfigPage} />} />
			<Route path="/settings/product/ingredients/article/menu/*" element={<PrivatePage component={ProductMenuPage} />} />
			<Route path="/settings/product/ingredients/*" element={<PrivatePage component={IngredientsPage} />} />
			<Route path="/settings/product/ingredients/topping-groups/*" element={<PrivatePage component={ToppingGroupsPage} />} />
			<Route path="/settings/product/ingredients/topping-groups/edit/*" element={<PrivatePage component={ToppingGroupsConfigPage} />} />

			<Route path="/settings/program/printer/*" element={<PrivatePage component={PrinterSettingsPage} />} />
			<Route path="/settings/program/printer/config/*" element={<PrivatePage component={PrinterConfigPage} />} />
			<Route path="/settings/program/printer/config/edit/*" element={<PrivatePage component={PrinterConfigFormPage} />} />
			<Route path="/settings/program/printer/cashdrawer/*" element={<PrivatePage component={CashDrawerPage} />} />
			<Route path="/settings/program/phone/*" element={<PrivatePage component={PhonePage} />} />
			<Route path="/settings/program/desktop/*" element={<PrivatePage component={DesktopUserPage} />} />
			<Route path="/settings/program/*" element={<PrivatePage component={ProgramSettingsPage} />} />
			<Route path="/settings/program/client-screen" element={<PrivatePage component={ClientMonitorSettingsPage} />} />

			<Route path="/settings/program/company-data/*" element={<PrivatePage component={CompanyDataPage} />} />
			<Route path="/settings/program/tax-settings/*" element={<PrivatePage component={TaxSettingPage} />} />
			<Route path="/settings/program/users/*" element={<PrivatePage component={PermissionsPageUsers} />} />
			<Route path="/settings/program/users/all/*" element={<PrivatePage component={UserPage} />} />
			<Route path="/settings/program/users/permissions/*" element={<PrivatePage component={PermissionsPage} />} />
			<Route path="/settings/program/platform/*" element={<PrivatePage component={PlatformsPage} />} />
			<Route path="/settings/program/platform/takeaway/*" element={<PrivatePage component={TakeawayRestaurantPage} />} />
			<Route path="/settings/program/platform/api/*" element={<PrivatePage component={ApiRestaurantPage} />} />
			<Route path="/settings/program/platform/ordersmart/*" element={<PrivatePage component={OrdersmartRestaurantPage} />} />
			<Route path="/settings/program/platform/ubereat/*" element={<PrivatePage component={UberEatRestaurantPage} />} />
			<Route path="/settings/program/platform/wolt/*" element={<PrivatePage component={WoltRestaurantPage} />} />
			<Route path="/settings/program/platform/foodora/*" element={<PrivatePage component={FoodoraRestaurantPage} />} />

			<Route path="/settings/restaurant/*" element={<PrivatePage component={RestaurantSettingsPage} />} />
			<Route path="/settings/restaurant/cart-settings/*" element={<PrivatePage component={RestaurantCartSettingsPage} />} />
			<Route path="/settings/restaurant/areas/*" element={<PrivatePage component={AreaPage} />} />
			<Route path="/settings/restaurant/order-and-pay/*" element={<PrivatePage component={OrderAndPaySettingsPage} />} />

			<Route path="/settings/product/discounts/*" element={<PrivatePage component={DiscountsPage} />} />
			<Route path="/settings/product/discounts/business/*" element={<PrivatePage component={BusinessScopeDiscountPage} />} />

			<Route path="/settings/delivery/*" element={<PrivatePage component={DeliverySettingsPage} />} />
			<Route path="/settings/delivery/cart-settings/*" element={<PrivatePage component={DeliveryCartSettings} />} />
			<Route path="/settings/delivery/config/*" element={<PrivatePage component={DeliveryConfigPage} />} />

			<Route path="/settings/cashdesk/*" element={<PrivatePage component={CashDeskSettingsPage} />} />
			<Route path="/settings/cashdesk/cart-settings/*" element={<PrivatePage component={CashDeskCartSettings} />} />

			<Route path="/settings/cashbook/*" element={<PrivatePage component={CashBookSettingsPage} />} />
			<Route path="/settings/cashbook/categories" element={<PrivatePage component={CategoriesOfCashBookPage} />} />
			<Route path="/settings/datev" element={<PrivatePage component={DateVSettingsPage} />} />

			<Route path="/settings/marketing" element={<PrivatePage component={MarketingSettingsPage} />} />
			<Route path="/settings/marketing/coupon/*" element={<PrivatePage component={CouponPage} />} />
			<Route path="/settings/marketing/coupon/config/*" element={<PrivatePage component={CouponConfigPage} />} />
			<Route path="/settings/marketing/coupon/flyer-config/*" element={<PrivatePage component={FlyerCouponConfigPage} />} />
			<Route path="/settings/marketing/stamps/*" element={<PrivatePage component={StampsSettingsPage} />} />

			<Route path="/settings/webshop/*" element={<PrivatePage component={WebShopSettingsPage} />} />
			<Route path="/settings/webshop/design/*" element={<PrivatePage component={WebShopDesignPage} />} />
			<Route path="/settings/webshop/working-hours/*" element={<PrivatePage component={WebShopSchedulePage} />} />
			<Route path="/settings/webshop/seo/*" element={<PrivatePage component={ShopSeoPage} />} />

			<Route path="/settings/payments/*" element={<PrivatePage component={PaymentsSettingsPage} />} />
			<Route path="/settings/payments/ec-terminal/*" element={<PrivatePage component={TerminalWizardPage} />} />

			<Route path="/settings/driver-integrations/*" element={<PrivatePage component={PlatformDriversPage} />} />
			<Route path="/settings/driver-integrations/wolt/*" element={<PrivatePage component={DeliveryWoltPage} />} />

			<Route path="/settings/kitchen-monitor" element={<PrivatePage component={KitchenMonitorSettingsPage} />} />
			<Route path="/settings/kitchen-monitor/edit" element={<PrivatePage component={KitchenMonitorConfigPage} />} />

			<Route path="/report/*" element={<PrivatePage component={ReportPage} />}>
				<Route path="*" element={<ZReportPage />} />
				<Route path="daily" element={<DailyReportPage />} />
				<Route path="monthly" element={<MonthlyReportPage />} />
				<Route path="cashbook" element={<Suspense><CashbookNew /></Suspense>} />
			</Route>

			<Route path="report/daily/statistics/" element={<PrivatePage component={DailyStatistics} />} />
			<Route path="report/monthly/statistics/" element={<PrivatePage component={MonthlyStatistics} />} />

			<Route path="/logout/*" element={<LogOut />} />

			<Route path="/login/*" element={<LoginPage />} />
			<Route path="/passwordless/:passwordLessCodeId/*" element={<PasswordLessPage />} />
			<Route path="/recovery/*" element={<RecoveryPage />} />
			<Route path="/changepassword/:changePasswordId/*" element={<RecoveryPasswordPage />} />
			<Route exact path="/registration/company/*" element={<PrivatePage component={RegistrationCompanyPage} />} />
			<Route path="/desktop/client/*" element={<PrivatePage component={DesktopClientPage} />} />
			<Route path="/registration/success" element={<RegistrationSuccessPage />} />

			<Route path="/payments/qr-code/success" element={<PaymentsQrCodeSuccessPage />} />
			<Route path="/partner-portal" element={<PrivatePage component={PartnerPortalPage} />} />
			<Route element={<PrivatePage component={() => <h1>404</h1>} />} />
		</Routes>
	)
})

Main.displayName = 'Main'
export default Main
