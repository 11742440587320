import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setRestaurantShowPaidBeforeOrdersModal, setRestaurantTable } from '../../../../../redux/action/restaurant'
import CustomModal from '../../../../Elements/modal/CustomModal'
import PaidBeforeOrdersTable from './PaidBeforeOrdersTable'
import Button from '../../../../Elements/buttons/Button'
import { PaidBeforeOrdersTableWrapper } from './index.styled'
import ModalOrder from '../../../orders/modalOrder'
import { getRestaurantOrderThunk } from '../../../../../redux/thunk/restaurant/order'

const Z_INDEX = 108

const PaidBeforeOrdersModal = React.memo(() => {
	const table = useSelector(state => state.restaurant.table)
	const showPaidBeforeOrdersModal = useSelector(state => state.restaurant.showPaidBeforeOrdersModal)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [activeRow, setActiveRow] = useState(0)
	const [selectedOrder, setSelectedOrder] = useState(null)

	const selectOrder = useCallback(async (item, i) => {
		setActiveRow(i)
		const order = await dispatch(getRestaurantOrderThunk(item.id))
		setSelectedOrder(order)
	}, [dispatch])

	const handleClose = useCallback(() => {
		dispatch(setRestaurantShowPaidBeforeOrdersModal(false))
		dispatch(setRestaurantTable(null))
	}, [dispatch])

	return (
		<>
			<CustomModal isOpen={showPaidBeforeOrdersModal}
									 close={handleClose}
									 title={`${t('Modal.orders')} ${t('restaurant.tables.modal.table')} #` + (table?.number || '')}
									 showBackButton={false}
									 disableBackButton={true}
									 zIndex={Z_INDEX}
									 size="x-small height-scroll"
									 button={<Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')} onClick={handleClose} keyButton="F3" keyText="F3" zIndex={Z_INDEX} className="btn-center" />}>
				<PaidBeforeOrdersTableWrapper>
					<PaidBeforeOrdersTable {...{ activeRow, selectOrder }} />
				</PaidBeforeOrdersTableWrapper>
			</CustomModal>

			<ModalOrder {...{ selectedOrder, setSelectedOrder, isPayBeforeOrder: true }} />
		</>
	)
})

PaidBeforeOrdersModal.displayName = 'PaidBeforeOrdersModal'
export default PaidBeforeOrdersModal
