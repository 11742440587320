import styled, { keyframes } from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'
import CustomNavLink from '../link/NavLink'
import { ButtonText } from '../buttons/Button/style/index.styled'
import { ButtonWrapper } from '../buttons/Button/style/buttonWrap/index.styled'

export const Form = styled.form`
	width: 100%;
	max-width: 73.75em;
	font-size: 1rem;

	&.half {
		width: 50%;
	}

	&.two-thirds {
		width: 70%;
	}

	&.small {
		max-width: 55em;
	}

	&.medium {
		max-width: 64.38em;
	}

	&.big {
		max-width: 83.13em;
	}

	@media screen and (max-width: ${theme.point1280}) {
		&, &.half, &.two-thirds {
			width: 100%;
			max-width: 100%;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		&.small, &.medium {
			width: 100%;
			max-width: 100%;
		}
	}

`
Form.displayName = 'Form'

export const AuthForm = styled.form`
	font-size: 1rem;
	color: ${colors.black_light};
	max-width: 25em;
	margin: 0 auto;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding: 5.38em 0 0;
	position: relative;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;

	@media screen and (max-width: ${theme.point820}) {
		max-width: 34.86em;
		padding: 2.15em 0 0;
		align-items: center;

		> :nth-child(2) {
			align-self: start;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		min-width: 100%;
		margin: 0;
		padding: 1.88em 1.25em 0;
	}
`
AuthForm.displayName = 'AuthForm'

export const FormLink = styled(CustomNavLink)`
	font-size: 1.4rem;
	color: ${colors.blue};
	text-align: right;

	&.loginPage {
		font-size: 1.125em;
		display: block;
		margin-top: 0.95em;
		text-align: right;
		margin-bottom: 1.95em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.loginPage {
			font-size: 1.29em;
			margin-top: 0.56em;
			margin-bottom: 1.11em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		text-align: left;

		&.loginPage {
			font-size: 1.125em;
			margin-top: 0.67em;
			margin-bottom: 1.11em;
		}
	}
`
AuthForm.displayName = 'AuthForm'

export const FormInputs = styled.div`
	font-size: 1rem;
	display: grid;
	gap: 0.63em;
	grid-template-columns: repeat(${props => props.cols || 1}, 1fr);
	align-items: start;

	&.half {
		width: 50%;
	}

	&.two-gap {
		gap: 1.25em 0.63em;
	}

	&.gap {
		gap: 1.25em;
	}

	&.form-gap {
		gap: 1.25em;
	}

	${props => props.marginBottom && `
    margin-bottom: ${props.marginBottom}em;
  `};

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.72em;

		&.gap {
			gap: 1.43em;
		}

		&.form-gap {
			gap: 1.43em 0.72em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
		grid-template-columns: 1fr;

		&.two {
			grid-template-columns: 1fr 1fr;
		}

		&.gap {
			gap: 1.25em;
		}

		&.form-gap {
			gap: 1.25em;
		}
	}

	&.two-column {
		@media screen and (max-width: ${theme.point1280}) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: ${theme.point720}) {
			grid-template-columns: 1fr;
		}
	}
`

export const FormItem = styled.div`
	display: grid;
	gap: 0.63em;
	position: relative;
	grid-column: ${props => props.column || 'initial'};
	grid-row: ${props => props.row || 'initial'};

	${props => props.flex && `
    display: flex;
  `}

	${props => props.marginBottom && `
    margin-bottom: ${props.marginBottom}em;
  `};

	${props => props.cols && `
    grid-template-columns: repeat(${props.cols}, 1fr);
  `};

	&.gap-medium {
		gap: 1.25em;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-column: 1;
		width: 100%;
	}

	&.two-column {
		@media screen and (max-width: ${theme.point1280}) {
			grid-column: 1 / 3;
		}

		@media screen and (max-width: ${theme.point720}) {
			grid-column: 1;
			width: 100%;
		}
	}

	&.order-1 {
		@media screen and (max-width: ${theme.point1280}) {
			order: 1;
		}
	}
`

export const FormInfo = styled.div`
	font-size: 1rem;
	font-family: ${theme.fontRobotoRegular};
	color: ${colors.gray_lighter};
	border-radius: 0.75em;
	border: 1px solid ${colors.gray_lighter};
	min-height: 4.13em;
	display: flex;
	align-items: center;
	gap: 1em;
	padding: 0.5em 0.69em 0.5em 1.63em;
	text-align: left;
	margin-bottom: 1.5em;

	&::before {
		font-size: 1em;
		display: block;
		content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 28.5C23.0939 28.5 29.25 22.3439 29.25 14.75C29.25 7.15608 23.0939 1 15.5 1C7.90608 1 1.75 7.15608 1.75 14.75C1.75 22.3439 7.90608 28.5 15.5 28.5Z' stroke='%23ADADB4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5 10V21' stroke='%23ADADB4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		width: 1.8em;
		margin-top: 0.4em;
		flex-shrink: 0;
	}
`

const breatheAnimation = keyframes`
	0% {
		opacity: 1;
	}
	99% {
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
`

export const FormButtons = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.63em;
	margin-top: 1.9em;

	> button:nth-child(2) {
		margin-left: auto;
	}

	&.bottom {
		margin-bottom: 1.9em;
	}

	&.bottom-big {
		margin-bottom: 2.5em;
	}

	&.top-border {
		margin-top: 2.5em;
		padding-top: 2.5em;
		border-top: 1px dashed #60616E;
	}

	&.half {
		width: 50%;
	}

	&.justify {
		> * {
			width: 100%;
		}
	}

	&.hidden {
		animation: ${breatheAnimation} .2s;
		animation-fill-mode: forwards;
	}

	&.wrap {
		margin-top: -8em;
		margin-bottom: 1em;
	}

	&.column {
		flex-direction: column;
	}

	&.bigMargin {
		margin-top: 3.125em;
	}

	&.autoMarginTop {
		margin-top: auto;
	}

	&.end {
		justify-content: end;
	}

	&.mob {
		gap: 1.25em;
		@media screen and (max-width: ${theme.point720}) {
			display: grid;
			grid-template-columns: 1fr;

			> button {
				margin-left: 0;
			}
		}
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.shopSchedulePage {
			> * {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		&.fixedSizeBtn {
			> ${ButtonWrapper} {
				width: 13em;
			}
		}

		&.wrap {
			margin-top: -1em;
			flex-wrap: wrap;

			> * {
				width: 100%;
			}
		}

		&.mobileTop {
			margin-top: 1.9em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.fixedSizeBtn {
			> ${ButtonWrapper} {
				width: 11.38em;
			}
		}

		&.two-column {
			> button:nth-child(2) {
				margin-left: 0;
			}

			> * {
				width: 100%;
			}
		}

		&.margin-top-mob-0 {
			margin-top: 0;
		}
	}

	@media screen and (max-width: ${theme.point520}) {
		&.fixedSizeBtn {
			> ${ButtonWrapper} {
				width: 50%;
			}
		}

		&.formMobile {
			flex-direction: column;
			gap: 1.25em;
			margin-top: 1.9em;

			> * {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: ${theme.point400}) {
		margin-top: 1em;

		&.mobileBottom {
			margin-bottom: 1em;
		}

		&.mobileTop {
			margin-top: 1.9em;
		}
	}
`

export const AuthFormButtons = styled(FormButtons)`
	margin: 1.25em 0;
	row-gap: 1.25em;
	column-gap: 0.63em;

	&.margin-0 {
		margin: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		margin: 1.43em 0;
		row-gap: 1.43em;
		column-gap: 0.71em;

		> ${ButtonWrapper} {
			min-height: 3.93em;

			${ButtonText} {
				font-size: 1.43em;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		margin: 1.25em 0;
		row-gap: 1.25em;
		column-gap: 0.63em;

		> ${ButtonWrapper} {
			min-height: 3.44em;
			padding: 0.81em 0.88em;

			${ButtonText} {
				font-size: 1.38em;
			}
		}
	}
`
AuthFormButtons.displayName = 'AuthFormButtons'
